<template >
    <div>
        <div class="app-modal__box" v-loading="loader">
            <div class="app-modal__body p-2" v-if="paymentSelectedPoint.deal_payments">

                <div class="modal-card" v-for="deal in paymentSelectedPoint.deal_payments.dealIds" :key="deal">
                    <ul>
                        <li>
                            <div><b>{{ $t("message.deal") }} №{{ deal }} -> "{{ deal_name(deal) }}" </b></div>
                            <div><b>{{ totalAmount(deal) | formatNumber(1) }} {{ deal_code(deal) }}</b></div>
                        </li>
                        <li v-for="payment in filteredPayments(deal)" :key="payment.id">
                            <div>{{ payment.payment_condition_name }} </div>
                            <div><b> {{ (payment.percentage * payment.money_amount )/100 | formatNumber(1) }} {{ payment.code }}</b></div>
                        </li>
                       
                    </ul>
                </div>

            </div>
            <div class="app-modal__body p-2" v-else>
              <h2>{{ $t("message.no_paymnets") }}</h2>
            </div>

            <!-- end app-modal__body -->
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import drawer from "@/utils/mixins/drawer";
import form from "@/utils/mixins/form";

export default {
    mixins: [form, drawer],
    props: {
        selectedPoint: {
            default: Object,
        },
    },
    data() {
        return {
            loader: false
        };
    },
    created() {},
     watch: {
      selectedPoint: {
          handler: async function(newVal) {
              this.loader = true;
              this.updateSelectedPoint(newVal)
                .then(res => {
                    this.loader = false;
                })
                
          },
          deep: true,
          immediate: true
      },
    },
    computed: {
        ...mapGetters({
            paymentSelectedPoint: "home/dealPaymentSelectedPoint",
            
        }),
    },
    methods: {
        ...mapActions({
            updateSelectedPoint: "home/dealPaymentSelectedPoint",
        }),
        deal_name(id){
          if(this.paymentSelectedPoint.deal_payments){
            let a = this.paymentSelectedPoint.deal_payments.payments.find(item => parseInt(item.deal_id) === id);
            return a ? a.deal_name : 'no name'
          }
        },
        deal_code(id){
            let a = this.paymentSelectedPoint.deal_payments.payments.find(item => parseInt(item.deal_id) === id);
            return a ? a.code : 'no code'
        },

        filteredPayments(id){
            let a = this.paymentSelectedPoint.deal_payments.payments.filter(item => parseInt(item.deal_id) === id);
            return a
        },

        totalAmount(id){
          let a = this.paymentSelectedPoint.deal_payments.payments.filter(item => parseInt(item.deal_id) === id);

          let total = 0;
          a.forEach(element => {
            total += element.percentage * element.money_amount/100
          });

            return total;
        }
    },
};
</script>
