<template >
  <div>
    <div class="app-modal__box">
      <div class="app-modal__in">
        <div class="app-modal__header d-flex f-between p-5 m-title-modal">
          <p class="large--title m-0"> {{
              $t("message.new_m", {
                m: $t("message.phoneContact"),
              })
            }}</p>
          <div>
             <crm-store-update-close
                :permission="$options.name"
                :button_type="'store'"
                :loading="loadingButton"
                @c-submit="submit(true)"
                @c-close="close()"
            ></crm-store-update-close>
          </div>
        </div>
      </div>
      <!-- app-modal__header end -->

      <div class="app-modal__body p-5 pb-0">
        <div class="timeline-items__right rounded-sm w-100 p-4">
            <el-form ref="form" :model="form" :rules="rules" :label-position="'top'">
                <el-row :gutter="20">
                    <el-col :span="12">
                      <el-form-item
                      prop="name_title_id"
                      :label="columns.name_title_id.title"
                      >
                        <select-name-title
                          :size="'medium'"
                          :placeholder="columns.name_title_id.title"
                          :id="form.name_title_id"
                          v-model="form.name_title_id"
                        ></select-name-title>   
                      </el-form-item>     
                    </el-col>
                    <el-col :span="12">
                      <el-form-item
                      prop="name"
                      :label="$t('message.name')"
                      >
                        <el-input
                            :placeholder="$t('message.name')"
                            v-model="form.name"
                            size="medium"
                        ></el-input>
                      </el-form-item>     
                    </el-col>
                    <el-col :span="12">
                      <el-form-item
                      prop="surname"
                      :label="$t('message.surname')"
                      >
                        <el-input
                            :placeholder="$t('message.surname')"
                            v-model="form.surname"
                            size="medium"
                        ></el-input>
                      </el-form-item>     
                    </el-col>
                    <el-col :span="12">
                      <el-form-item
                      prop="patronymic"
                      :label="$t('message.patronymic')"
                      >
                        <el-input
                            :placeholder="$t('message.patronymic')"
                            v-model="form.patronymic"
                            size="medium"
                        ></el-input>
                      </el-form-item>     
                    </el-col>
                    <el-col :span="12">
                          <crm-phone-input
                              :inputValue="form.phone_number" 
                              v-model="form.phone_number"
                              @getPhoneStatus="getPhoneStatus"
                              :form_prop="'phone_number'"
                              :country_code="form.phone_country_code"
                          ></crm-phone-input> 
                    </el-col>
                    <el-col :span="12">
                      <el-form-item
                      prop="date_of_birth"
                      :label="$t('message.date_of_birth')"
                      >
                        <el-date-picker
                          class="w-100"
                          size="medium"
                          v-model="form.date_of_birth"
                          type="date"
                          :placeholder="$t('message.date_of_birth')"
                          format="yyyy-MM-dd"
                          value-format="yyyy-MM-dd"
                        ></el-date-picker>
                      </el-form-item>     
                    </el-col>
                    <el-col :span="12">
                      <el-form-item
                      prop="company_id"
                      :label="columns.company_id.title"
                      >
                        <select-company
                          :size="'medium'"
                          :placeholder="$t('message.company')"
                          :id="form.company_id"
                          v-model="form.company_id"
                        >
                        </select-company>
                      </el-form-item>     
                    </el-col>
                    <el-col :span="12">
                      <el-form-item
                      prop="position"
                      :label="columns.position.title"
                      >
                        <el-input
                            :placeholder="columns.position.title"
                            v-model="form.position"
                            size="medium"
                        ></el-input>
                      </el-form-item>     
                    </el-col>        
                    <el-col :span="12">
                      <el-form-item
                      prop="email"
                      :label="columns.email.title"
                      >
                        <el-input
                            :placeholder="columns.email.title"
                            v-model="form.email"
                            size="medium"
                        ></el-input>
                      </el-form-item>     
                    </el-col>
                    <el-col :span="12">
                      <el-form-item
                      prop="website"
                      :label="columns.website.title"
                      >
                        <el-input
                            :placeholder="columns.website.title"
                            v-model="form.website"
                            size="medium"
                        ></el-input>
                      </el-form-item>     
                    </el-col>
                    <el-col :span="12">
                      <el-form-item
                      prop="contact_type_id"
                      :label="columns.contact_type_id.title"
                      >
                        <select-contact-type
                          :size="'medium'"
                          :placeholder="columns.contact_type_id.title"
                          :id="form.contact_type_id"
                          v-model="form.contact_type_id"
                        ></select-contact-type>
                      </el-form-item>     
                    </el-col>
                    <el-col :span="12">
                      <el-form-item
                      prop="source_id"
                      :label="columns.source_id.title"
                      >
                        <select-source
                            :size="'medium'"
                            :placeholder="columns.source_id.title"
                            :id="form.source_id"
                            v-model="form.source_id"
                        ></select-source>
                      </el-form-item>     
                    </el-col>
                    <!-- end-col -->

                    <el-col :span="24">
                      <div class="text-descr mb-0">
                        <span class="text-descr__label d-block">{{
                          $t("message.responsible")
                        }}</span>
                        <div
                          class="
                            responsible-block
                            d-flex
                            align-center
                            font-medium
                            p-2
                            rounded-sm
                          "
                        >
                          <div
                            class="gc-small-block rounded-circle hidden mr-3"
                            style="border: 1px solid gray"
                          >
                            <img
                              :src="
                                authUser
                                  ? (authUser.avatar
                                    ? authUser.avatar
                                    : '/img/default-user-icon.png')
                                  : '/img/default-user-icon.png'
                              "
                              alt="Default Avatar"
                              class="d-block"
                            />
                          </div>
                          <div class="f-fill">
                            <span class="text-descr__text f-fill">{{
                              authUser.name
                            }}</span>
                          </div>
                        </div>
                      </div>
                      <div class="text-descr mb-0">
                        <span class="text-descr__label d-block">{{
                          $t("message.participants")
                        }}</span>
                        <div
                          class="
                            responsible-block
                            d-flex
                            align-center
                            font-medium
                            p-2
                            rounded-sm
                            pointer
                            p-relative
                          "
                        >
                          <div
                            class="gc-small-block rounded-circle hidden mr-3"
                            @click="getSelectedUsers"
                          >
                            <img
                              src="/img/default-user-icon.png"
                              alt="Default Avatar"
                              class="d-block"
                            />
                          </div>
                          <div
                            class="f-fill d-flex align-center"
                            @click="getSelectedUsers"
                          >
                            <span class="text-descr__text f-fill"
                              >{{
                                $t("message.add_participant")
                              }}</span
                            >
                            <i class="el-icon-plus ml-auto"></i>
                          </div>

                          <div
                            class="
                              responsible-block-dropdown
                              p-absolute
                              w-100
                              rounded
                              p-3
                            "
                          >
                            <div class="responsible-block-search">
                              <div class="app-form__group">
                                <div class="responsible-block-input">
                                  <input
                                    type="text"
                                    class="gc-country-input"
                                    placeholder="Поиск..."
                                    @input="findResponsibleUsers"
                                    ref="responsibleUsersInput"
                                  />
                                </div>
                              </div>
                            </div>

                            <div
                              class="responsible-users-dropdown rounded p-3 mt-2"
                              ref="usersDropdown"
                            >
                              <perfect-scrollbar style="height: 200px">
                                <div
                                  v-for="user in usersList"
                                  :key="user.id"
                                  @click="selectUsers(user)"
                                  class="
                                    users-dropdown-items
                                    d-flex
                                    p-2
                                    rounded
                                    transition
                                  "
                                >
                                  <div class="user-items-left mr-2">
                                    <div
                                      class="
                                        user-item-avatar
                                        w-100
                                        h-100
                                        rounded-pill
                                        hidden
                                      "
                                    >
                                      <img
                                        :src="
                                          user
                                            ? (user.avatar
                                              ? user.avatar
                                              : '/img/default-user-icon.png')
                                            : '/img/default-user-icon.png'
                                        "
                                        alt
                                      />
                                    </div>
                                  </div>
                                  <div class="user-items-right">
                                    <h4 class="my-1">
                                      {{ user.name }}
                                    </h4>
                                    <p class="m-0">
                                      {{ user.phone }}
                                    </p>
                                  </div>
                                </div>
                              </perfect-scrollbar>

                              <div class="custom__button mt-3">
                                <el-button
                                  @click="setUsers"
                                  type="primary"
                                  icon="el-icon-user"
                                  size="medium"
                                  >{{ $t('message.add') }}</el-button
                                >
                              </div>
                            </div>

                            <div
                              v-for="user in selectedUsersList"
                              :key="user.id"
                              class="users-dropdown-items d-flex p-2 rounded transition"
                            >
                              <div class="user-items-left mr-2">
                                <div
                                  style="border: 1px solid gray"
                                  class="user-item-avatar w-100 h-100 rounded-pill hidden"
                                >
                                  <img
                                    :src="
                                      user
                                        ? (user.avatar
                                          ? user.avatar
                                          : '/img/default-user-icon.png')
                                        : '/img/default-user-icon.png'
                                    "
                                    alt
                                  />
                                </div>
                              </div>
                              <div class="user-items-right">
                                <h4 class="my-1">
                                  {{ user.name }}
                                </h4>
                                <p class="m-0">
                                  {{ user.phone }}
                                </p>
                              </div>
                              <div
                                class="f-fill d-flex align-center"
                                @click="removeFromSelectedUsers(user)"
                              >
                                <i class="el-icon-delete icon__delete ml-auto"></i>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </el-col>
                </el-row>
            </el-form>
        </div>
      </div>
      <!-- end app-modal__body -->
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import drawer from "@/utils/mixins/drawer";
import form from "@/utils/mixins/form";
import selectNameTitle from "@/components/inventory/select-name-title";
import selectContactType from "@/components/inventory/select-contact-type";
import selectCompany from "@/components/inventory/select-company";
import selectSource from "@/components/inventory/select-source";

export default {
  
  mixins: [form, drawer],
  components: {
    selectNameTitle,
    selectContactType,
    selectCompany,
    selectSource,
  },
  data() {
    return {
      usersList: [],
      selectedUsersList: [],
      is_phone_valid: false,
    };
  },
  created() {},
  computed: {
      ...mapGetters({
        rules: "phoneContacts/rules",
        model: "phoneContacts/model",
        columns: "phoneContacts/columns",
        authUser: "auth/user",
        getInventoryUsers: "users/inventory",
        getUserSearch: "users/search",
      }),
  },
  methods: {
      ...mapActions({
        save: "phoneContacts/store",
        showInventoryUsers: "users/inventory",
        search: "users/search",
      }),
      afterOpen(){
        this.form.user_id = this.authUser.id;
      },
      getPhoneStatus({is_valid, countryCode}){
        this.is_phone_valid = is_valid;
        this.form.phone_country_code = countryCode;
      },
      findResponsibleUsers() {
        const { usersDropdown } = this.$refs;
        let value = event.target.value;
        if (value) {
          this.searchUsers(value, this);
        }
        if (value) {
          usersDropdown.classList.add("active");
        } else {
          usersDropdown.classList.remove("active");
        }
      },
      searchUsers: _.debounce((search, self) => {
        if (_.isFunction(self.search)) {
          self
            .search(search)
            .then((res) => {
              self.usersList = self.getUserSearch;
            })
            .catch((err) => {});
        }
      }, 1000),
      getSelectedUsers() {
        const { usersDropdown } = this.$refs;
        usersDropdown.classList.toggle("active");
        if (usersDropdown.classList.contains("active")) {
          this.showInventoryUsers().then(() => {
            this.usersList = this.getInventoryUsers;
          });
        }
      },
      selectUsers(user) {
        let $el = event.target.closest(".users-dropdown-items");
        if (!this.selectedUsersList.some((person) => person.id === user.id)) {
          this.selectedUsersList.push(user);
          $el.classList.add("active");
        } else {
          this.selectedUsersList = this.selectedUsersList.filter(
            (item) => item.id !== user.id
          );
          $el.classList.remove("active");
        }
      },
      setUsers() {
        this.form.spectators = this.selectedUsersList.map(item => item.id);
        const { usersDropdown, responsibleUsersInput } = this.$refs;

        this.selectedUsersContainer = true;
        usersDropdown.classList.remove("active");
        responsibleUsersInput.value = "";
      },
      removeFromSelectedUsers(user) {
        this.selectUsers(user);
        this.form.spectators = this.selectedUsersList.map(item => item.id);
      },
      submit(close = true) {
          this.$refs["form"].validate((valid) => {
              if (valid) {
                if(this.is_phone_valid){
                  this.loadingButton = true;
                  this.save(this.form)
                      .then((res) => {
                          this.loadingButton = false;
                          this.$alert(res);
                          this.parent().listChanged();
                          if (close) this.close();
                      })
                      .catch((err) => {
                          this.loadingButton = false;
                          this.$alert(err);
                      });
                }else{
                  this.$notify({
                      title: this.$t('message.validation'),
                      type: "error",
                      offset: 130,
                      message: this.$t('message.phone_number_is_not_valid')
                  });
                }
              }
          });
      },
  },
};
</script>
