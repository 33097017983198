export const filter = {
    id : '',  
    company_id: null, 
    full_name: '', 
    date_of_birth: '', 
    passport_serial_number: '', 
    type_id: null, 
    phone: '', 
    phone_country_code: 'UZ',
    email: '', 
    passport_validity: '', 
    actual_address: '', 
    inn: '', 
    gender: null, 
    country_id: null, 
    city_id: null,
    clientType_id: null,
    created_at: '',
    updated_at: ''  
  };
  