import { columns } from './properties/columns'
import { filter } from './properties/filter'
import { pagination } from "@/store/modules/properties/pagination";
import { sort } from "@/store/modules/properties/sort";
import { rules } from "./properties/rules";
import { model } from "./properties/model";
import { contact_people } from "./properties/contact_people";
import { client_account } from "./properties/client_account";

export const state = {
    list: [],
    inventory: [],
    types: [],
    deals: [],
    genders: [],
    companyClients: [],
    personClients: [],
    model: JSON.parse(JSON.stringify(model)),
    columns: columns,
    filter: filter,
    pagination: JSON.parse(JSON.stringify(pagination)),
    sort: JSON.parse(JSON.stringify(sort)),   
    rules: rules, 
    contact_people: JSON.parse(JSON.stringify(contact_people)),
    client_account: JSON.parse(JSON.stringify(client_account)),
    clientDashboard: [],
};
