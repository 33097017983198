import request from '@/utils/request';

export function index(params) {
    return request({
        url: '/deals',
        method: 'get',
        params
    })
}

export function archivedDeals(params) {
    return request({
        url: '/deals/archivedDeals',
        method: 'get',
        params
    })
}

export function inventory(params) {
    return request({
        url: "/deals/inventory",
        method: "get",
        params
    });
}

export function deletedDeals(params) {
    return request({
        url: '/deals/deletedDeals',
        method: 'get',
        params
    })
}

export function dealTasks(id) {
    return request({
        url: `/deals/dealTasks/${id}`,
        method: 'get',
    })
}

export function store(data) {
    return request({
        url: '/deals',
        method: 'post',
        data
    })
}

export function show(id) {
    return request({
        url: `/deals/${id}`,
        method: 'get'
    })
}

export function archivateDeal(id) {
    return request({
        url: `/deals/archivateDeal/${id}`,
        method: 'get'
    })
}

export function restoreDeal(id) {
    return request({
        url: `/deals/restoreDeal/${id}`,
        method: 'get'
    })
}

export function downloadDealFile(deal_id, file) {
    return request({
        url: `/deals/downloadDealFile/${deal_id}/${file}`,
        method: 'get',
        responseType: 'blob'
    })
}

export function changeDealStage(params) {
    return request({
        url: `/deals/changeDealStage`,
        method: 'get',
        params
    })
}

export function deleteSpectator(deal_id, user_id) {
    return request({
        url: `/deals/deleteSpectator/${deal_id}/${user_id}`,
        method: 'get',
    })
}

export function deleteFile(deal_id, file) {
    return request({
        url: `/deals/deleteFile/${deal_id}/${file}`,
        method: 'get',
    })
}

export function showLast() {
    return request({
        url: '/deals/showLastCreated',
        method: 'get',
    })
}

export function dealStages(params) {
    return request({
        url: '/deals/dealStages',
        method: 'get',
        params
    })
}

export function changeIndex(data) {
    return request({
        url: `/deals/changeIndex/${data.id}`,
        method: 'put',
        data
    })
}
export function update(data) {
    return request({
        url: `/deals/${parseInt(data.get('id'))}`,
        method: 'post',
        data
    })
}
export function destroy(id) {
    return request({
        url: `/deals/${id}`,
        method: 'delete'
    })
}

export function deleteDeal(deal_id, reason_id) {
    return request({
        url: `/deals/deleteDeal/${deal_id}/${reason_id}`,
        method: 'delete'
    })
}

export function getDealsForCalendar(params) {
    return request({
        url: '/deals/getDealsForCalendar',
        method: 'get',
        params
    })
}

export function phoneNumberDeals(number) {
    return request({
        url: `/deals/phoneNumberDeals/${number}`,
        method: 'get',
    })
}