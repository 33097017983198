<template>
    <div>
        <div id="chart" v-if="reasonsDeals.ids && reasonsDeals.ids.length > 0">
            <apexchart
                type="pie"
                :height="size"
                :options="deletTask.chartOptions"
                :series="deletTask.series"
            ></apexchart>
        </div>
        <div v-else class="eror">
            <img src="/img/dashboard.png" alt="Logo" class="d-block" />
            <h1>
                {{ $t("message.no_data_yet") }}
            </h1>
        </div>
    </div>
</template>
<script>

import { mapGetters, mapActions } from "vuex";

export default {
    components: {},
    props: {
        period: {
            default: 'weekly'
        },
        filterForm: {
            default: null
        },
        size: {
            default: 423
        }
    },
    
    data() {
        return {
            deletTask: {
                series: [],
                chartOptions: {
                    chart: {
                        width: 380,
                        type: "pie"
                    },
                    legend: {
                        show: false
                    },
                    responsive: [
                        {
                            options: {
                                chart: {
                                    width: 200
                                },
                                legend: {
                                    position: "bottom"
                                }
                            }
                        }
                    ]
                }
            }
        };
    },
    
    watch: {
        watchAll: {
            handler: async function(val) {
                setTimeout(() => {
                    this.updateChartData({period: this.period, filter: this.filterForm});
                }, 500);
            },
            deep: true,
            immediate: true
        },
       
    },
    computed: {
        ...mapGetters({
            reasonsDeals: "home/reasonsDeals"
        }),
        watchAll(){
          return (this.period + JSON.stringify(this.filterForm));
        }
    },
    methods: {
        ...mapActions({
            updateReasonsDeals: "home/reasonsDeals"
        }),

        updateChartData(data){
            this.updateReasonsDeals(data)
                .then(() => {
                    if(this.reasonsDeals.ids && this.reasonsDeals.ids.length > 0){
                        this.deletTask.series = this.reasonsDeals.counts;
                        this.deletTask = {...this.deletTask, ...{
                           
                            chartOptions: {
                                legend: {
                                    show: false
                                },
                                // dataLabels: {
                                //     enabled: true,
                                //     formatter: function (val, { seriesIndex, dataPointIndex, w }) {
                                //         // return w.config.series[seriesIndex] + " / " + val + "%"
                                //         return val + "%"
                                //     },
                                // },
                                labels: JSON.parse(JSON.stringify(this.reasonsDeals.names)),
                            }
                        }}
                    }
                });
        },

    }
};
</script>
<style>
.eror {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 400px;
}
.eror h1 {
    color: #fff;
    font-size: 20px;
}
.eror img {
    width: 160px;
}
</style>
