import { i18n } from "@/utils/i18n";

export const columns = {
    id: {
        show: true,
        title: i18n.t("message.n"),
        sortable: true,
        column: "id"
    },
    name: {
        show: true,
        title: i18n.t("message.name"),
        sortable: true,
        column: "name"
    },
    mfo: {
        show: true,
        title: i18n.t("message.mfo"),
        sortable: true,
        column: "mfo"
    },
    okonx: {
        show: true,
        title: i18n.t("message.okonx"),
        sortable: true,
        column: "okonx"
    },

    oked: {
        show: true,
        title: i18n.t("message.oked"),
        sortable: true,
        column: "oked"
    },

    rkp_nds: {
        show: true,
        title: i18n.t("message.rkp_nds"),
        sortable: true,
        column: "rkp_nds"
    },

    work_phone: {
        show: true,
        title: i18n.t("message.work_phone"),
        sortable: true,
        column: "work_phone"
    },
    country_id: {
        show: true,
        title: i18n.t("message.country_id"),
        sortable: true,
        column: "country_id"
    },

    city_id: {
        show: true,
        title: i18n.t("message.city_id"),
        sortable: true,
        column: "city_id"
    },
    work_place: {
        show: false,
        title: i18n.t("message.work_place"),
        sortable: true,
        column: "work_place"
    },
    legal_address: {
        show: false,
        title: i18n.t("message.legal_address"),
        sortable: true,
        column: "legal_address"
    },
    landmark: {
        show: false,
        title: i18n.t("message.landmark"),
        sortable: true,
        column: "landmark"
    },

    residence_place: {
        show: false,
        title: i18n.t("message.residence_place"),
        sortable: true,
        column: "residence_place"
    },

    comment: {
        show: false,
        title: i18n.t("message.comment"),
        sortable: true,
        column: "comment"
    },

    created_at: {
        show: true,
        title: i18n.t("message.created_at"),
        sortable: true,
        column: "created_at"
    },
    updated_at: {
        show: true,
        title: i18n.t("message.updated_at"),
        sortable: true,
        column: "updated_at"
    },
    settings: {
        show: true,
        title: i18n.t("message.settings"),
        sortable: false,
        column: "settings"
    }
};
