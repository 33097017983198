<template >
  <div>
    <div class="app-modal__box">
      <div class="app-modal__in">
        <div class="app-modal__header d-flex f-between p-5 m-title-modal">
          <p class="large--title m-0">
            {{
              $t("message.edit_m", {
                m: $t("message.client"),
              })
            }}
          </p>
          <div>
            <crm-store-update-close
              :permission="$options.name"
              :button_type="'store'"
              :loading="loadingButton"
              @c-submit="submit(true)"
              @c-close="close()"
            ></crm-store-update-close>
          </div>
        </div>
      </div>
      <!-- app-modal__header end -->

      <div class="app-modal__body p-5 pb-0" v-loading="loadingData">
        <div class="timeline-items__right rounded-sm w-100 p-4">
          <el-form ref="form" :model="form" :rules="rules" label-position="top">
            <el-row :gutter="20">
              <el-col :span="8">
                <el-form-item
                  :label="$t('message.full_name')"
                  prop="full_name"
                  class="label_mini"
                >
                  <crm-input
                    :size="'medium'"
                    :inputValue="form.full_name"
                    v-model="form.full_name"
                    :placeholder="$t('message.full_name')"
                  ></crm-input>
                </el-form-item>
              </el-col>
              <!-- end col -->
              <el-col :span="8">
                <el-form-item
                  :label="$t('message.company')"
                  prop="company"
                  class="label_mini"
                >
                  <select-company
                    :size="'medium'"
                    :placeholder="columns.company_id.title"
                    :id="form.company_id"
                    v-model="form.company_id"
                  >
                  </select-company>
                </el-form-item>
              </el-col>
              <!-- end col -->
              <el-col :span="8">
                <el-form-item
                  :label="$t('message.date_of_birth')"
                  prop="date_of_birth"
                  class="label_mini"
                >
                  <el-date-picker
                    class="w-100"
                    size="medium"
                    v-model="form.date_of_birth"
                    type="date"
                    :placeholder="$t('message.date_of_birth')"
                    format="yyyy-MM-dd"
                    value-format="yyyy-MM-dd"
                  ></el-date-picker>
                </el-form-item>
              </el-col>
              <!-- end col -->
              <el-col :span="8">
                <el-form-item
                  :label="$t('message.passport_serial_number')"
                  prop="passport_serial_number"
                  class="label_mini"
                >
                  <crm-input
                    :size="'medium'"
                    :inputValue="form.passport_serial_number"
                    v-model="form.passport_serial_number"
                    :placeholder="$t('message.passport_serial_number')"
                  ></crm-input>
                </el-form-item>
              </el-col>
              <!-- end col -->
              <el-col :span="8">
                <el-form-item
                  :label="$t('message.type')"
                  prop="type_id"
                  class="label_mini"
                >
                  <select-client-type
                    :size="'medium'"
                    :placeholder="columns.type_id.title"
                    :id="form.type_id"
                    v-model="form.type_id"
                  >
                  </select-client-type>
                </el-form-item>
              </el-col>
              <!-- end col -->
              <el-col :span="8">
                <crm-phone-input
                    :inputValue="form.phone" 
                    v-model="form.phone"
                    @getPhoneStatus="getPhoneStatus"
                    :form_prop="'phone'"
                    :country_code="form.phone_country_code"
                ></crm-phone-input>
              </el-col>

              <!-- end col -->
              <el-col :span="8">
                <el-form-item
                  :label="$t('message.passport_validity')"
                  prop="passport_validity"
                  class="label_mini"
                >
                  <el-date-picker
                    class="w-100"
                    size="medium"
                    v-model="form.passport_validity"
                    type="date"
                    :placeholder="$t('message.passport_validity')"
                    format="yyyy-MM-dd"
                    value-format="yyyy-MM-dd"
                  ></el-date-picker>
                </el-form-item>
              </el-col>
              <!-- end col -->
              <el-col :span="8">
                <el-form-item
                  :label="$t('message.actual_address')"
                  prop="actual_address"
                  class="label_mini"
                >
                  <crm-input
                    :size="'medium'"
                    :inputValue="form.actual_address"
                    v-model="form.actual_address"
                    :placeholder="$t('message.actual_address')"
                  ></crm-input>
                </el-form-item>
              </el-col>
              <!-- end col -->
              <el-col :span="8">
                <el-form-item
                  :label="$t('message.email')"
                  prop="email"
                  class="label_mini"
                >
                  <crm-input
                    :size="'medium'"
                    :inputValue="form.email"
                    v-model="form.email"
                    :placeholder="$t('message.email')"
                  ></crm-input>
                </el-form-item>
              </el-col>
              <!-- end col -->

              <el-col :span="8">
                <el-form-item
                  :label="$t('message.inn')"
                  prop="inn"
                  class="label_mini"
                >
                  <crm-input
                    :size="'medium'"
                    :inputValue="form.inn"
                    v-model="form.inn"
                    :placeholder="$t('message.inn')"
                  ></crm-input>
                </el-form-item>
              </el-col>
              <!-- end col -->
              <el-col :span="8">
                <el-form-item
                  :label="$t('message.gender')"
                  prop="gender"
                  class="label_mini"
                >
                  <select-client-gender
                    :size="'medium'"
                    :placeholder="columns.gender_id.title"
                    :id="form.gender_id"
                    v-model="form.gender_id"
                  >
                  </select-client-gender>
                </el-form-item>
              </el-col>
              <!-- end col -->
              <el-col :span="8">
                <el-form-item
                  :label="$t('message.clientTypes')"
                  class="label_mini"
                >
                  <select-client-types
                    ref="clientTypes"
                    v-model="form.clientType_ids"
                    :clientType_ids="form.clientType_ids"
                    :size="'medium'"
                    :placeholder="$t('message.clientTypes')"
                  >
                  </select-client-types>
                </el-form-item>
              </el-col>
              <!-- end col -->
               <el-col :span="8">
                <el-form-item
                  :label="$t('message.country_id')"
                  class="label_mini"
                >
                  <select-country
                    :size="'medium'"
                    :placeholder="columns.country_id.title"
                    :id="form.country_id"
                    v-model="form.country_id"
                  >
                  </select-country>
                </el-form-item>
              </el-col>
              <!-- end col -->

              <el-col :span="8">
                <el-form-item
                  :label="$t('message.city_id')"
                  class="label_mini"
                >
                   <select-city
                    :size="'medium'"
                    :placeholder="columns.city_id.title"
                    :id="form.city_id"
                    :country_id="form.country_id"
                    v-model="form.city_id"
                  >
                  </select-city>
                </el-form-item>
              </el-col>
              <!-- end col -->

              <el-col :span="24">
                <el-upload
                  class="avatar-uploader"
                  :show-file-list="false"
                  action="/"
                  :on-change="updateImageList"
                  list-type="picture-card"
                  accept="image/jpeg, image/png"
                  :auto-upload="false"
                >
                  <img v-if="imageUrl" :src="imageUrl" class="avatar" />
                  <i v-else class="el-icon-plus"></i>
                </el-upload>
              </el-col>

            </el-row>
          </el-form>
        </div>
      </div>
      <!-- end app-modal__body -->

      <contact-people ref="contactPeople"></contact-people>

      <client-account ref="clientAccount"></client-account>
    </div>
  </div>
</template>
<script>
import selectCompany from "@/components/inventory/select-company";
import selectClientType from "@/components/inventory/select-client-type";
import selectClientGender from "@/components/inventory/select-client-gender";
import contactPeople from "./clientPerson/contact-people";
import clientAccount from "./clientPerson/client-accounts";
import selectClientTypes from "@/components/multiSelects/multi-select-client-types";
import selectCountry from "@/components/inventory/select-country";
import selectCity from "@/components/inventory/select-city";

import { mapGetters, mapActions } from "vuex";
import drawer from "@/utils/mixins/drawer";
import form from "@/utils/mixins/form";
import show from "@/utils/mixins/show";

export default {
  components: {
    selectCompany,
    selectClientType,
    selectClientGender,
    contactPeople,
    clientAccount,
    selectClientTypes,
    selectCountry,
    selectCity,
  },
  mixins: [form, drawer, show],

  data() {
    return {
      updateImage: [],
      imageUrl: "", 
      is_phone_valid: false
    };
  },
  created() {},
  computed: {
    ...mapGetters({
      rules: "clients/rules",
      model: "clients/model",
      columns: "clients/columns",
    }),
  },
  methods: {
    ...mapActions({
      update: "clients/update",
      show: "clients/show",
    }),
    getPhoneStatus({is_valid, countryCode}){
        this.is_phone_valid = is_valid;
        this.form.phone_country_code = countryCode;
    },
    afterOpen() {
      this.fetchData();
    },
    fetchData() {
      if (!this.loadingData) {
        this.loadingData = true;
        this.show(this.selectedItem.id)
          .then((res) => {
            if (this.form.avatar) {
              this.imageUrl = this.form.image_url + '/' + this.form.avatar[0].path;
            }
            if (this.form.client_contact_people && this.form.client_contact_people.length > 0) {
              this.$refs.contactPeople.object = JSON.parse(JSON.stringify(this.form.client_contact_people));
            }
            if (this.form.client_checking_accounts && this.form.client_checking_accounts.length > 0) {
              this.$refs.clientAccount.object = JSON.parse(JSON.stringify(this.form.client_checking_accounts));
            }
            this.loadingData = false;
          })
          .catch((err) => {
            this.loadingData = false;
          });
      }
    },
    updateImageList(file) { 
      if(file.size < 5200000){
        this.imageUrl = URL.createObjectURL(file.raw);
        this.updateImage = [];
        this.updateImage.push(file.raw);
      }else{
        this.$message({
          type: "warning",
          message: "Размер изображения должен быть меньше 5 МБ"
        });
      }
    },
    submit(close = true) {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          if(this.is_phone_valid){

            this.form.client_contact_people = this.$refs.contactPeople.object;
            this.form.client_checking_accounts = this.$refs.clientAccount.object;
            this.form.gender = this.form.gender_id
            let formData = new FormData();

            for (const key in this.form) {
              if (key != "avatar" && key !== 'client_contact_people' && key !== 'client_checking_accounts' && key !== 'clientType_ids') {
                if (this.form[key]) {
                  formData.append(key, this.form[key]);
                } else {
                  formData.append(key, "");
                }
              }else if(key === 'client_contact_people' || key === 'client_checking_accounts' || key === 'clientType_ids'){
                formData.append(key, JSON.stringify(this.form[key]));
              }
            }
            if (_.size(this.updateImage) > 0) {
              for (const key in this.updateImage) {
                if (this.updateImage.hasOwnProperty(key)) {
                  const element = this.updateImage[key];
                  formData.append("avatar", element);
                }
              }
            }
            formData.append('_method', 'PUT');

            this.loadingButton = true;
            this.update(formData)
              .then((res) => {
                this.loadingButton = false;
                this.$alert(res);
                this.parent().listChanged();
                if (close) this.close();
              })
              .catch((err) => {
                this.loadingButton = false;
                this.$alert(err);
              });
          }else{
            this.$notify({
                title: this.$t('message.validation'),
                type: "error",
                offset: 130,
                message: this.$t('message.phone_number_is_not_valid')
            });
          }
       
        }
      });
    },
  },
};
</script>
