<template>
    <div class="khan-chart-a">
        <div id="chart">
            <apexchart
                type="bar"
                height="350"
                :options="chartOptions"
                :series="series"
            ></apexchart>
        </div>
    </div>
</template>
<script>
import ApexCharts from "apexcharts";
import { mapGetters, mapActions } from "vuex";
import _ from 'lodash';
import { formatMoney } from "@/filters/index";

export default {
    components: {
        ApexCharts,
    },
    props: {
        filterForm: {
            default: Object,
        },
    },
    data() {
        return {
            series: [
                {
                    name: 'total_amount',
                    data: [],
                },
            ],
            chartOptions: {
                chart: {
                    type: "bar",
                    height: 350,
                },
                colors: ["#feb019", "#00e396", "#008ffb", "#775dd0", "#ff4560"],

                plotOptions: {
                    bar: {
                        horizontal: false,
                        columnWidth: "55%",
                        endingShape: "rounded",
                    },
                },
                dataLabels: {
                    enabled: false,
                },

                stroke: {
                    show: true,
                    width: 2,
                    colors: ["transparent"],
                },
                value: {
                    fontSize: "16px",
                    color: "#fff",
                },
                xaxis: {
                    categories: [
                        // "L/C",
                        // "T/T",
                        // "CAD",
                        // "L/C after 60",
                        // "Pre payment",
                        // "Bank transfer",
                    ],
                },
                yaxis: {
                    labels: {
                        show: true,
                        formatter: function (value) {
                            return formatMoney(value, 2);
                        },
                    },
                },
                fill: {
                    opacity: 1,
                    colors: [
                        "#feb019",
                        "#00e396",
                        "#008ffb",
                        "#775dd0",
                        "#ff4560",
                    ],
                },
                tooltip: {
                    color: "#fff",
                },

                // grid: {
                //     row: {
                //         colors: ["#F44336", "#E91E63", "#9C27B0"],
                //     },
                //     column: {
                //         colors: ["#F44336", "#E91E63", "#9C27B0"],
                //     },
                // },
            },
        };
    },
    watch: {
        filterForm: {
            handler: async function (val) {
                setTimeout(() => {
                    this.updateDealPaymentByCountry(val)
                        .then(res => {
                            this.fetchData();
                        })
                }, 500);
            },
            deep: true,
            immediate: true,
        },
    },
    created() {
        this.updateDealPaymentByCountry()
            .then(res => {
                this.fetchData();
            })
    },
    computed: {
        ...mapGetters({
            dealPaymentByCountry: "home/dealPaymentByCountry",
        }),
    },
    methods: {
        ...mapActions({
            updateDealPaymentByCountry: "home/dealPaymentByCountry",
        }),
        fetchData(){
            this.series[0].name = this.$t('message.total_amount');
            this.series[0].data = this.dealPaymentByCountry.map(item => item.money_amount);
            this.chartOptions.xaxis.categories = this.dealPaymentByCountry.map(item => item.name)
            let self = this;
            this.chartOptions = {...this.chartOptions, ...{
                    chartOptions: {
                        xaxis: {
                            categories: self.dealPaymentByCountry.map(item => item.name),
                        },
                    },
                },
            };
        }
    }

};
</script>

<style>
/* .eror{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 400px;
}
.eror h1{
    color: #fff;
    font-size: 20px;
}
.eror img{
  width: 160px;
} */
</style>
