<template>
    <div class="shadow task-scroll-khan-product h453" >
        <div class="mt-4">
            <div class="itme-pragress-big" v-for="category in categoryStatistics" :key="category.id">
                <span class="t-color itme-pragress-user-name"
                    > {{ category.name }} - {{ category.money_amount | formatMoney(2) }}</span
                >
                <span class="itme-pragress">
                    <el-progress
                        class="psum"
                        :text-inside="true"
                        :stroke-width="26"
                        :percentage="100"
                        :format="format"
                    ></el-progress>
                    <el-progress
                        class="top_R"
                        :text-inside="true"
                        :stroke-width="24"
                        :percentage="category.money_amount > 0 ? Math.round(category.profit*100/category.money_amount) : 0"
                        status="success"
                    ></el-progress>
                </span>
                <ul class="rezultat">
                    <li>{{ $t("message.Total amount") }}: <span>{{ category.money_amount | formatMoney(2) }}</span></li>
                    <li>{{ $t("message.profit_m") }}: <span>{{ category.profit | formatMoney(2) }}</span></li>
                </ul>
            </div>
            
        </div>
    </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { formatMoney } from "@/filters/index";

export default {
    components: {},
    props: {
        period: {
            default: "weekly",
        },
        filterForm: {
            default: null,
        },
        parent_id: {
            default: null,
        },
    },
    data() {
        return {
            radio1: "Неделя",
            count: 1,
            counts: [],
            items: [],
        };
    },
    watch: {
        update_data: {
            handler: async function (val) {
                setTimeout(() => {
                    this.updateChartData({
                        parent_id: this.parent_id,
                        period: this.period,
                        filter: this.filterForm,
                    });
                }, 500);
            },
            deep: true,
            immediate: true,
        },
    },
    computed: {
        ...mapGetters({
            categoryStatistics: "home/categoryStatisticBySellingProduct",
        }),
        update_data() {
            return this.period + this.parent_id;
        }
    },
    methods: {
        ...mapActions({
            categoryStatisticsData: "home/categoryStatisticBySellingProduct",
        }),
        format(percentage) {
            return '';
        },
        updateChartData(data) {
            this.categoryStatisticsData(data)
                .then(res => {
                    this.items = JSON.parse(JSON.stringify(this.categoryStatistics));

                })
        }
    },
};
</script>
<style>
.h453 {
    height: 453px;
}
</style>
