<template>
  <div>
    <el-row class="justify__box">
      <!-- <el-col class="pick__color" :span="3"><div></div></el-col> -->
      <el-col :span="24">
        <el-select :value="selected" @input="dispatch" :placeholder="placeholder || $t('message.color')" filterable clearable
          @change="getColor" :size="size" class="w-100">
          <el-option v-for="(color,index) in allowed_colors" :key="'colors-' + index" :label="color.name" :value="color.id" :style="'color:white; background:' + color.color">
          </el-option>
        </el-select>
      </el-col>
    </el-row>

  </div>
</template>
<script>
  import {mapGetters, mapActions} from "vuex";
  export default {
    props: {
      size: {
        default: 'small'
      },
      placeholder: {
        default: null,
      },
      id:{
        default: null
      },
      allowed_colors: {
        default: Array
      }
    },
    watch:{
      id:{
        handler: function() {
            this.selected = this.id
        },
        immediate: true
      },
    },
    data() {
      return {
        selected: null,
        selected_color: '',
      }
    },
    computed: {
      ...mapGetters({
        colors: 'colors/inventory'
      })
    },
    methods: {
      ...mapActions({
        updateInventory: 'colors/inventory'
      }),
      dispatch(e) {
        this.$emit('input', e)
        this.selected = e
      },
      getColor(value){
        if(value){
          let color = JSON.parse(JSON.stringify(this.allowed_colors.find(item => item.id === value))) 
          if(color){
            this.selected_color = color.color
          }else{
            this.selected_color = ''
          }
        }else{
          this.selected_color = ''
        }
      }
    },
  }

</script>
<style scoped>
  .justify__box {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .pick__color {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .pick__color div {
    display: block;
    width: 16px;
    height: 16px;
    background: red;
    border-radius: 0.275rem;
  }
</style>
