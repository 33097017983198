export const model = {
  id : null,  
  name: '', 
  code: null, 
  buy_price: '', 
  buy_price_currency_id: '', 
  selling_price: '', 
  selling_price_currency_id: '', 
  measurement_id: '', 
  product_model_id: null, 
  color_id: null, 
  is_default: false, 
  categories: [], 
  packages: [], 
  images: [], 
  created_at: '',
  updated_at: ''  
  };
  