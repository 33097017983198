<template>
    <aside :class="[menu ? 'is-expanded' : '', mode ? 'menuday' : 'menunight']">
        <div class="main__box">
            <div
                class="box__menu"
                :class="[
                    fixed ? 'all__size' : '',
                    menu ? 'opened__full' : '',
                    mode ? 'box__menuday' : 'box__menunight',
                ]"
            >
                <div class="menu">
                    <router-link
                        tag="a"
                        to="/"
                        :class="menu ? 'button' : 'buttonclose'"
                        class="button__fly"
                    >
                        <span
                            class="navigation__list"
                            :class="[mode ? 'iconsday' : 'iconsnight']"
                            ><span class="deshboard"></span
                        ></span>
                        <transition name="slide-fade">
                            <div
                                v-if="menu"
                                :class="mode ? 'text' : 'textnight'"
                            >
                                {{ $t("message.home") }}
                            </div>
                        </transition>
                    </router-link>
                </div>
                <div
                    v-for="buy in list"
                    :key="buy.id"
                    class="menu"
                    :class="buy.listOne && menu ? 'open__list' : ''"
                >
                    <a
                        @click="
                            menu
                                ? (buy.listOne = !buy.listOne)
                                : [
                                      openToggle,
                                      (buy.open = !buy.open),
                                      (fixed = !fixed),
                                  ]
                        "
                        class="button__fly"
                        :class="[
                            menu ? 'button' : 'buttonclose',
                            buy.listOne && buy.items ? 'hover' : '',
                        ]"
                        @mouseover="[(buy.open = true), (fixed = true)]"
                        @mouseleave="[(buy.open = false), (fixed = false)]"
                    >
                        <span
                            class="navigation__list"
                            :class="[mode ? 'iconsday' : 'iconsnight']"
                            ><span :class="buy.icon"></span
                        ></span>
                        <transition name="slide-fade">
                            <div
                                v-if="menu"
                                :class="mode ? 'text' : 'textnight'"
                            >
                                {{ $t(`${buy.name}`) }}
                            </div>
                            <div
                                @click="closeSub(buy.open)"
                                v-if="buy.open"
                                class="submenu"
                            >
                                <ul
                                    class="submenu__ul"
                                    :class="
                                        mode
                                            ? 'submenu__ulday'
                                            : 'submenu__ulnight'
                                    "
                                >
                                    <li
                                        class="submenu__title"
                                        :class="
                                            mode
                                                ? 'submenu__titleday'
                                                : 'submenu__titlenight'
                                        "
                                    >
                                        {{ $t(`${buy.name}`) }}
                                    </li>
                                    <router-link
                                        tag="li"
                                        :to="{
                                            name: l.link,
                                            params: {
                                                warehouse_type: l.params,
                                            },
                                        }"
                                        v-for="l in buy.items"
                                        v-can="l.v_can"
                                        :key="l.id"
                                        class="submenu__item"
                                        :class="
                                            mode
                                                ? 'submenu__itemday'
                                                : 'submenu__itemnight'
                                        "
                                        >{{ $t(`${l.name}`) }}</router-link
                                    >
                                </ul>
                            </div>
                        </transition>
                    </a>
                    <router-link
                        v-if="buy.listOne && menu"
                        :class="buy.listOne ? 'hover' : ''"
                        class="sub__button"
                        tag="a"
                        :to="{ name: n.link, params: { id: n.params } }"
                        v-for="(n, index) in buy.items"
                        :key="index"
                    >
                        <div :class="mode ? 'text' : 'textnight'">
                            <span
                                :class="
                                    mode ? 'icons__sizeday' : 'icons__sizenight'
                                "
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                >
                                    <path
                                        d="M12.6343 12.5657L8.45001 16.75C8.0358 17.1642 8.0358 17.8358 8.45001 18.25C8.86423 18.6642 9.5358 18.6642 9.95001 18.25L15.4929 12.7071C15.8834 12.3166 15.8834 11.6834 15.4929 11.2929L9.95001 5.75C9.5358 5.33579 8.86423 5.33579 8.45001 5.75C8.0358 6.16421 8.0358 6.83579 8.45001 7.25L12.6343 11.4343C12.9467 11.7467 12.9467 12.2533 12.6343 12.5657Z"
                                        fill="currentColor"
                                    />
                                </svg>
                            </span>
                            <span class="text__size">
                                {{
                                    $t(`${n.name}`) | truncate(18, "...")
                                }}</span
                            >
                        </div>
                    </router-link>
                </div>
                <div class="menu">
                    <router-link
                        tag="a"
                        to="calendar"
                        :class="menu ? 'button' : 'buttonclose'"
                        class="button__fly"
                    >
                        <span
                            class="navigation__list"
                            :class="[mode ? 'iconsday' : 'iconsnight']"
                            ><span class="deshboard"></span
                        ></span>
                        <transition name="slide-fade">
                            <div
                                v-if="menu"
                                :class="mode ? 'text' : 'textnight'"
                            >
                                {{ $t("message.home") }}
                            </div>
                        </transition>
                    </router-link>
                </div>
            </div>
        </div>
    </aside>
</template>

<script>
export default {
    data: () => ({
        list: [
            {
                link: false,
                name: "message.deals",
                tooltip: "Dashboard",
                icon: "transactions",
                open: false,
                listOne: false,
                v_can_or: [
                    "boards.index",
                    "deals.index",
                    "deals.archivedDeals",
                    "deals.deletedDeals",
                    "dealTypes.index",
                    "reasons.index",
                ],
                items: [
                    {
                        name: "message.deals",
                        link: "deals",
                        v_can: "boards.index",
                    },
                    {
                        name: "message.archivedDeals",
                        link: "archivedDeals",
                        v_can: "deals.archivedDeals",
                    },
                    {
                        name: "message.deletedDeals",
                        link: "deletedDeals",
                        v_can: "deals.deletedDeals",
                    },
                    {
                        name: "message.dealTypes",
                        link: "dealTypes",
                        v_can: "dealTypes.index",
                    },
                    {
                        name: "message.deal_reasons",
                        link: "reasons",
                        v_can: "reasons.index",
                    },
                ],
            },
            {
                link: "/purchase",
                name: "Касса",
                tooltip: "User",
                icon: "transactions",
                open: false,
                listOne: false,
                v_can_or: [],
                items: [
                    {
                        name: "Платежи",
                        link: "payments",
                        v_can: "reasons.index",
                    },
                    {
                        name: "Платежи за расходы",
                        link: "costTransactions",
                        v_can: "reasons.index",
                    },
                    {
                        name: "message.currencies",
                        link: "currency",
                        v_can: "reasons.index",
                    },
                ],
            },
            {
                link: "#",
                name: "Texno",
                tooltip: "User",
                icon: "transactions",
                open: false,
                listOne: false,
                v_can_or: ["taskBoards.index"],
                items: [
                    {
                        name: "Нереализованные товары",
                        link: "regionsProducts",
                        v_can: "taskBoards.index",
                    },
                    {
                        name: "Продукции",
                        link: "texnoProduct",
                        v_can: "taskBoards.index",
                    },
                    // {
                    //     name: "Реализованные товары",
                    //     link: "texnoProductFull",
                    //     v_can: "taskBoards.index",
                    // },
                    // {
                    //     name: "Продажи",
                    //     link: "texnosale",
                    //     v_can: "taskBoards.index",
                    // },
                    // {
                    //     name: "Добавить заявку ",
                    //     link: "texnoApplicationsAdd",
                    //     v_can: "taskBoards.index",
                    // },
                    {
                        name: "Happy call",
                        link: "texnoHappy",
                        v_can: "taskBoards.index",
                    },
                    {
                        name: "Заявки",
                        link: "texnoApplicationsAll",
                        v_can: "taskBoards.index",
                    },
                ],
            },
            {
                link: "#",
                name: "message.tasks",
                tooltip: "Messages",
                icon: "taskss",
                open: false,
                listOne: false,
                v_can_or: [
                    "taskBoards.index",
                    "tasks.archivedTasks",
                    "tasks.deletedTasks",
                    "taskReasons.index",
                ],
                items: [
                    {
                        name: "message.tasks",
                        link: "tasks",
                        v_can: "taskBoards.index",
                    },
                    {
                        name: "message.completed_tasks",
                        link: "archivedTasks",
                        v_can: "tasks.archivedTasks",
                    },
                    {
                        name: "message.deletedTasks",
                        link: "deletedTasks",
                        v_can: "tasks.deletedTasks",
                    },
                    {
                        name: "message.taskReasons",
                        link: "taskReasons",
                        v_can: "taskReasons.index",
                    },
                ],
            },
            {
                link: "#",
                name: "message.calls",
                tooltip: "Analytics",
                icon: "calling",
                open: false,
                listOne: false,
                v_can_or: [
                    "sipAccounts.index",
                    "phoneContacts.index",
                    "phoneNumberForSipAccountOutsideCall.index",
                    "callHistory.index",
                ],
                items: [
                    {
                        name: "message.call_history",
                        link: "callHistories",
                        v_can: "callHistory.index",
                    },
                    {
                        name: "message.phoneContacts",
                        link: "phoneContacts",
                        v_can: "phoneContacts.index",
                    },
                    {
                        name: "message.sipAccounts",
                        link: "sipAccount",
                        v_can: "sipAccounts.index",
                    },
                    {
                        name: "message.phoneNumberForSipAccountOutsideCall",
                        link: "phoneNumberForSipAccountOutsideCall",
                        v_can: "phoneNumberForSipAccountOutsideCall.index",
                    },
                ],
            },
            {
                link: "#",
                name: "message.counterparties",
                tooltip: "Files",
                icon: "add-user",
                open: false,
                listOne: false,
                v_can_or: ["clients.index"],
                items: [
                    {
                        name: "message.clients",
                        link: "clients",
                        v_can: "clients.index",
                    },
                    {
                        name: "Услуги",
                        link: "servicesTexno",
                        v_can: "clients.index",
                    },
                    {
                        name: "Поставщики услуг",
                        link: "companyTexno",
                        v_can: "clients.index",
                    },
                    {
                        name: "Мастера",
                        link: "mastersTexno",
                        v_can: "clients.index",
                    },
                ],
            },
            {
                link: "#",
                name: "message.h_r",
                tooltip: "Order",
                icon: "add-user2",
                open: false,
                listOne: false,
                v_can_or: ["users.index", "roles.index", "permissions.index"],
                items: [
                    {
                        name: "message.users",
                        link: "users",
                        v_can: "users.index",
                    },
                    {
                        name: "message.roles",
                        link: "roles",
                        v_can: "roles.index",
                    },
                    {
                        name: "message.permissions",
                        link: "permissions",
                        v_can: "permissions.index",
                    },
                ],
            },
            {
                link: "#",
                name: "message.settings",
                tooltip: "Saved",
                icon: "add-settings",
                open: false,
                listOne: false,
                v_can_or: [
                    "dealStages.index",
                    "paymentTypes.index",
                    "sources.index",
                    "costTypes.index",
                    "companies.index",
                    "filials.index",
                    "countries.index",
                    "cities.index",
                    "categories.index",
                    "clientTypes.index",
                    "dealGoals.index",
                    "dealConditions.index",
                    "shipmentTerms.index",
                    "kPExampleTemplates.index",
                    "colors.index",
                    "packages.index",
                ],
                items: [
                    {
                        name: "message.kpTemplates",
                        link: "kpTemplate",
                        v_can: "kPExampleTemplates.index",
                    },
                    {
                        name: "message.dealStages",
                        link: "dealStage",
                        v_can: "dealStages.index",
                    },
                    {
                        name: "message.paymentTypes",
                        link: "paymentTypes",
                        v_can: "paymentTypes.index",
                    },
                    {
                        name: "message.sources",
                        link: "sources",
                        v_can: "sources.index",
                    },
                    {
                        name: "message.costTypes",
                        link: "costType",
                        v_can: "costTypes.index",
                    },
                    {
                        name: "message.companies",
                        link: "companies",
                        v_can: "companies.index",
                    },
                    {
                        name: "message.filial",
                        link: "filial",
                        v_can: "filials.index",
                    },
                    {
                        name: "message.countries",
                        link: "country",
                        v_can: "countries.index",
                    },
                    {
                        name: "message.cities",
                        link: "city",
                        v_can: "cities.index",
                    },
                    {
                        name: "message.categories",
                        link: "category",
                        v_can: "categories.index",
                    },
                    {
                        name: "message.clientTypes",
                        link: "clientTypes",
                        v_can: "clientTypes.index",
                    },
                    {
                        name: "message.dealGoals",
                        link: "dealGoals",
                        v_can: "dealGoals.index",
                    },
                    {
                        name: "message.dealConditions",
                        link: "dealConditions",
                        v_can: "dealConditions.index",
                    },
                    {
                        name: "message.shipmentTerms",
                        link: "shipmentTerms",
                        v_can: "shipmentTerms.index",
                    },
                    {
                        name: "message.colors",
                        link: "colors",
                        v_can: "colors.index",
                    },
                    {
                        name: "message.packages",
                        link: "packages",
                        v_can: "packages.index",
                    },
                ],
            },
            {
                link: "#",
                name: "message.product_settings",
                tooltip: "Setting",
                icon: "add-settings",
                open: false,
                listOne: false,
                v_can_or: [
                    "products.index",
                    "measurements.index",
                    "productSizes.index",
                    "productModels.index",
                ],
                items: [
                    {
                        name: "message.products",
                        link: "product",
                        v_can: "products.index",
                    },
                    {
                        name: "message.measurements",
                        link: "measurements",
                        v_can: "measurements.index",
                    },
                    {
                        name: "message.productSizes",
                        link: "productSizes",
                        v_can: "productSizes.index",
                    },
                    {
                        name: "message.productModels",
                        link: "productModels",
                        v_can: "productModels.index",
                    },
                ],
            },
        ],
        fixed: false,
    }),
    computed: {
        menu() {
            return this.$store.getters.MENU;
        },
        mode() {
            return this.$store.getters.MODE;
        },
    },
    methods: {
        ToggleMenu() {
            this.is_expanded = !this.is_expanded;
        },
        openToggle() {
            this.$store.commit("toggleMenu");
        },
        closeSub(item) {
            item = false;
            this.fixed = true;
        },
    },
    filters: {
        truncate: function (text, length, suffix) {
            if (text.length > length) {
                return text.substring(0, length) + suffix;
            } else {
                return text;
            }
        },
    },
};
</script>

<style >
/* :root {
    --primary: #00b2ff;
    --grey: #64748b;
    --dark: #1e293b;
    --dark-alt: #334155;
    --light: #f1f5f9;
    --sidebar-width: 300px;
} */
aside {
    display: flex;
    flex-direction: column;
    width: calc(2rem + 32px);
    min-height: 100%;
    max-height: 100%;
    overflow: hidden;
    padding: 0rem 1rem 0rem 1rem;
    color: #f1f5f9;
    position: relative;
    z-index: 40;
    transition: opacity 0.1s ease-out;
}
.all__size {
    width: 100%;
}
.menuday {
    background-color: #fff;
}
.menunight {
    background: #100e1d;
}
.logo {
    margin-bottom: 1rem;
}
.logo img {
    width: 2rem;
}
.buttonclose:before {
    position: absolute;
    left: 5px;
    margin-top: 15px;
    content: "";
    width: 5px;
    height: 5px;
    background-color: #bdc5d1;
    opacity: 0;
    border-radius: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    transition: opacity 0s;
}
.buttonclose:hover::before {
    opacity: 1;
}
.open__list:before {
    position: absolute;
    left: 5px;
    margin-top: 25px;
    content: "";
    width: 5px;
    height: 5px;
    background-color: #bdc5d1;
    opacity: 1;
    border-radius: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    transition: opacity 0s;
}
.menu-toggle-wrap {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 1rem;
    position: relative;
    transition: opacity 0.1s ease-out;
    top: 0;
}
.sub__button:hover .text__size {
    color: #00b2ff;
}
.sub__button:hover .icons__sizeday svg path {
    fill: #00b2ff;
}
.sub__button:hover .icons__sizenight svg path {
    fill: #00b2ff;
}
.open__list {
    padding-bottom: 10px;
}

.menu-toggle {
    transition: opacity 0.1s ease-out;
}
.main__box {
    display: flex;
    width: 100%;
    position: relative;
    height: 100%;
}
/* .menu-toggle:hover .material-icons i {
    color: #fff;
    transition: opacity .1s ease-out;
    transform: translateX(0.5rem);
} */
.navigation__list span {
    width: 25px;
    height: 25px;
    margin: 0;
    display: block;
    transition: opacity 0.1s ease-out;
    text-align: center;
    margin-top: 10px;
}
.button__fly:hover .navigation__list span {
    background-image: linear-gradient(
        to bottom,
        #28f19b,
        #00e4cc,
        #00d1f1,
        #00bbff,
        #01a0f9
    );
    background-image: linear-gradient(
        to bottom,
        #28f19b,
        #00e4cc,
        #00d1f1,
        #00bbff,
        #01a0f9
    ) !important;
}
.button__fly:hover .text {
    color: #00b2ff;
}
.button__fly:hover .textnight {
    color: #00b2ff;
}
/* .material-icons span::before {
    font-size: 24px;
    font-weight: 400;
} */
.iconsday {
    color: #030303;
}
.iconsnight {
    color: #fff;
}
.is-expanded {
    width: 220px;
    transition: opacity 0.1s ease-out;
}
.menu-toggle {
    top: -3rem;
    margin-right: -18px;
}
.box__menu {
    position: fixed;
    left: 0;
    padding-right: 0px;
    padding-top: 0px;
    height: 92%;
    overflow-y: scroll;
    overflow-x: hidden;
    direction: rtl;
    padding-bottom: 150px;
}
.opened__full {
    width: 220px;
}
.box__menuday::-webkit-scrollbar {
    width: 3px;
}
.box__menuday {
    scrollbar-width: thin;
    scrollbar-color: #d0d6e3;
}
.box__menuday::-webkit-scrollbar-track {
    background: #ffffff;
}
.box__menuday::-webkit-scrollbar-thumb {
    background-color: #283046;
    border-radius: 10px;
    border: 2px solid #fff0;
}
.box__menunight::-webkit-scrollbar {
    width: 3px;
}
.box__menunight {
    scrollbar-width: thin;
    scrollbar-color: #5f5f5f;
}
.box__menunight::-webkit-scrollbar-track {
    background: #1f1f1f;
}
.box__menunight::-webkit-scrollbar-thumb {
    background-color: #283046;
    border-radius: 10px;
    border: 2px solid #fff0;
}
.menu {
    direction: ltr;
}
.button {
    width: 220px;
    display: flex;
    flex-direction: row;
    align-items: center;
    text-decoration: none;
    padding: 0.6rem 45px 0.6rem 16px;
    cursor: pointer;
    transition: opacity 0s ease-in-out;
}
.buttonclose {
    width: 61px;
    display: flex;
    flex-direction: row;
    align-items: center;
    text-decoration: none;
    padding: 0.6rem 45px 0.6rem 16px;
    transition: opacity 0s ease-in-out;
    cursor: pointer;
}
.sub__button {
    width: 221.5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    text-decoration: none;
    padding: 0rem 6px 0rem 0px;
    cursor: pointer;
    transition: opacity 0s;
}
.activen {
    border-left: 5px solid #f06445;
}

.text {
    display: flex;
    align-items: center;
    color: #030303;
    font-size: 14px;
    font-weight: 400;
    padding-top: 12px;
    margin-left: 10px;
}
.textnight {
    display: flex;
    align-items: center;
    color: #fff;
    font-size: 14px;
    font-weight: 400;
    padding-top: 12px;
    margin-left: 15px;
}

.material-icons {
    margin-right: 10px;
    display: flex;
    align-items: center;
}
.submenu {
    position: relative;
    z-index: 999999999999 !important;
    padding: 10px 0px;
}
.submenu__ul {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    position: absolute;
    z-index: 99999999 !important;
    left: 0;
    margin-top: -20px;
    min-width: 250px;
    max-width: 300px;
    max-height: 250px;
    overflow-y: auto;
    text-align: left;
    padding: 10px 0;
    margin-left: 20px;
    border-radius: 10px;
    direction: ltr;
}

.submenu__ulday {
    background: #fff;
    color: #030303;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
        rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}
.submenu__ulnight {
    background: #100e1d;
    color: #fff;
    box-shadow: rgba(230, 230, 230, 0.02) 0px 1px 3px 0px,
        rgba(80, 86, 92, 0.15) 0px 0px 0px 1px;
}
.submenu__ulday::-webkit-scrollbar {
    width: 8px;
}
.submenu__ulday {
    scrollbar-width: thin;
    scrollbar-color: #ebeef5;
}
.submenu__ulday::-webkit-scrollbar-track {
    background: #ffffff;
    border-radius: 10px;
}
.submenu__ulday::-webkit-scrollbar-thumb {
    background-color: #ebeef5;
    border-radius: 10px;
    border: 2px solid #fff0;
}
.submenu__ulday:hover::-webkit-scrollbar-thumb {
    background-color: #d1d7e4;
    border-radius: 10px;
    border: 2px solid #fff0;
}

.submenu__ulnight::-webkit-scrollbar {
    width: 8px;
}
.submenu__ulnight {
    scrollbar-width: thin;
    scrollbar-color: #7a7a7a;
}
.submenu__ulnight::-webkit-scrollbar-track {
    background: #828282;
    border-radius: 10px;
}
.submenu__ulnight::-webkit-scrollbar-thumb {
    background-color: #7a7a7a;
    border-radius: 10px;
    border: 2px solid #fff0;
}
.submenu__ulnight:hover::-webkit-scrollbar-thumb {
    background-color: #d1d7e4;
    border-radius: 10px;
    border: 2px solid #fff0;
}
.submenu__item {
    display: flex;
    font-size: 14px;
    font-weight: 400;
    padding: 5px 10px;
    border-radius: 10px;
    cursor: pointer;
    transition: opacity 0.1s ease-out;
}
.submenu__item:hover {
    color: #00b2ff;
}
.submenu__title {
    display: flex;
    font-size: 16px;
    font-weight: 600;
    padding: 5px 10px 0px 10px;
    margin-bottom: 5px;
    border-radius: 10px;
}

.icons__sizeday svg {
    width: 18px;
    height: 18px;
}
.icons__sizeday svg path {
    fill: #bdc5d1;
}

.icons__sizenight svg {
    width: 18px;
    height: 18px;
}
.icons__sizenight svg path {
    fill: #fff;
}
.text__size {
    width: 190px;
    font-size: 14px;
}
.sub__icon {
    margin-left: 2px;
}
.sub__icon svg {
    width: 24px;
    height: 24px;
}
@media (max-width: 768px) {
    aside {
        position: fixed;
        z-index: 9999;
    }
}
.slide-fade-enter-active {
    transition: opacity 0.1s ease-out;
}
.slide-fade-leave-active {
    transition: opacity 0.1s ease cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
    /* .slide-fade-leave-active до версии 2.1.8 */ {
    transform: translateX(-20px);
    opacity: 0;
}
</style>
