export const filter = {
    id: null,
    user_id:  null,
    filial_id:  null,
    index: null,
    board_id: null,
    name: '',
    money_amount: null, 
    profit: null, 
    currency_id:  null,
    dealStage_id:  null,
    reception_address: '',
    shipment_term_id:  null,
    phone: '',
    email: '',
    begin_date: '', 
    end_date: '',
    company_id:  null,
    client_id:  null,
    dealType_id:  null,
    source_id:  null,
    reason_id:  null,
    comment: '',
    finish_date: '',
    created_at: '',
    updated_at: '',         
    spectators:  [],   
};