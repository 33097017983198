<template>
    <div>
        <div class="mt-4">
            <div class="itme-pragress-big mengra" v-for="user in arr" :key="user.id">
                <span class="t-color itme-pragress-user-name">
                    Azimov Abdulahad
                </span>
                <span class="itme-pragress">
                    <el-progress
                        class="psum"
                        :text-inside="true"
                        :stroke-width="25"
                        :percentage="user.percentage"
                        :format="format"
                    ></el-progress>
                    <el-progress
                        class="top_R"
                        :text-inside="true"
                        :stroke-width="23"
                        :percentage="40"
                        status="success"
                    ></el-progress>
                </span>
                <ul class="rezultat">
                    <li>Actual: <span>125 000 000 sum</span></li>
                    <li>Expected: <span>125 000 000 sum</span></li>
                </ul>
            </div>
            <!-- <div class="itme-pragress-big mengra">
                <span class="t-color itme-pragress-user-name">
                    Akromjon Numonov
                </span>
                <span class="itme-pragress">
                    <el-progress
                        class="psum"
                        :text-inside="true"
                        :stroke-width="25"
                        :percentage="70"
                        :format="format"
                    ></el-progress>
                    <el-progress
                        class="top_R"
                        :text-inside="true"
                        :stroke-width="23"
                        :percentage="30"
                        status="success"
                    ></el-progress>
                </span>
                <ul class="rezultat">
                    <li>Actual: <span>125 000 000 sum</span></li>
                    <li>Expected: <span>125 000 000 sum</span></li>
                </ul>
            </div>
            <div class="itme-pragress-big mengra">
                <span class="t-color itme-pragress-user-name">
                    Munisa Mayakupova
                </span>
                <span class="itme-pragress">
                    <el-progress
                        class="psum"
                        :text-inside="true"
                        :stroke-width="25"
                        :percentage="50"
                        :format="format"
                    ></el-progress>
                    <el-progress
                        class="top_R"
                        :text-inside="true"
                        :stroke-width="23"
                        :percentage="20"
                        status="success"
                    ></el-progress>
                    <ul class="rezultat">
                        <li>Actual: <span>125 000 000 sum</span></li>
                        <li>Expected: <span>125 000 000 sum</span></li>
                    </ul>
                </span>
            </div>
            <div class="itme-pragress-big mengra">
                <span class="t-color itme-pragress-user-name">
                    Mirshod Mustafayev
                </span>
                <span class="itme-pragress">
                    <el-progress
                        class="psum"
                        :text-inside="true"
                        :stroke-width="25"
                        :percentage="40"
                        :format="format"
                    ></el-progress>
                    <el-progress
                        class="top_R"
                        :text-inside="true"
                        :stroke-width="23"
                        :percentage="15"
                        status="success"
                    ></el-progress>
                    <ul class="rezultat">
                        <li>Actual: <span>125 000 000 sum</span></li>
                        <li>Expected: <span>125 000 000 sum</span></li>
                    </ul>
                </span>
            </div>
            <div class="itme-pragress-big mengra">
                <span class="t-color itme-pragress-user-name">
                    Aziz Pernekulov
                </span>
                <span class="itme-pragress">
                    <el-progress
                        class="psum"
                        :text-inside="true"
                        :stroke-width="25"
                        :percentage="30"
                        :format="format"
                    ></el-progress>
                    <el-progress
                        class="top_R"
                        :text-inside="true"
                        :stroke-width="23"
                        :percentage="10"
                        status="success"
                    ></el-progress>
                    <ul class="rezultat">
                        <li>Actual: <span>125 000 000 sum</span></li>
                        <li>Expected: <span>125 000 000 sum</span></li>
                    </ul>
                </span>
            </div>
            <div class="itme-pragress-big mengra">
                <span class="t-color itme-pragress-user-name">
                    Rustamxon Rustamxonov
                </span>
                <span class="itme-pragress">
                    <el-progress
                        class="psum"
                        :text-inside="true"
                        :stroke-width="25"
                        :percentage="20"
                        :format="format"
                    ></el-progress>
                    <el-progress
                        class="top_R"
                        :text-inside="true"
                        :stroke-width="23"
                        :percentage="8"
                        status="success"
                    ></el-progress>
                    <ul class="rezultat">
                        <li>Actual: <span>125 000 000 sum</span></li>
                        <li>Expected: <span>125 000 000 sum</span></li>
                    </ul>
                </span>
            </div>
            <div class="itme-pragress-big mengra">
                <span class="t-color itme-pragress-user-name">
                    Mirfotix Sobirov
                </span>
                <span class="itme-pragress">
                    <el-progress
                        class="psum"
                        :text-inside="true"
                        :stroke-width="25"
                        :percentage="20"
                        :format="format"
                    ></el-progress>
                    <el-progress
                        class="top_R"
                        :text-inside="true"
                        :stroke-width="23"
                        :percentage="8"
                        status="success"
                    ></el-progress>
                    <ul class="rezultat">
                        <li>Actual: <span>125 000 000 sum</span></li>
                        <li>Expected: <span>125 000 000 sum</span></li>
                    </ul>
                </span>
            </div>
            <div class="itme-pragress-big mengra">
                <span class="t-color itme-pragress-user-name">
                    Nurmuhammad Muxtorov
                </span>
                <span class="itme-pragress">
                    <el-progress
                        class="psum"
                        :text-inside="true"
                        :stroke-width="25"
                        :percentage="20"
                        :format="format"
                    ></el-progress>
                    <el-progress
                        class="top_R"
                        :text-inside="true"
                        :stroke-width="23"
                        :percentage="8"
                        status="success"
                    ></el-progress>
                    <ul class="rezultat">
                        <li>Actual: <span>125 000 000 sum</span></li>
                        <li>Expected: <span>125 000 000 sum</span></li>
                    </ul>
                </span>
            </div>
            <div class="itme-pragress-big mengra">
                <span class="t-color itme-pragress-user-name">
                    Ulugbek Dusmukhamedov
                </span>
                <span class="itme-pragress">
                    <el-progress
                        class="psum"
                        :text-inside="true"
                        :stroke-width="25"
                        :percentage="20"
                        :format="format"
                    ></el-progress>
                    <el-progress
                        class="top_R"
                        :text-inside="true"
                        :stroke-width="23"
                        :percentage="8"
                        status="success"
                    ></el-progress>
                    <ul class="rezultat">
                        <li>Actual: <span>125 000 000 sum</span></li>
                        <li>Expected: <span>125 000 000 sum</span></li>
                    </ul>
                </span>
            </div>
            <div class="itme-pragress-big mengra">
                <span class="t-color itme-pragress-user-name">
                    Dilmurod Yo'ldoshev
                </span>
                <span class="itme-pragress">
                    <el-progress
                        class="psum"
                        :text-inside="true"
                        :stroke-width="25"
                        :percentage="20"
                        :format="format"
                    ></el-progress>
                    <el-progress
                        class="top_R"
                        :text-inside="true"
                        :stroke-width="23"
                        :percentage="8"
                        status="success"
                    ></el-progress>
                    <ul class="rezultat">
                        <li>Actual: <span>125 000 000 sum</span></li>
                        <li>Expected: <span>125 000 000 sum</span></li>
                    </ul>
                </span>
            </div> -->
        </div>
    </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";

export default {
    components: {},
    data() {
        return {
            radio1: "Неделя",
            expectedSum: "100 000 000",
            arr: [
                {
                    id:1,
                    percentage:100,
                    money_amount: 1111
                },
                {
                    id:2,
                    percentage:100,
                    money_amount: 1111
                }
            ]
        };
    },
    computed: {
        ...mapGetters({
            usersDeals: "users/usersDeals"
        })
    },
    methods: {
        format(percentage) {
            // return `${percentage}%1000`;
            return this.expectedSum;
        },
    },
};
</script>

    