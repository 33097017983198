import { i18n } from '@/utils/i18n';

export const columns = {
    name : {
        show: true,
        title: i18n.t('message.name'),
        sortable: true,
        column: 'name'
    },
    quantity : {
        show: true,
        title: i18n.t('message.quantity'),
        sortable: true,
        column: 'quantity'
    },
    measurement_id : {
        show: true,
        title: i18n.t('message.measurement_id'),
        sortable: true,
        column: 'measurement_id'
    },
    price : {
        show: true,
        title: i18n.t('message.price'),
        sortable: true,
        column: 'price'
    },
    currency_id : {
        show: true,
        title: i18n.t('message.currency_id'),
        sortable: true,
        column: 'currency_id'
    },
    profit : {
        show: true,
        title: i18n.t('message.profit'),
        sortable: true,
        column: 'profit'
    },
    discount : {
        show: true,
        title: i18n.t('message.discount'),
        sortable: true,
        column: 'discount'
    },
    nds : {
        show: true,
        title: i18n.t('message.nds'),
        sortable: true,
        column: 'nds'
    },
    color : {
        show: true,
        title: i18n.t('message.color'),
        sortable: true,
        column: 'color'
    },
    package : {
        show: true,
        title: i18n.t('message.package'),
        sortable: true,
        column: 'package'
    },
    total : {
        show: true,
        title: i18n.t('message.total'),
        sortable: true,
        column: 'total'
    },
    delete : {
        show: true,
        title: i18n.t('message.delete'),
        sortable: true,
        column: 'delete'
    },
};
