import { i18n } from '@/utils/i18n';

export const columns = {
    id : {
        show: true,
        title: i18n.t('message.n'),
        sortable: true,
        column: 'id'
    },
    name : {
        show: true,
        title: i18n.t('message.name'),
        sortable: true,
        column: 'name'
    },
    code : {
        show: true,
        title: i18n.t('message.code'),
        sortable: true,
        column: 'code'
    },

    buy_price : {
        show: true,
        title: i18n.t('message.buy_price'),
        sortable: true,
        column: 'buy_price'
    },
    buy_price_currency_id : {
        show: true,
        title: i18n.t('message.buy_price_currency_id'),
        sortable: true,
        column: 'buy_price_currency_id'
    },
    selling_price : {
        show: true,
        title: i18n.t('message.selling_price'),
        sortable: true,
        column: 'selling_price'
    },
    selling_price_currency_id : {
        show: true,
        title: i18n.t('message.selling_price_currency_id'),
        sortable: true,
        column: 'selling_price_currency_id'
    },
    measurement_id : {
        show: true,
        title: i18n.t('message.measurement_id'),
        sortable: true,
        column: 'measurement_id'
    },
    product_model_id : {
        show: true,
        title: i18n.t('message.productModel'),
        sortable: true,
        column: 'product_model_id'
    },
    color_id : {
        show: true,
        title: i18n.t('message.color'),
        sortable: true,
        column: 'color_id'
    },
    is_default : {
        show: true,
        title: i18n.t('message.is_default'),
        sortable: true,
        column: 'is_default'
    },
    categories : {
        show: false,
        title: i18n.t('message.categories'),
        sortable: true,
        column: 'categories'
    },
    created_at : {
        show: true,
        title: i18n.t('message.created_at'),
        sortable: true,
        column: 'created_at'
    },
    updated_at : {
        show: true,
        title: i18n.t('message.updated_at'),
        sortable: true,
        column: 'updated_at'
    },   
    settings : {
        show: true,
        title: i18n.t('message.settings'),
        sortable: false,
        column: 'settings'
    },

};
