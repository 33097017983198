const mode = window.localStorage.getItem('mode')
const oldVersion = window.localStorage.getItem('oldVersion')
const leftVersion = window.localStorage.getItem('leftVersion')
const background = window.localStorage.getItem('background')
const radio = window.localStorage.getItem('radio')

export const state = {
    mode: mode ? JSON.parse(mode) : true,
    oldVersion: oldVersion ? JSON.parse(oldVersion) : false,
    leftVersion: leftVersion ? JSON.parse(leftVersion) : true,
    links: [
      {
        id: 3,
        name: 'Dashboard',
        link: 'Home'
      },
      {
        id: 0,
        name: 'Сделки',
        link: 'deals'
      },
      {
        id: 2,
        name: 'Задачи',
        link: 'tasks'
      },
    ],
    radio: radio ? JSON.parse(radio) : 1,
    is_expanded: false,
    background: background ? JSON.parse(background) : {
        id: 55,
        check: true,
        url: 'https://firebasestorage.googleapis.com/v0/b/vue-store-1.appspot.com/o/back%2F12121.png?alt=media&token=9c39e9f9-23fc-474b-aa88-2997289a69f7',
        name: 'White',
        mode: true
    },

    lock: {
        password: 0, status: false
    },
    dateToday: '',
    getLockTimer: {
        isIdle: true,
        isTime: 0,
        disable: true
    }
}
