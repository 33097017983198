<template>
    <div>
        <div class="top-title">
            <h3>{{ $t("message.deal_by_phone_number") }}</h3>
            <div class="add-task" style="margin: 0px 0px 10px;" @click="createDeal()"><i class="el-icon-circle-plus-outline"></i></div>
        </div>
        <div class="modal-tasks-itle">
            <div
                class="deal-task-items p-3 rounded mb-3"
                v-for="deal in phoneNumberDeals"
                :key="deal.id"
                @dblclick="permissions.some(per => per.slug == 'deals.update') ? addModalEdit(deal, false) : ''"
            >
                <div class="d-flex f-between mb-2">
                    <span class="nomer-deal-task">
                        <span class="pr-2">№{{ deal.id }}</span>
                    </span>
                    <div class="name-tesk">{{ deal.name }}</div>
                </div>
                <div class="name-compami-deal-task mb-2"></div>
                <div class="type-task-big mb-2">
                    <div class="type-task">{{ $t("message.company_y") }}:</div>
                    <div class="type-task-name">{{ deal.company ? deal.company.name : '' }}</div>
                </div>
                <div class="type-task-big mb-2">
                    <div class="type-task">{{ $t("message.deal_type") }}:</div>
                    <div class="type-task-name">{{ deal.deal_type ? deal.deal_type.name : '' }}</div>
                </div>
                <div class="type-task-big mb-2">
                    <div class="type-task">{{ $t("message.country") }}</div>
                    <div class="type-task-name">{{ deal.country ? deal.country.name : '' }}</div>
                </div>
                <div class="user-summa-deal-task">
                    <span><a href="#" class="link-color"> {{ deal.user ? deal.user.name : '' }}</a></span
                    ><span>{{ deal.money_amount | formatNumber(2) }}
                           {{ deal.currency ? deal.currency.code : ''}}
                    </span>
                </div>
                <div class="bottom-data-task">
                    <span class="data-deal-task-end">
                        <i class="el-icon-time"></i> {{ formatDate(deal.begin_date) }}
                    </span>
                    <span class="data-deal-task-start coloa3">
                        <i class="el-icon-time"></i> {{ formatDate(deal.end_date) }}
                    </span>
                    <span class="data-deal-task-start" :class="dayDiff(deal) >= 0 ? 'coloa2' : 'coloa3'">
                        <i class="el-icon-date"></i> {{dayDiff(deal)}}
                    </span>
                    <span
                        class="data-deal-task-start color_6"
                        style="float: right"
                    >
                        <i class="el-icon-edit"></i> {{Math.round(deal.data_percentage)}} %
                    </span>
                </div>
            </div>
        </div>
            
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import AppModal from "@/views/deals/components/add-modal";

export default {
    components: {
        AppModal
    },
    data() {
        return {
            selectedDeal: null,
            board_id: null,
            dealCreate: false,
            drawerDealCreate: false,
        };
    },
    created() {
        
    },
    computed: {
        ...mapGetters({
            rules: "phoneContacts/rules",
            model: "phoneContacts/model",
            columns: "phoneContacts/columns",
            authUser: "auth/user",
            getInventoryUsers: "users/inventory",
            getUserSearch: "users/search",
            contactTypes: 'phoneContacts/contactTypes',
            phoneNumberDeals: "deals/phone_number_deals",
            permissions: "auth/permissions",

        }),
    },
    methods: {
        ...mapActions({
            save: "phoneContacts/store",
            updateInventory: "phoneContacts/inventory",
            updateContacts: "phoneContacts/index",
            showInventoryUsers: "users/inventory",
            search: "users/search",
        }),
        afterOpen(){
            
        },
        createDeal(){
            this.$emit('createDealFromContact');
        },
        addModalEdit(deal, dealCreate) {
            this.$emit('addModalEdit', {deal, dealCreate})
        },
        dayDiff(deal) {
            let finish_date = new Date(deal.finish_date);
            let current_date = new Date();
            let end_date = new Date(deal.end_date);
            if (!isNaN(current_date) && !isNaN(end_date)) {
                if(!isNaN(finish_date) && finish_date.getFullYear() !== 1970){
                let timeDifference = Math.round((end_date.getTime() - finish_date.setHours(0,0,0))/(1000 * 3600 * 24));    
                return timeDifference;
                }else{
                let timeDifference = Math.round((end_date.getTime() - current_date.setHours(0,0,0))/(1000 * 3600 * 24));    
                return timeDifference;
                }
            } else {
                console.log("Enter valid date.");
            }
        },
        
    },
};
</script>

<style>

.coloa3 {
   background: #ff3f3f;
}

</style>
