<template>
  <div>
    <el-select
      :value="selected"
      @input="dispatch"
      :placeholder="placeholder || $t('message.filial')"
      filterable
      clearable
      :size="size"
      class="w-100"
    >
      <el-option
        v-for="(filial, index) in filials"
        :key="'filials-' + index"
        :label="filial.name"
        :value="filial.id"
      ></el-option>
    </el-select>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  props: {
    size: {
      default: "small",
    },
    placeholder: {
      default: null,
    },
    id: {
      default: null,
    },
    board_id: {
      default: null,
    },
  },
  watch: {
    board_id: {
      handler: function () {
        if (this.filials.length === 1) {
          this.selected = this.filials[0].id;
          this.$emit("input", this.selected);
        }
      },
      immediate: true,
    },
    id: {
      handler: function () {
        this.selected = this.id;
      },
      immediate: true,
    },
  },
  data() {
    return {
      selected: null,
    };
  },
  mounted() {
    if (this.filials && this.filials.length === 0) this.updateInventory();
  },
  computed: {
    ...mapGetters({
      filials: "filials/userFilials",
    }),
  },
  methods: {
    ...mapActions({
      updateInventory: "filials/userFilials",
    }),
    dispatch(e) {
      this.$emit("input", e);
      this.selected = e;
    },
  },
};
</script>
