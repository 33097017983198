<template>
    <div class="call-modal-full">
        <div class="top-title">
            <h3>{{ $t("message.new_contact") }}</h3>
        </div>
        <el-form ref="form" :model="form" :rules="rules" style="width: 100%" label-position="top">
            <el-form-item prop="name" :label="$t('message.name')"  style="width: 100%">
                <el-input 
                    :placeholder="$t('message.name')"
                    v-model="form.name"
                    size="medium"
                >
                </el-input>
            </el-form-item>
            <el-form-item
                prop="company_id"
                :label="columns.company_id.title"
                style="width: 100%"
                >
                <select-company
                    :size="'medium'"
                    :placeholder="$t('message.company')"
                    :id="form.company_id"
                    v-model="form.company_id"
                >
                </select-company>
            </el-form-item> 
            <crm-phone-input
                :inputValue="form.phone_number" 
                v-model="form.phone_number"
                @getPhoneStatus="getPhoneStatus"
                :form_prop="'phone_number'"
                :country_code="form.phone_country_code"
            ></crm-phone-input> 
            <el-form-item
                prop="email"
                :label="columns.email.title"
                style="width: 100%"
                >
                <el-input
                    :placeholder="columns.email.title"
                    v-model="form.email"
                    size="medium"
                ></el-input>
            </el-form-item>  
            <el-form-item
                prop="contact_type_id"
                :label="columns.contact_type_id.title"
                style="width: 100%"
                >
                <select-contact-type
                    :size="'medium'"
                    :placeholder="columns.contact_type_id.title"
                    :id="form.contact_type_id"
                    v-model="form.contact_type_id"
                ></select-contact-type>
            </el-form-item>   

            <el-form-item class="mt-5 text-center">
                <el-button type="primary" @click="submit(true)"> {{ $t('message.save') }} </el-button>
                <el-button @click="close()">{{ $t('message.close') }}</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
import CountryPhoneInput from "@/components/CountryPhoneInput";
import { mapGetters, mapActions } from "vuex";
import selectCompany from "@/components/inventory/select-company";
import selectContactType from "@/components/inventory/select-contact-type";

export default {
    components: {
        CountryPhoneInput,
        selectCompany,
        selectContactType,
    },
    data() {
        return {
            form: {},
        };
    },
    created() {
        if (this.model != null) {           
            this.form = JSON.parse(JSON.stringify(this.model));         
        }
    },
    computed: {
        ...mapGetters({
            rules: "phoneContacts/rules",
            model: "phoneContacts/model",
            columns: "phoneContacts/columns",
            authUser: "auth/user",
            getInventoryUsers: "users/inventory",
            getUserSearch: "users/search",
            contactTypes: 'phoneContacts/contactTypes'
        }),
    },
    methods: {
        ...mapActions({
            save: "phoneContacts/store",
            updateInventory: "phoneContacts/inventory",
            updateContacts: "phoneContacts/index",
            showInventoryUsers: "users/inventory",
            search: "users/search",
        }),
        afterOpen(){
            this.form.user_id = this.authUser.id;
        },
        getPhoneStatus({is_valid, countryCode}){
            this.is_phone_valid = is_valid;
            this.form.phone_country_code = countryCode;
        },
        submit(close = true) {
            this.$refs["form"].validate((valid) => {
                if (valid) {
                    if(this.is_phone_valid){
                        this.loadingButton = true;
                        this.save(this.form)
                            .then((res) => {
                                this.loadingButton = false;
                                this.updateInventory();
                                this.updateContacts();
                                this.$alert(res);
                                if(close){
                                    this.close();
                                }
                            })
                            .catch((err) => {
                                this.loadingButton = false;
                                this.$alert(err);
                            });
                    }else{
                        this.$notify({
                            title: this.$t('message.validation'),
                            type: "error",
                            offset: 130,
                            message: this.$t('message.phone_number_is_not_valid')
                        });
                    }
                }
            });
        },
        close(){
            this.$emit('closeContactModal', true)
        },
        getPhone(num) {
            this.form.phone = num;
        },
    },
};
</script>

<style lang="scss">
.call-modal-full {
    .el-form-item__label {
        width: 100%;
        text-align: left;
    }
    .el-form-item {
        margin-bottom: 0px;
    }
}
.el-select-dropdown.el-popper{
    z-index: 9996 !important;
}
</style>
