export const model = {
    id: null,
    user_id:  null,
    filial_id:  null,
    index: 0,
    board_id: null,
    name: '',
    money_amount: 0, 
    profit: 0, 
    profit: 0, 
    status: 1, 
    currency_id:  null,
    deal_stage_id:  null,
    reception_address: '',
    deal_payments: [],
    shipment_term_id:  null,
    phone: '',
    phone_country_code: 'UZ',
    email: '',
    begin_date: new Date(), 
    end_date: new Date(),
    company_id:  null,
    client_id:  null,
    deal_type_id:  null,
    source_id:  null,
    reason_id:  null,
    comment: '',
    finish_date: '',
    created_at: '',
    updated_at: '',         
    spectators:  [],   
    files:  [],   
};