export const getters = {
    list: state => state.list,
    archivedDeals: state => state.archivedDeals,
    deletedDeals: state => state.deletedDeals,
    dealTasks: state => state.dealTasks,
    inventory: state => state.inventory,
    dealStages: state => state.dealStages,
    deletedDealsForStage: state => state.deletedDealsForStage,
    model: state => state.model,
    rules: state => state.rules,
    columns: state => state.columns,
    filter: state => state.filter,
    pagination: state => state.pagination,
    sort: state => state.sort ,
    newForm: state => state.form,
    dealsForCalendar: state => state.dealsForCalendar,
    phone_number_deals: state => state.phone_number_deals,
    
    form: state => {
        return {
            id: state.model.id,
            user_id:  state.model.user ? state.model.user.id : '',
            user:  state.model.user,
            filial_id:  state.model.filial ? state.model.filial.id : '',
            index: state.model.index,
            board_id: state.model.board_id,
            name: state.model.name,
            money_amount: state.model.money_amount,
            profit: state.model.profit,
            status: state.model.status,
            currency_id:  state.model.currency ? state.model.currency.id : '',
            deal_stage_id:  state.model.deal_stage ? state.model.deal_stage.id : '',
            reception_address:  state.model.reception_address,
            deal_payments: state.model.deal_payments,
            shipment_term_id:  state.model.shipmentTerm ? state.model.shipmentTerm.id : '',
            begin_date: state.model.begin_date, 
            end_date: state.model.end_date,
            company_id:  state.model.company ? state.model.company.id : '',
            client_id:  state.model.client ? state.model.client.id : '',
            deal_type_id:  state.model.deal_type ? state.model.deal_type.id : '',
            source_id:  state.model.source ? state.model.source.id : '',
            reason_id:  state.model.reason ? state.model.reason.id : '',
            comment: state.model.comment, 
            finish_date: state.model.finish_date,
            phone: state.model.phone, 
            phone_country_code: state.model.phone_country_code, 
            email: state.model.email, 
            spectators: state.model.spectators,
            files: state.model.files,
        }
    }
}