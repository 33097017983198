export const model = {
  id : '',  
  company: null, 
  full_name: '', 
  date_of_birth: '', 
  passport_serial_number: '', 
  type: null, 
  phone: '', 
  phone_country_code: 'UZ',
  email: '', 
  passport_validity: '', 
  actual_address: '', 
  inn: '', 
  gender: null, 
  gender_id: null, 
  country_id: null, 
  city_id: null, 
  clientTypes: [], 
  clientType_ids: [], 
  created_at: '',
  updated_at: ''  
  };
  