<template>
    <div>
        <a
            href="#"
            rel="nofollow"
            class="btn-call"
            @click="iconPhone"
            :class="{ noneIcon: noneIcon }"
            v-if="phoneStatus"
        >
            <div
                class="btn-call__ico"
                :class="
                    phoneStatus === 'registered'
                        ? 'btn-call__ico_color_white'
                        : 'btn-call__ico_color_red'
                "
            >
                <i class="el-icon-phone"></i>
            </div>
        </a>
        <div class="sip__box" v-show="activeCatPhone">
            <i
                class="el-icon-circle-close closeIconPhone-close"
                @click="closeCatPhone"
            >
            </i>
            <div class="sip__header">
                <span>{{ $t("message.department_list") }}</span>
            </div>
            <el-row :gutter="5">
                <el-col :span="4">
                    <div class="sip__item">
                        <div class="sip__cate">
                            <span>S.Raximov</span>
                        </div>
                        <ul class="sip__ul">
                            <li
                                v-for="sip_account in sipAccountList.filter(
                                    (item) => parseInt(item.username) < 200
                                )"
                                :key="sip_account.username"
                                class="sip__li"
                                @click="callToSipAccount(sip_account)"
                            >
                                <div class="sip__into">
                                    <div class="sip__status">
                                        <span
                                            class="sip__indicator"
                                            :style="
                                                'background-color:' +
                                                setSipAccountsState(
                                                    sip_account.username
                                                )
                                            "
                                        ></span>
                                        <span class="sip__infoindicator"></span>
                                    </div>
                                    <div class="sip__img">
                                        <img
                                            src="https://firebasestorage.googleapis.com/v0/b/vue-store-1.appspot.com/o/user.png?alt=media&token=04ba7c78-0b75-431d-aa5b-3456a8ada2a2"
                                            alt=""
                                        />
                                    </div>
                                    <div class="sip__name">
                                        <span>{{
                                            sip_account.displayname
                                        }}</span>
                                    </div>
                                </div>
                                <div class="sip__phone">
                                    <a>{{ sip_account.username }}</a>
                                </div>
                            </li>
                        </ul>
                    </div>
                </el-col>
                <el-col :span="4">
                    <div class="sip__item">
                        <div class="sip__cate">
                            <span>Sergili Tikuv</span>
                        </div>
                        <ul class="sip__ul">
                            <li
                                v-for="sip_account in sipAccountList.filter(
                                    (item) =>
                                        200 <= parseInt(item.username) &&
                                        parseInt(item.username) < 300
                                )"
                                :key="sip_account.username"
                                class="sip__li"
                                @click="callToSipAccount(sip_account)"
                            >
                                <div class="sip__into">
                                    <div class="sip__status">
                                        <span
                                            class="sip__indicator"
                                            :style="
                                                'background-color:' +
                                                setSipAccountsState(
                                                    sip_account.username
                                                )
                                            "
                                        ></span>
                                        <span class="sip__infoindicator"></span>
                                    </div>
                                    <div class="sip__img">
                                        <img
                                            src="https://firebasestorage.googleapis.com/v0/b/vue-store-1.appspot.com/o/user.png?alt=media&token=04ba7c78-0b75-431d-aa5b-3456a8ada2a2"
                                            alt=""
                                        />
                                    </div>
                                    <div class="sip__name">
                                        <span>{{
                                            sip_account.displayname
                                        }}</span>
                                    </div>
                                </div>
                                <div class="sip__phone">
                                    <a>{{ sip_account.username }}</a>
                                </div>
                            </li>
                        </ul>
                    </div>
                </el-col>
                <el-col :span="4">
                    <div class="sip__item">
                        <div class="sip__cate">
                            <span>Sergili To'quv</span>
                        </div>
                        <ul class="sip__ul">
                            <li
                                v-for="sip_account in sipAccountList.filter(
                                    (item) =>
                                        300 <= parseInt(item.username) &&
                                        parseInt(item.username) < 400
                                )"
                                :key="sip_account.username"
                                class="sip__li"
                                @click="callToSipAccount(sip_account)"
                            >
                                <div class="sip__into">
                                    <div class="sip__status">
                                        <span
                                            class="sip__indicator"
                                            :style="
                                                'background-color:' +
                                                setSipAccountsState(
                                                    sip_account.username
                                                )
                                            "
                                        ></span>
                                        <span class="sip__infoindicator"></span>
                                    </div>
                                    <div class="sip__img">
                                        <img
                                            src="https://firebasestorage.googleapis.com/v0/b/vue-store-1.appspot.com/o/user.png?alt=media&token=04ba7c78-0b75-431d-aa5b-3456a8ada2a2"
                                            alt=""
                                        />
                                    </div>
                                    <div class="sip__name">
                                        <span>{{
                                            sip_account.displayname
                                        }}</span>
                                    </div>
                                </div>
                                <div class="sip__phone">
                                    <a>{{ sip_account.username }}</a>
                                </div>
                            </li>
                        </ul>
                    </div>
                </el-col>
                <el-col :span="4">
                    <div class="sip__item">
                        <div class="sip__cate">
                            <span>Namangan Iplik</span>
                        </div>
                        <ul class="sip__ul">
                            <li
                                v-for="sip_account in sipAccountList.filter(
                                    (item) =>
                                        400 <= parseInt(item.username) &&
                                        parseInt(item.username) < 500
                                )"
                                :key="sip_account.username"
                                class="sip__li"
                                @click="callToSipAccount(sip_account)"
                            >
                                <div class="sip__into">
                                    <div class="sip__status">
                                        <span
                                            class="sip__indicator"
                                            :style="
                                                'background-color:' +
                                                setSipAccountsState(
                                                    sip_account.username
                                                )
                                            "
                                        ></span>
                                        <span class="sip__infoindicator"></span>
                                    </div>
                                    <div class="sip__img">
                                        <img
                                            src="https://firebasestorage.googleapis.com/v0/b/vue-store-1.appspot.com/o/user.png?alt=media&token=04ba7c78-0b75-431d-aa5b-3456a8ada2a2"
                                            alt=""
                                        />
                                    </div>
                                    <div class="sip__name">
                                        <span>{{
                                            sip_account.displayname
                                        }}</span>
                                    </div>
                                </div>
                                <div class="sip__phone">
                                    <a>{{ sip_account.username }}</a>
                                </div>
                            </li>
                        </ul>
                    </div>
                </el-col>
                <el-col :span="4">
                    <div class="sip__item">
                        <div class="sip__cate">
                            <span>{{ $t("message.department") }} X</span>
                        </div>
                        <ul class="sip__ul">
                            <li
                                v-for="sip_account in sipAccountList.filter(
                                    (item) =>
                                        500 <= parseInt(item.username) &&
                                        parseInt(item.username) < 600
                                )"
                                :key="sip_account.username"
                                class="sip__li"
                                @click="callToSipAccount(sip_account)"
                            >
                                <div class="sip__into">
                                    <div class="sip__status">
                                        <span
                                            class="sip__indicator"
                                            :style="
                                                'background-color:' +
                                                setSipAccountsState(
                                                    sip_account.username
                                                )
                                            "
                                        ></span>
                                        <span class="sip__infoindicator"></span>
                                    </div>
                                    <div class="sip__img">
                                        <img
                                            src="https://firebasestorage.googleapis.com/v0/b/vue-store-1.appspot.com/o/user.png?alt=media&token=04ba7c78-0b75-431d-aa5b-3456a8ada2a2"
                                            alt=""
                                        />
                                    </div>
                                    <div class="sip__name">
                                        <span>{{
                                            sip_account.displayname
                                        }}</span>
                                    </div>
                                </div>
                                <div class="sip__phone">
                                    <a>{{ sip_account.username }}</a>
                                </div>
                            </li>
                        </ul>
                    </div>
                </el-col>
            </el-row>
        </div>
        <div class="call-big no-modal2" :class="{ active2: activeIconPhone }">
            <i
                v-show="callSessionsList.length < 1"
                class="el-icon-circle-close closeIconPhone-close"
                @click="closeIconPhone"
            >
            </i>
            <i
                v-show="callSessionsList.length >= 1"
                class="el-icon-back closeIconPhone-close"
                @click="closeIconPhone"
            >
            </i>

            <div class="call-number-kontak">
                <div class="call-number">
                    <div class="dial-pad">
                        <audio id="remoteAudio" ref="remoteAudioRef"></audio>

                        <!-- <audio v-for="(callsess, index) in callSessionsList" :key="'callsess_'+index" id="remoteAudio" :ref="'remoteAudioRef_'+index"></audio> -->

                        <audio id="incoming-call-beep" loop>
                            <source
                                src="/audio/telephone-ring-04.mp3"
                                type="audio/mpeg"
                            />
                        </audio>
                        <audio id="sound-beep" loop>
                            <source src="/audio/beep.mp3" type="audio/mpeg" />
                        </audio>
                        <div
                            class="statuss"
                            v-show="
                                (addingCall || isTransferCall) &&
                                elapsedTime > 0
                            "
                        >
                            {{
                                elapsedTime > 0
                                    ? formattedElapsedTime
                                    : $t("message.calling") + "..."
                            }}
                        </div>
                        <div
                            class="statuss"
                            v-show="!addingCall && !isTransferCall"
                        >
                            <div
                                v-click-outside="onClickOutside"
                                @click="miniStatus = !miniStatus"
                                class="status__header"
                            >
                                <span
                                    :class="
                                        radio === 'registered'
                                            ? 'active__status'
                                            : 'notactive__status'
                                    "
                                ></span>
                                <span>{{ $t(`message.${radio}`) }}</span>
                            </div>
                            <transition name="status-fade">
                                <div v-show="miniStatus" class="status__box">
                                    <div class="status__title">
                                        <span>{{
                                            $t("message.select_status")
                                        }}</span>
                                    </div>
                                    <ul class="status__ul">
                                        <li
                                            @click.prevent="
                                                setActive('registered')
                                            "
                                            class="call__avialable status__item"
                                        >
                                            <span class="available__icon">
                                                <span
                                                    class="el-icon-check"
                                                ></span>
                                            </span>
                                            <span class="status__text">{{
                                                $t("message.register")
                                            }}</span>
                                            <span
                                                v-show="isActive('registered')"
                                                class="
                                                    status__absolute
                                                    el-icon-check
                                                "
                                            ></span>
                                        </li>
                                        <li
                                            @click.prevent="
                                                setActive('unregistered')
                                            "
                                            class="not__disturb status__item"
                                        >
                                            <span class="disturb__icon">
                                                <span
                                                    class="el-icon-minus"
                                                ></span>
                                            </span>
                                            <span class="status__text">{{
                                                $t("message.unregistered")
                                            }}</span>
                                            <span
                                                v-show="
                                                    isActive('unregistered')
                                                "
                                                class="
                                                    status__absolute
                                                    el-icon-check
                                                "
                                            ></span>
                                        </li>
                                        <!-- <li @click.prevent="setActive('available')" class="call__avialable status__item">
                                            <span class="available__icon">
                                                <span class="el-icon-check"></span>
                                            </span>
                                            <span class="status__text">{{ $t('message.available') }}</span>
                                            <span v-show="isActive('available')" class="status__absolute el-icon-check"></span>
                                        </li>
                                        <li @click.prevent="setActive('NotDisturb')" class="not__disturb status__item">
                                            <span class="disturb__icon">
                                                <span class="el-icon-minus"></span>
                                            </span>
                                            <span class="status__text">{{ $t('message.not_disturb') }}</span>
                                            <span v-show="isActive('NotDisturb')" class="status__absolute el-icon-check"></span>
                                        </li>
                                        <li @click.prevent="setActive('busy')" class="not__disturb status__item">
                                            <span class="disturb__icon"></span>
                                            <span class="status__text">{{ $t('message.busy') }}</span>
                                            <span v-show="isActive('busy')" class="status__absolute el-icon-check"></span>
                                        </li>
                                        <li @click.prevent="setActive('absent')" class="not__disturb status__item">
                                            <span class="disturb__icon">
                                                <span class="el-icon-close"></span>
                                            </span>
                                            <span class="status__text">{{ $t('message.absent') }}</span>
                                            <span v-show="isActive('absent')" class="status__absolute el-icon-check"></span>
                                        </li>
                                        <li @click.prevent="setActive('invisable')" class="call__hidden status__item">
                                            <span class="hidden__icon"></span>    
                                            <span class="status__text">{{ $t('message.invisible') }}</span>
                                            <span v-show="isActive('invisable')" class="status__absolute el-icon-check"></span>
                                        </li> -->
                                    </ul>
                                </div>
                            </transition>
                        </div>
                        <div class="dial-screen" contenteditable="false">
                            {{ phonet }}
                        </div>
                        <div class="call__numbers">
                            <div class="call__line">
                                <div @click="append('1')" class="call__item">
                                    <span>1</span>
                                </div>
                                <div @click="append('2')" class="call__item">
                                    <span>2</span>
                                </div>
                                <div @click="append('3')" class="call__item">
                                    <span>3</span>
                                </div>
                            </div>
                            <div class="call__line">
                                <div @click="append('4')" class="call__item">
                                    <span>4</span>
                                </div>
                                <div @click="append('5')" class="call__item">
                                    <span>5</span>
                                </div>
                                <div @click="append('6')" class="call__item">
                                    <span>6</span>
                                </div>
                            </div>
                            <div class="call__line">
                                <div @click="append('7')" class="call__item">
                                    <span>7</span>
                                </div>
                                <div @click="append('8')" class="call__item">
                                    <span>8</span>
                                </div>
                                <div @click="append('9')" class="call__item">
                                    <span>9</span>
                                </div>
                            </div>
                            <div class="call__line">
                                <div @click="append('*')" class="call__item">
                                    <span>*</span>
                                </div>
                                <div @click="append('0')" class="call__item">
                                    <span>0</span>
                                </div>
                                <div @click="append('#')" class="call__item">
                                    <span>#</span>
                                </div>
                            </div>
                            <div class="call__line">
                                <div @click="append('+')" class="call__empty">
                                    <span>+</span>
                                </div>
                                <div @click="callingClick" class="call__call">
                                    <span class="el-icon-phone"></span>
                                </div>
                                <div @click="del" class="call__clear">
                                    <span>
                                        <img
                                            src="https://firebasestorage.googleapis.com/v0/b/vue-store-1.appspot.com/o/clear.png?alt=media&token=47fc68c8-a7b3-4318-a1db-231de3f18454"
                                            alt=""
                                        />
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="call-kontak">
                    <div>
                        <button class="sip__otdel" @click="openCatPhone()">
                            {{ $t("message.department_list") }}
                        </button>
                        <el-select
                            v-show="callSessionsList.length < 1"
                            size="mini"
                            class="ml-3"
                            v-model="selectedPhoneNumberForOutboundCall"
                            placeholder="Select"
                        >
                            <el-option
                                v-for="phone_for_outbound_call in authUser.sip_account
                                    ? authUser.sip_account.phones
                                    : []"
                                :key="phone_for_outbound_call.id"
                                :label="phone_for_outbound_call.phone_number"
                                :value="phone_for_outbound_call.prefix"
                            >
                            </el-option>
                        </el-select>
                    </div>

                    <el-tabs v-model="activeName">
                        <el-tab-pane
                            :label="$t('message.call_history')"
                            name="callC"
                        >
                            <el-input
                                :placeholder="$t('message.search')"
                                prefix-icon="el-icon-search"
                                v-model="input2"
                                class="input-call"
                            >
                            </el-input>
                            <div
                                style="overflow-y: scroll; height: 320px"
                                v-if="authUser.sip_account"
                            >
                                <div
                                    class="call-users"
                                    v-for="(callH, index) in callHistoryList"
                                    :key="'callH_' + index"
                                >
                                    <div class="call-users-info">
                                        <img
                                            src="https://firebasestorage.googleapis.com/v0/b/vue-store-1.appspot.com/o/user.png?alt=media&token=04ba7c78-0b75-431d-aa5b-3456a8ada2a2"
                                            alt=""
                                        />
                                        <div class="call-users-info-text2">
                                            <div class="info-c-u-off">
                                                <span class="name-limit">
                                                    {{
                                                        findContactAttribute(
                                                            callH,
                                                            "name"
                                                        )
                                                    }}
                                                    Tell:{{
                                                        findContactAttribute(
                                                            callH,
                                                            "number"
                                                        )
                                                    }}
                                                </span>
                                                <span class="sek-off font_10">
                                                    {{
                                                        findContactAttribute(
                                                            callH,
                                                            "company"
                                                        )
                                                    }}
                                                </span>
                                                <span
                                                    v-if="
                                                        authUser.sip_account
                                                            .sip_number ===
                                                            callH.src &&
                                                        callH.disposition ===
                                                            'ANSWERED'
                                                    "
                                                    class="sek-off coo1"
                                                >
                                                    {{ $t("message.outgoing") }}
                                                </span>
                                                <span
                                                    v-if="
                                                        authUser.sip_account
                                                            .sip_number ===
                                                            callH.dst &&
                                                        callH.disposition !==
                                                            'NO ANSWER'
                                                    "
                                                    class="sek-off coo3"
                                                >
                                                    {{ $t("message.incoming") }}
                                                </span>
                                                <span
                                                    v-if="
                                                        authUser.sip_account
                                                            .sip_number ===
                                                            callH.dst &&
                                                        callH.disposition ===
                                                            'NO ANSWER'
                                                    "
                                                    class="sek-off coo2"
                                                >
                                                    {{ $t("message.missed") }}
                                                </span>
                                            </div>
                                            <div class="info-c-u-off">
                                                <span class="sek-off">
                                                    {{
                                                        Math.floor(
                                                            callH.billsec / 60
                                                        )
                                                    }}:{{ callH.billsec % 60 }}
                                                </span>
                                                <span class="sek-off">
                                                    {{ callH.calldate }}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </el-tab-pane>
                        <el-tab-pane
                            :label="$t('message.phoneContacts')"
                            name="callTab"
                        >
                            <el-input
                                :placeholder="$t('message.search')"
                                prefix-icon="el-icon-search"
                                v-model="contactSearchInput"
                                class="input-call"
                                @input="findContactInputSearch"
                                @blur="searchInputBlur"
                                @focus="searchInputFocus"
                            >
                            </el-input>
                            <div style="overflow-y: scroll; height: 320px">
                                <div
                                    class="call-users"
                                    v-for="contact in phoneContacts"
                                    :key="contact.id"
                                    @click="getPhoneNumber(contact)"
                                    @dblclick="callingClick"
                                >
                                    <div class="call-users-info">
                                        <img
                                            src="https://firebasestorage.googleapis.com/v0/b/vue-store-1.appspot.com/o/user.png?alt=media&token=04ba7c78-0b75-431d-aa5b-3456a8ada2a2"
                                            alt=""
                                        />
                                        <div class="call-users-info-text">
                                            <span class="name">
                                                {{
                                                    contact.name_title
                                                        ? contact.name_title
                                                              .name
                                                        : ""
                                                }}
                                                {{ contact.name }}
                                                {{ contact.surname }}
                                            </span>
                                            <span class="sek-off font_10 fbig">
                                                {{
                                                    contact.company
                                                        ? contact.company.name.toUpperCase()
                                                        : ""
                                                }}
                                            </span>
                                            <span class="tel">
                                                {{ contact.phone_number }}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </el-tab-pane>
                        <el-tab-pane
                            :label="$t('message.sipAccounts')"
                            name="callTab2"
                        >
                            <el-input
                                :placeholder="$t('message.search')"
                                prefix-icon="el-icon-search"
                                v-model="input2"
                                class="input-call"
                                @blur="searchInputBlur"
                                @focus="searchInputFocus"
                            >
                            </el-input>
                            <div style="overflow-y: scroll; height: 320px">
                                <div
                                    class="call-users"
                                    v-for="contact in sipAccountList.filter(
                                        (num) => num.username.length < 6
                                    )"
                                    :key="contact.id"
                                    @click="getPhoneNumber(contact)"
                                    @dblclick="callingClick"
                                >
                                    <div class="call-users-info">
                                        <img
                                            src="https://firebasestorage.googleapis.com/v0/b/vue-store-1.appspot.com/o/user.png?alt=media&token=04ba7c78-0b75-431d-aa5b-3456a8ada2a2"
                                            alt=""
                                        />
                                        <div class="call-users-info-text">
                                            <span class="name">
                                                {{ contact.displayname }}
                                            </span>
                                            <span class="sek-off font_10 fbig">
                                            </span>
                                            <span class="tel">
                                                {{ contact.username }}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </el-tab-pane>
                    </el-tabs>
                </div>
            </div>
        </div>
        <!-- end CAll NUMBER TABS HISTOR KONTAK -->

        <div
            class="call-big no-modal-kontak"
            :class="{ activeKontak: activeKontakModal }"
            style="background: #fff; border: 1px solid #d7d5d5"
        >
            <i
                class="el-icon-circle-close closeIconPhone-close"
                @click="closeIconKontak"
            >
            </i>

            <callKantentCom
                ref="callKantentCom"
                @closeContactModal="closeIconKontak"
            ></callKantentCom>
        </div>
        <!-- end call add Kantent Modal -->

        <div
            class="call-big no-modal-task"
            :class="{ activetask: activeTaskModal }"
        >
            <i
                class="el-icon-circle-close closeIconPhone-close"
                @click="closeIconKontakTask"
            >
            </i>

            <callTaskCom
                ref="callTaskCom"
                @addModalEdit="addModalEdit"
                @createDealFromContact="createDealFromContact"
            ></callTaskCom>
        </div>
        <!-- end call New Task Modal -->
        <div
            class="calling-style"
            :class="{ active3: callingModal, active4: miniPage }"
        >
            <div v-show="callSessionsList.length > 1" class="main__call">
                <div>
                    <ul class="meeting__ul">
                        <li
                            v-for="(call_session, index) in callSessionsList"
                            :key="'call_' + index"
                            class="meeting__li"
                        >
                            <span class="meeting__title">{{
                                (call_session.session.direction === "incoming"
                                    ? $t("message.incoming")
                                    : $t("message.outgoing")) +
                                " " +
                                $t("message.call")
                            }}</span>
                            <img
                                class="meeting__image"
                                src="/img/default-user-icon.png"
                                alt=""
                            />
                            <h5>{{ call_session.display_name }}</h5>
                            <h5>{{ call_session.username }}</h5>
                            <div class="meeting__buttons">
                                <button
                                    class="mute__mic"
                                    v-show="
                                        call_session.session.status ===
                                        call_session.session.C.STATUS_CONFIRMED
                                    "
                                >
                                    <i
                                        class="el-icon-video-pause"
                                        @click="
                                            puttCallOnHold(call_session.session)
                                        "
                                    >
                                    </i>
                                </button>
                                <button
                                    v-show="
                                        call_session.session.status ===
                                        call_session.session.C
                                            .STATUS_WAITING_FOR_ANSWER
                                    "
                                    class="mute__mic"
                                >
                                    <i
                                        class="el-icon-phone"
                                        @click="
                                            answerCall(
                                                call_session.session,
                                                index
                                            )
                                        "
                                    >
                                    </i>
                                </button>
                                <button class="decline__icon">
                                    <i
                                        class="el-icon-phone"
                                        @click="
                                            hangUpCall(
                                                call_session.session,
                                                index
                                            )
                                        "
                                    ></i>
                                </button>
                            </div>
                        </li>
                    </ul>
                </div>

                <div class="calling-icons align__center">
                    <i
                        v-if="elapsedTime"
                        class="el-icon-plus unique__button opacityshow"
                        @click="addPhone('adding')"
                    >
                    </i>
                    <i
                        v-if="elapsedTime && !addingCall"
                        class="el-icon-sort unique__button opacityshow"
                        @click="addPhone('transfering')"
                    >
                    </i>
                    <i
                        @click="microphoneCalling"
                        :class="{ offMicrophone: addMic }"
                        class="el-icon-microphone"
                    >
                    </i>

                    <i
                        class="el-icon-user"
                        v-if="!miniPage"
                        @click="ikontalModal"
                    >
                    </i>
                    <i
                        class="el-icon-suitcase"
                        v-if="!miniPage"
                        @click="ikontalModalTask"
                    >
                    </i>
                    <!-- <i class="el-icon-phone" @click="closeCallingClick"></i> -->
                    <!-- <i
                        class="el-icon-phone-outline"
                        @click="phoneOutline"
                        :class="{ noneBlock: noneBlock }"
                    >
                    </i> -->
                    <i class="el-icon-copy-document" @click="miniWidow"></i>
                </div>
            </div>
            <div v-show="callSessionsList.length === 1" class="phone__center">
                <div
                    v-if="callSessionsList.length === 1"
                    class="calling-text waviy"
                >
                    {{
                        callSessionsList[0].session.direction === "outgoing" &&
                        callSessionsList[0].session.status ===
                            callSessionsList[0].session.C.STATUS_1XX_RECEIVED
                            ? $t("message.calling") + "..."
                            : ""
                    }}
                    {{
                        callSessionsList[0].session.direction === "incoming" &&
                        callSessionsList[0].session.status ===
                            callSessionsList[0].session.C
                                .STATUS_WAITING_FOR_ANSWER
                            ? "Входящий звонок"
                            : ""
                    }}
                </div>
                <div class="clling-img">
                    <img src="/img/default-user-icon.png" alt="" />
                </div>
                <div
                    v-if="callSessionsList.length === 1"
                    class="calling-nam-user font18"
                >
                    <i class="el-icon-office-building"></i>
                    {{
                        callSessionsList[0].company
                            ? callSessionsList[0].company.name.toUpperCase()
                            : ""
                    }}
                </div>
                <div
                    v-if="callSessionsList.length === 1"
                    class="calling-nam-user"
                >
                    <!-- Name -->
                    {{ callSessionsList[0].display_name }}
                </div>
                <div
                    v-if="callSessionsList.length === 1"
                    class="calling-nam-user"
                >
                    <!-- Tell -->
                    {{ callSessionsList[0].username }}
                </div>
                <div
                    v-if="elapsedTime > 0"
                    class="elapsedTime calling-pharom-user"
                >
                    {{
                        elapsedTime > 0
                            ? formattedElapsedTime
                            : $t("message.calling") + "..."
                    }}
                </div>

                <div class="calling-icons">
                    <router-link to="texnoApplicationsAdd">
                        <i
                            style="background-color: #67c23a"
                            class="el-icon-document-add"
                            @click="goToLink()"
                        >
                        </i>
                    </router-link>

                    <i
                        v-if="
                            callSessionsList.length > 0 &&
                            callSessionsList[0].session.status ===
                                callSessionsList[0].session.C.STATUS_CONFIRMED
                        "
                        class="el-icon-plus unique__button opacityshow"
                        @click="addPhone('adding')"
                    >
                    </i>
                    <i
                        v-if="
                            callSessionsList.length > 0 &&
                            callSessionsList[0].session.status ===
                                callSessionsList[0].session.C.STATUS_CONFIRMED
                        "
                        class="el-icon-sort unique__button opacityshow"
                        @click="addPhone('transfering')"
                    >
                    </i>
                    <i
                        v-if="
                            callSessionsList.length > 0 &&
                            callSessionsList[0].session.status ===
                                callSessionsList[0].session.C.STATUS_CONFIRMED
                        "
                        @click="microphoneCalling(callSessionsList[0].session)"
                        :class="{ offMicrophone: addMic }"
                        class="el-icon-microphone"
                    >
                    </i>
                    <i
                        class="el-icon-user"
                        v-if="!miniPage"
                        @click="ikontalModal"
                    >
                    </i>
                    <i
                        class="el-icon-suitcase"
                        v-if="!miniPage"
                        @click="ikontalModalTask"
                    >
                    </i>
                    <i
                        class="el-icon-phone-outline"
                        @click="phoneOutline(callSessionsList[0].session)"
                        :class="{ noneBlock: noneBlock }"
                    >
                    </i>
                    <i
                        class="el-icon-phone"
                        @click="closeCallingClick(callSessionsList[0].session)"
                    ></i>
                    <i class="el-icon-copy-document" @click="miniWidow"></i>
                    <!-- <el-button v-if="!miniPage" @click="ikontalModalTask" type="danger" icon="el-icon-delete" circle></el-button> -->
                </div>
            </div>
        </div>
        <!-- end call Call Mini CAll Modal -->

        <div v-if="!activeIconPhone" class="fixed__column">
            <div
                class="carte"
                @click="newcontact = true"
                @mouseleave="newcontact = false"
            >
                <div class="carte__button" @mouseover="newcontact = true">
                    <span class="carte__icon">
                        <i class="el-icon-plus"> </i>
                    </span>
                </div>
                <transition name="just">
                    <div class="carte__inside" v-show="newcontact">
                        <ul class="carte__ul">
                            <li
                                class="carte__li"
                                @click="drawerDealCreate = true"
                            >
                                <a>{{ $t("message.new_deal") }}</a>
                                <span>С</span>
                            </li>
                            <li
                                class="carte__li"
                                @click="taskModalDrawer = true"
                            >
                                <a>{{ $t("message.new_task") }}</a>
                                <span>З</span>
                            </li>
                            <li
                                class="carte__li"
                                @click="drawerCreateContact = true"
                            >
                                <a>{{ $t("message.new_contact") }}</a>
                                <span>К</span>
                            </li>
                        </ul>
                    </div>
                </transition>
            </div>
        </div>
        <!-- end call-big-modal -->

        <div class="app-modal app-modal__full">
            <el-dialog
                :visible.sync="taskModalDrawer"
                ref="drawerCreateTask"
                @opened="drawerOpened('drawerCreateTaskChild')"
                @closed="drawerClosed('drawerCreateTaskChild')"
                :fullscreen="true"
            >
                <div
                    @click="taskModalDrawer = false"
                    class="
                        close-modal-stick
                        d-flex
                        align-center
                        p-fixed
                        text-white
                        p-2
                        font-bold
                        pointer
                        transition
                    "
                >
                    <div
                        class="
                            close-modal-icon
                            text-center
                            rounded-circle
                            transition
                        "
                    >
                        <i class="el-icon-close"></i>
                    </div>
                    <span class="close-modal-text text-uppercase ml-2"></span>
                </div>
                <TaskModal
                    ref="drawerCreateTaskChild"
                    drawer="drawerCreateTask"
                />
            </el-dialog>

            <el-dialog
                :visible.sync="drawerDealCreate"
                :fullscreen="true"
                ref="drawerDealCreate"
                @opened="drawerOpened('drawerDealCreateChild')"
                @closed="drawerClosed('drawerDealCreateChild')"
            >
                <div
                    @click="closeAddModel()"
                    class="
                        close-modal-stick
                        d-flex
                        align-center
                        p-fixed
                        text-white
                        p-2
                        font-bold
                        pointer
                        transition
                    "
                >
                    <div
                        class="
                            close-modal-icon
                            text-center
                            rounded-circle
                            transition
                        "
                    >
                        <i class="el-icon-close"></i>
                    </div>
                    <span class="close-modal-text text-uppercase ml-2"></span>
                </div>
                <DealModal
                    ref="drawerDealCreateChild"
                    :board_id="board_id"
                    :selectedDeal="selectedDeal"
                    :dealCreate="dealCreate"
                    drawer="drawerDealCreate"
                />
            </el-dialog>

            <el-drawer
                :with-header="false"
                :visible.sync="drawerCreateContact"
                class="body_scroll"
                ref="drawerCreateContact"
                size="50%"
                @opened="drawerOpened('drawerCreateContactChild')"
                @closed="drawerClosed('drawerCreateContactChild')"
            >
                <div>
                    <contact-create
                        ref="drawerCreateContactChild"
                        drawer="drawerCreateContact"
                    >
                    </contact-create>
                </div>
            </el-drawer>
        </div>
    </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import _ from "lodash";
import form from "@/utils/mixins/form";
import drawer from "@/utils/mixins/drawer";
import TaskModal from "@/views/tasks/components/add-modal";
import DealModal from "@/views/deals/components/add-modal";
import ContactCreate from "@/views/phoneContacts/components/crm-create";
import callKantentCom from "@/components/call/callTaskCom";
import callTaskCom from "@/components/call/callKantentCom";
import JsSIP from "jssip";

export default {
    mixins: [form, drawer],
    components: {
        TaskModal,
        DealModal,
        ContactCreate,
        callTaskCom,
        callKantentCom,
    },
    data() {
        return {
            activeCatPhone: false,
            miniStatus: false,
            radio: "registered",
            activeName: "callTab",
            calculation: "",
            contactSearchInput: "",
            input2: "",
            activeIconPhone: false,
            callingModal: false,
            addMic: false,
            noneBlock: false,
            miniPage: false,
            noneIcon: false,
            elapsedTime: 0,
            newcontact: false,
            timer: undefined,
            taskModalDrawer: false,
            reloadList: false,
            drawerDealCreate: false,
            dealCreate: true,
            drawerCreateContact: false,
            pickUpCallIcon: false,
            activeKontakModal: false,
            activeTaskModal: false,
            userAgentOptions: {},
            connection: null,
            phone: "",
            audioStart: false,
            selectedDeal: {},
            board_id: null,
            phoneStatus: "",
            activeCatPhone: false,
            dataSearchIsWorking: false,
            callHistoryInput: "",
            format: "",
            regax: "^",
            template: "XX XXX XX XX",
            phonet: "",
            sipAccountList: [],
            selectedPhoneNumberForOutboundCall: "",
            isTransferCall: false,
            addingCall: false,
            callDirection: "",
            callSessionsList: [],
        };
    },
    mounted() {
        this.addKeyPressListener();

        if (!this.audioStart) {
            document.addEventListener("mouseup", (e) => {
                document.getElementById("incoming-call-beep").play();
                if (!document.getElementById("incoming-call-beep").paused) {
                    document.getElementById("incoming-call-beep").load();
                }
                this.audioStart = true;
            });
        }
        let x = 1;
        this.format = this.template.replace(/X+/g, () => "$" + x++);
        this.template.match(/X+/g).forEach((char, key) => {
            this.regax += "(\\d{" + char.length + "})?";
        });
    },
    watch: {
        calculation(next, prev) {
            this.phonet = this.calculation;
            if (next.length > prev.length) {
                this.phonet = this.phonet
                    .replace(/[^0-9]/g, "")
                    .replace(new RegExp(this.regax, "g"), this.format)
                    .substr(0, this.template.length);
            }
        },
    },
    computed: {
        ...mapGetters({
            phoneContacts: "phoneContacts/inventory",
            authUser: "auth/user",
            serverSipAccountList: "sipAccounts/serverSipAccountList",
            callHistoryList: "callHistories/phoneCallList",
            ariPhones: "sipAccounts/ariPhones",
        }),
        formattedElapsedTime() {
            const date = new Date(null);
            date.setSeconds(this.elapsedTime / 1000);
            const utc = date.toUTCString();
            return utc.substr(utc.indexOf(":") - 2, 8);
        },
    },
    created() {
        setTimeout(() => {
            if (this.authUser.sip_account) {
                if (this.authUser.sip_account.phones.length > 0) {
                    this.selectedPhoneNumberForOutboundCall =
                        this.authUser.sip_account.phones[0].prefix;
                    this.debouncedRegisterUserAgentJSSIP(this);
                }
            } else {
                this.phoneStatus = "";
            }
        }, 200);
        this.updateContactList();
        let url =
            "https://bmcallserver.gomax.uz:8089/ari/endpoints?api_key=go_crm_ari_user:ari_password";
        this.ariConnectionWithServer(url);

        this.sipAccountsFromServer().then((res) => {
            this.sipAccountList = JSON.parse(
                JSON.stringify(this.serverSipAccountList)
            );
        });
        this.updateContactInventory();
        this.debouncedFetchCallHistoryData(this);
    },

    methods: {
        ...mapActions({
            updateContactInventory: "phoneContacts/inventory",
            searchPhoneContact: "phoneContacts/searchPhoneContact",
            updateTaskList: "taskBoards/index",
            updateDealsList: "boards/index",
            updateContactList: "phoneContacts/index",
            updatePhoneNumberDeals: "deals/phoneNumberDeals",
            sipAccountsFromServer: "sipAccounts/sipAccountsFromServer",
            ariConnectionWithServer: "sipAccounts/ariConnectionWithServer",
            updateCallHistoryList: "callHistories/getPhoneCalls",
        }),
        goToLink() {
            this.miniPage = true;
        },

        addPhone(event) {
            if (event == "adding") {
                this.addingCall = true;
                this.activeIconPhone = true;
                this.callingModal = false;
            }
            if (event == "transfering") {
                this.isTransferCall = true;
                this.activeIconPhone = true;
                this.callingModal = false;
            }
        },
        setSipAccountsState(number) {
            let phone_list = JSON.parse(JSON.stringify(this.ariPhones));
            let phone_state = phone_list.find(
                (item) => item.resource === number
            );
            let color = "red";
            if (phone_state) {
                if (phone_state.state === "online") {
                    color = "green";
                }
            }
            return color;
        },
        phoneNotRegisteredMessage() {
            this.$notify({
                title: this.$t("message.register"),
                type: "error",
                offset: 130,
                message: this.$t(
                    "message.phone_not_registered_please_register_phone_to_make_a_call"
                ),
            });
        },
        isActive(menuItem) {
            return this.radio === menuItem;
        },
        setActive(menuItem) {
            if (menuItem === "registered") {
                this.debouncedRegisterUserAgentJSSIP(this);
            }
            if (menuItem === "unregistered") {
                this.unregisterThePhone();
            }

            this.radio = menuItem;
            this.miniStatus = false;
        },
        onClickOutside() {
            this.miniStatus = false;
        },
        debouncedRegisterUserAgentJSSIP: _.debounce((self) => {
            if (_.isFunction(self.registerUserAgentJSSIP)) {
                self.registerUserAgentJSSIP();
            }
        }, 1000),
        debouncedFetchCallHistoryData: _.debounce((self) => {
            if (_.isFunction(self.fetchCallHistoryData)) {
                self.fetchCallHistoryData();
            }
        }, 3000),
        calculateCallDuration(duration) {
            let minutes = Math.floor(duration / 60);
            let seconds = duration % 60;

            if (minutes < 10) {
                minutes = "0" + minutes;
            }
            if (seconds < 10) {
                seconds = "0" + seconds;
            }
            let time = minutes + " : " + seconds;
            return time;
        },
        findContactAttribute(callH, atribute_name) {
            let number = "";
            if (this.authUser.sip_account) {
                if (callH.src !== this.authUser.sip_account.sip_number) {
                    number = callH.src;
                }
            } else {
                number = callH.dst;
            }

            let account = {};

            if (
                this.phoneContacts.find((item) => item.phone_number === number)
            ) {
                account = this.phoneContacts.find(
                    (item) => item.phone_number === number
                );
            }
            if (
                this.serverSipAccountList.find(
                    (item) => item.username === number
                )
            ) {
                account = this.serverSipAccountList.find(
                    (item) => item.username === number
                );
            }

            let string_data = "";

            if (atribute_name === "name") {
                string_data = account.name
                    ? account.name
                    : account.displayname
                    ? account.displayname
                    : "";
            }
            if (atribute_name === "company") {
                string_data = account.company ? account.company.name : "";
            }
            if (atribute_name === "number") {
                string_data = number;
            }

            return string_data;
        },
        createDealFromContact() {
            this.dealCreate = true;
            this.activeTaskModal = false;
            this.drawerDealCreate = true;
        },
        addModalEdit({ deal, dealCreate }) {
            this.activeTaskModal = false;
            this.board_id = deal.board_id;
            this.selectedDeal = deal;
            this.dealCreate = dealCreate;
            this.drawerDealCreate = true;
        },
        fetchCallHistoryData() {
            this.loadingButton = true;
            this.loadingData = true;
            this.updateCallHistoryList({
                number_of_data: 20,
                only_phone_history: true,
            })
                .then((res) => {
                    this.loadingButton = false;
                    this.loadingData = false;
                })
                .catch((err) => {
                    this.loadingButton = false;
                    this.loadingData = false;
                });
        },
        addKeyPressListener() {
            window.addEventListener(
                "keydown",
                function (e) {
                    if (this.activeIconPhone && !this.dataSearchIsWorking) {
                        if (
                            e.key >= 0 ||
                            e.key === "+" ||
                            e.key === "#" ||
                            e.key === "*"
                        ) {
                            this.calculation += e.key;
                        }
                        if (
                            e.code === "Backspace" &&
                            this.calculation.length > 0
                        ) {
                            this.calculation = this.calculation.slice(0, -1);
                        }
                        if (e.code === "Enter" && this.calculation.length > 0) {
                            this.callingClick();
                        }
                    }
                }.bind(this)
            );
        },
        registerUserAgentJSSIP() {
            // using jsSIP
            let vm = this;
            let wss_url =
                "wss://" +
                vm.authUser.sip_account.server_url +
                ":" +
                vm.authUser.sip_account.port +
                "/ws";
            let socket = new JsSIP.WebSocketInterface(wss_url);
            let sip_uri =
                this.authUser.sip_account.extension_type +
                ":" +
                this.authUser.sip_account.sip_number +
                "@" +
                this.authUser.sip_account.server_url;
            let configuration = {
                sockets: [socket],
                uri: sip_uri,
                password: this.authUser.sip_account.password,
            };

            let coolPhone = new JsSIP.UA(configuration);
            coolPhone.start();
            coolPhone.register();

            coolPhone.on("registered", function (e) {
                vm.$notify({
                    title: vm.$t("message.register"),
                    type: "success",
                    offset: 130,
                    message: vm.$t("message.phone_registered_message", {
                        phone: vm.authUser.sip_account.sip_number,
                    }),
                });
                vm.phoneStatus = "registered";
            });
            coolPhone.on("unregistered", function (e) {
                vm.$notify({
                    title: vm.$t("message.unregister"),
                    type: "success",
                    offset: 130,
                    message: vm.$t("message.phone_unregistered", {
                        phone: vm.authUser.sip_account.sip_number,
                    }),
                });

                vm.phoneStatus = "unregistered";
            });
            coolPhone.on("registrationFailed", function (e) {
                vm.$notify({
                    title: vm.$t("message.register"),
                    type: "error",
                    offset: 130,
                    message: vm.$t("message.phone_not_registered_message", {
                        phone: vm.authUser.sip_account.sip_number,
                    }),
                });
                vm.phoneStatus = "registrationFailed";
            });

            coolPhone.on("newRTCSession", function (data) {
                let session = data.session;
                session.on("peerconnection", function (data) {
                    data.peerconnection.addEventListener(
                        "addstream",
                        function (e) {
                            // set remote audio stream
                            vm.$refs["remoteAudioRef"].srcObject = e.stream;
                            vm.$refs["remoteAudioRef"].play();
                        }
                    );
                });

                if (session.direction === "outgoing") {
                    session.connection
                        .createOffer()
                        .then(function (offer) {
                            try {
                                session.connection.setLocalDescription(offer);
                            } catch (err) {
                                console.log(err, "err");
                            }
                        })
                        .catch((err) => {
                            console.log(err, "errrrrr");
                        });
                }

                vm.waitCallDecision(session);
                vm.callDirection = session.direction;

                if (session.direction === "outgoing") {
                    let start_out = true;
                    session.on("progress", function (e) {
                        if (start_out) {
                            start_out = false;
                            if (session.remote_identity.uri.user.length <= 5) {
                                document.getElementById("sound-beep").play();
                            }
                        }
                    });
                    session.on("accepted", function (e) {
                        if (vm.callSessionsList.length === 1) {
                            vm.start();
                        }
                        document.getElementById("sound-beep").load();
                    });
                    session.on("confirmed", function (e) {
                        if (vm.addingCall) {
                            vm.callSessionsList.length > 1
                                ? vm.conferencing()
                                : "";
                            setTimeout(() => {
                                vm.callSessionsList.forEach((element) => {
                                    if (element.session.isOnHold().local) {
                                        element.session.unhold();
                                    }
                                });
                            }, 200);

                            // vm.addingCall = false;
                        }
                        document.getElementById("sound-beep").load();
                    });

                    session.on("refer", function (req) {});

                    session.on("failed", function (reason) {
                        vm.callStopped(session);
                    });

                    session.on("ended", function () {
                        if (
                            vm.isTransferCall === true &&
                            session.status &&
                            session.C.STATUS_TERMINATED
                        ) {
                            vm.callSessionsList = [];
                        }
                        vm.callStopped(session);
                    });
                    session.on("icecandidate", function (event) {
                        if (
                            event.candidate.type === "srflx" &&
                            event.candidate.relatedAddress !== null &&
                            event.candidate.relatedPort !== null
                        ) {
                            event.ready();
                        }
                    });
                }
                if (session.direction === "incoming") {
                    session.on("progress", function (e) {
                        document.getElementById("incoming-call-beep").play();
                        if (session.remote_identity.uri.user) {
                            vm.updatePhoneNumberDeals(
                                session.remote_identity.uri.user
                            );
                        }
                    });
                    session.on("refer", function (req) {});
                    session.on("accepted", function () {
                        if (vm.callSessionsList.length === 1) {
                            vm.start();
                        }
                        document.getElementById("incoming-call-beep").load();
                    });
                    session.on("confirmed", function () {
                        if (vm.addingCall) {
                            vm.callSessionsList.length > 1
                                ? vm.conferencing()
                                : "";
                        }
                    });
                    session.on("ended", function () {
                        vm.callStopped(session);
                    });

                    session.on("failed", function (reason) {
                        vm.callStopped(session);
                    });
                    session.on("addstream", function (e) {
                        vm.$refs["remoteAudioRef"].srcObject = e.stream;
                        vm.$refs["remoteAudioRef"].play();
                    });
                }
            });
            this.phone = coolPhone;
        },
        unregisterThePhone() {
            if (this.phone.isRegistered()) {
                this.phone.unregister();
            }
        },
        conferencing() {
            //take all received tracks from the sessions you want to merge
            let sessions = this.callSessionsList;
            let receivedTracks = [];
            sessions.forEach(function (session) {
                if (session !== null && session !== undefined) {
                    session.session.connection
                        .getReceivers()
                        .forEach(function (receiver) {
                            receivedTracks.push(receiver.track);
                        });
                }
            });
            //use the Web Audio API to mix the received tracks
            let context = new AudioContext();
            let allReceivedMediaStreams = new MediaStream();

            sessions.forEach(function (session) {
                if (session !== null && session !== undefined) {
                    let mixedOutput = context.createMediaStreamDestination();

                    session.session.connection
                        .getReceivers()
                        .forEach(function (receiver) {
                            receivedTracks.forEach(function (track) {
                                allReceivedMediaStreams.addTrack(
                                    receiver.track
                                );
                                if (receiver.track.id !== track.id) {
                                    let sourceStream =
                                        context.createMediaStreamSource(
                                            new MediaStream([track])
                                        );
                                    sourceStream.connect(mixedOutput);
                                }
                            });
                        });
                    //mixing your voice with all the received audio
                    session.session.connection
                        .getSenders()
                        .forEach(function (sender) {
                            let sourceStream = context.createMediaStreamSource(
                                new MediaStream([sender.track])
                            );
                            sourceStream.connect(mixedOutput);
                        });
                    session.session.connection
                        .getSenders()[0]
                        .replaceTrack(mixedOutput.stream.getTracks()[0]);
                }
            });

            //play all received stream to you
            this.$refs["remoteAudioRef"].srcObject = allReceivedMediaStreams;
            let promiseRemote = this.$refs["remoteAudioRef"].play();

            if (promiseRemote !== undefined) {
                promiseRemote
                    .then((_) => {
                        console.log("playing all received streams to you");
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            }
        },

        addCallToCallSessionsList(session) {
            let existing_session = {};
            if (session.direction === "incoming") {
                if (session.remote_identity.uri.user.length > 5) {
                    let find_con = this.phoneContacts.find((el) =>
                        el.phone_number.includes(
                            session.remote_identity.display_name
                        )
                    );
                    existing_session = {
                        session: session,
                        display_name: find_con
                            ? JSON.parse(JSON.stringify(find_con.name))
                            : session.remote_identity.display_name,
                        username: find_con
                            ? JSON.parse(
                                  JSON.stringify(
                                      session.remote_identity.uri.user
                                  )
                              )
                            : "",
                        company: find_con
                            ? find_con.company
                                ? find_con.company
                                : ""
                            : "",
                    };
                } else {
                    existing_session = {
                        session: session,
                        display_name: JSON.parse(
                            JSON.stringify(session.remote_identity.display_name)
                        ),
                        username: JSON.parse(
                            JSON.stringify(session.remote_identity.uri.user)
                        ),
                        company: "",
                    };
                }
            }
            if (session.direction === "outgoing") {
                if (this.calculation.length > 5) {
                    let find_con = this.phoneContacts.find((el) =>
                        el.phone_number.includes(this.calculation)
                    );
                    existing_session = {
                        session: session,
                        display_name: find_con
                            ? JSON.parse(JSON.stringify(find_con.name))
                            : "",
                        username: JSON.parse(JSON.stringify(this.calculation)),
                        company: find_con
                            ? find_con.company
                                ? find_con.company
                                : ""
                            : "",
                    };
                } else {
                    let find_con = this.sipAccountList.find(
                        (el) => el.username === this.calculation
                    );
                    existing_session = {
                        session: session,
                        display_name: find_con
                            ? JSON.parse(JSON.stringify(find_con.displayname))
                            : "",
                        username: JSON.parse(JSON.stringify(this.calculation)),
                        company: "",
                    };
                }
            }

            if (this.addingCall && this.callSessionsList.length === 1) {
                this.callSessionsList.forEach((tell) => {
                    tell.session.hold();
                });
            }
            this.callSessionsList.unshift(existing_session);
        },
        callStopped(session) {
            if (session.status !== session.C.STATUS_CONFIRMED) {
                this.callSessionsList.forEach((item) => {
                    if (item.session.isOnHold().local) {
                        item.session.unhold();
                    }
                });
            }
            this.callSessionsList = this.callSessionsList.filter(
                (item) =>
                    item.session.status !== item.session.C.STATUS_TERMINATED
            );

            document.getElementById("sound-beep").load();
            document.getElementById("incoming-call-beep").load();
            this.callingModal = false;
            this.activeIconPhone = true;
            this.noneBlock = false;
            this.addMic = false;
            if (this.callSessionsList.length === 0 || this.isTransferCall) {
                this.stop();
                this.isTransferCall = false;
                this.addingCall = false;
            }
            this.fetchCallHistoryData();
        },

        callToSipAccount(sip_account) {
            this.getPhoneNumber(sip_account);
            setTimeout(() => {
                this.callingClick();
            }, 100);
        },
        puttCallOnHold(session) {
            if (session.isOnHold().local) {
                session.unhold();
            } else {
                session.hold();
            }
        },
        hangUpCall(session, index) {
            if (
                (session.status &&
                    session.status !== session.C.STATUS_TERMINATED) ||
                session.status === 0
            ) {
                if (!document.getElementById("incoming-call-beep").paused) {
                    document.getElementById("incoming-call-beep").load();
                }
                if (!document.getElementById("sound-beep").paused) {
                    // document.getElementById("sound-beep").load();
                }

                session.terminate();
                if (session && session.status === session.C.STATUS_TERMINATED) {
                    this.callSessionsList.forEach((item) => {
                        if (item.session.isOnHold().local) {
                            item.session.unhold();
                        }
                    });
                }
            }
            this.fetchCallHistoryData();
        },
        callToOthers(ua) {
            this.noneBlock = true;
            var options = {
                mediaConstraints: { audio: true, video: false },
                pcConfig: {
                    iceServers: [
                        {
                            urls: [
                                "stun:stun.l.google.com:19302",
                                // "stun:stun.zoiper.com:3478"
                            ],
                        },
                    ],
                    iceTransportPolicy: "all",
                    rtcpMuxPolicy: "negotiate",
                },
            };
            this.pickUpCallIcon = false;
            let vm = this;

            let number = "";
            if (
                this.calculation > 0 &&
                this.calculation.length <= 5 &&
                this.authUser.sip_account
            ) {
                number =
                    this.authUser.sip_account.extension_type +
                    ":" +
                    this.calculation +
                    "@" +
                    this.authUser.sip_account.server_url;
            } else {
                number = this.calculation;
            }
            this.updatePhoneNumberDeals(number);
            if (ua) {
                if (this.calculation.length > 5) {
                    number =
                        this.selectedPhoneNumberForOutboundCall.toString() +
                        number.toString();
                }
                if (this.isTransferCall && this.callSessionsList.length === 1) {
                    this.transferCall(number, options);
                } else {
                    let session = ua.call(number, options);
                    session.connection.addEventListener("addstream", (e) => {
                        if (
                            session.status &&
                            session.status === session.C.STATUS_TERMINATED
                        ) {
                        } else {
                            vm.$refs["remoteAudioRef"].srcObject = e.stream;
                            vm.$refs["remoteAudioRef"].play();
                        }
                    });
                }
            }
        },
        transferCall(num, options) {
            this.callSessionsList[0].session.refer(num, options);
            if (
                this.callSessionsList[0].session.status &&
                this.callSessionsList[0].session.status ===
                    this.callSessionsList[0].session.C.STATUS_TERMINATED
            ) {
                this.callSessionsList = [];
            }
        },
        answerCall(session, index) {
            if (Object.keys(session)) {
                let callOptions = {
                    mediaConstraints: {
                        audio: true, // only audio calls
                        video: false,
                    },
                    rtcOfferConstraints: {
                        audio: true, // only audio calls
                        video: false,
                    },
                    sessionTimersExpires: 3600,
                    pcConfig: {
                        iceServers: [
                            {
                                urls: [
                                    "stun:stun.l.google.com:19302",
                                    // "stun:stun.zoiper.com:3478"
                                ],
                            },
                        ],
                        iceTransportPolicy: "all",
                        rtcpMuxPolicy: "negotiate",
                    },
                };

                if (!this.addingCall) {
                    this.callSessionsList.forEach((element) => {
                        if (
                            element.session.status ===
                            element.session.C.STATUS_CONFIRMED
                        ) {
                            element.session.hold();
                        }
                    });
                }

                session.answer(callOptions);

                if (
                    session &&
                    session.status === session.C.STATUS_CONFIRMED &&
                    this.addingCall
                ) {
                    this.callSessionsList.forEach((element) => {
                        if (element.session.isOnHold().local) {
                            element.session.unhold();
                        }
                    });
                }
            }
        },
        waitCallDecision(session) {
            if (Object.keys(session)) {
                this.addCallToCallSessionsList(session);
                this.callingModal = true;
                this.pickUpCallIcon = true;
                this.activeIconPhone = false;
                this.miniPage = false;
            }
        },
        listChanged() {
            this.reloadList = true;
        },
        closeDrawer(drawer) {
            this.taskModalDrawer = false;
            if (
                this.$refs[drawer] &&
                _.isFunction(this.$refs[drawer].closeDrawer)
            ) {
                this.$refs[drawer].closeDrawer();
            }
        },
        closeAddModel() {
            this.drawerDealCreate = false;
            if (this.reloadList === true) {
                this.updateDealsList().then((res) => this.reloadList === false);
                this.updatePhoneNumberDeals(this.calculation).then(
                    (res) => this.reloadList === false
                );
            }
            if (!this.activeTaskModal && this.miniPage) {
                this.activeTaskModal = true;
            }
            setTimeout(() => {
                this.selectedDeal = {};
                this.board_id = null;
                this.dealCreate = true;
            }, 100);
        },
        drawerClosed(ref) {
            if (this.$refs[ref]) {
                this.$refs[ref].closed();
            }
            if (this.reloadList === true) {
                if (ref === "drawerCreateContactChild") {
                    this.updateContactList().then(
                        (res) => this.reloadList === false
                    );
                }
                if (ref === "drawerCreateTaskChild") {
                    this.updateTaskList().then(
                        (res) => this.reloadList === false
                    );
                }
            }
            if (_.isFunction(this.empty)) {
                this.empty();
            }
        },
        drawerOpened(ref) {
            if (this.$refs[ref]) {
                if (_.isFunction(this.$refs[ref].opened)) {
                    this.$refs[ref].opened();
                }
            }
        },
        searchInputBlur() {
            this.dataSearchIsWorking = false;
        },
        searchInputFocus() {
            this.dataSearchIsWorking = true;
        },
        findCallHistorySearch() {},
        findContactInputSearch() {
            if (this.contactSearchInput) {
                this.searchContacts(this.contactSearchInput, this);
            } else {
                setTimeout(() => {
                    this.updateContactInventory();
                }, 1000);
            }
        },
        searchContacts: _.debounce((search, self) => {
            if (_.isFunction(self.searchPhoneContact)) {
                self.searchPhoneContact({ text: search });
            }
        }, 1000),
        getPhoneNumber(contact) {
            if (contact.phone_number) {
                this.calculation = JSON.parse(
                    JSON.stringify(contact.phone_number)
                );
            } else {
                this.calculation = JSON.parse(JSON.stringify(contact.username));
            }
        },
        start() {
            this.timer = setInterval(() => {
                this.elapsedTime += 1000;
            }, 1000);
        },
        stop() {
            clearInterval(this.timer);
            setTimeout(() => {
                this.elapsedTime = 0;
            }, 300);
        },
        handleClose(done) {
            this.$confirm(this.$t("message.Are you sure to close this window"))
                .then((_) => {
                    done();
                })
                .catch((_) => {});
        },

        /****************Phone Numer***********************/
        append(value) {
            this.calculation += value.toString();
        },
        del() {
            if (this.calculation.toString().length > 1) {
                this.calculation = this.calculation
                    .toString()
                    .substring(0, this.calculation.toString().length - 1);
            } else {
                this.calculation = "";
            }
        },
        /****************End Phone Numer***********************/

        /****************icon Phone Icon Modal***********************/
        iconPhone(e) {
            this.activeIconPhone = true;
            this.noneIcon = true;
        },
        closeIconPhone(e) {
            if (this.callSessionsList.length < 1) {
                this.activeIconPhone = false;
                this.noneIcon = false;
            } else {
                this.isTransferCall = false;
                this.callingModal = true;
                this.activeIconPhone = false;
            }
        },
        closeCatPhone(e) {
            this.activeCatPhone = false;
            this.activeIconPhone = true;
        },
        openCatPhone(e) {
            this.activeCatPhone = true;
            this.activeIconPhone = false;
        },
        /****************icon Phone Icon Modal***********************/

        ikontalModal(e) {
            this.activeKontakModal = true;
            this.miniPage = true;
            this.$refs.callKantentCom.afterOpen();
        },

        closeIconKontak(e) {
            this.activeKontakModal = false;
            this.miniPage = !this.miniPage;
        },

        ikontalModalTask(e) {
            this.activeTaskModal = true;
            this.miniPage = true;
            this.$refs.callTaskCom.afterOpen();
        },

        closeIconKontakTask(e) {
            this.activeTaskModal = false;
            this.miniPage = !this.miniPage;
        },

        /****************icon Phone Icon Modal***********************/
        callingClick(e) {
            if (this.phone.isRegistered()) {
                if (
                    this.calculation.length > 0 &&
                    this.calculation !== this.authUser.sip_account.sip_number
                ) {
                    this.closeCatPhone();
                    this.callingModal = true;
                    this.activeIconPhone = false;
                    this.miniPage = false;
                    this.callToOthers(this.phone);
                    this.elapsedTime = 0;
                } else if (this.calculation.length === 0) {
                    this.$notify({
                        title: this.$t("message.no_phone"),
                        type: "warning",
                        offset: 130,
                        message: this.$t("message.enter_phone_number_to_call"),
                    });
                } else {
                    this.$notify({
                        title: this.$t("message.no_phone"),
                        type: "warning",
                        offset: 130,
                        message: this.$t("message.you_can_not_call_yourself"),
                    });
                }
            } else {
                this.phoneNotRegisteredMessage();
                this.phoneStatus = "unregistered";
            }
        },
        closeCallingClick(session) {
            this.activeKontakModal = false;
            this.activeTaskModal = false;
            this.callingModal = false;
            this.activeIconPhone = true;
            this.noneBlock = false;
            this.hangUpCall(session, 0);
        },
        /****************icon Phone Icon Modal***********************/

        microphoneCalling(session) {
            this.addMic = !this.addMic;
            if (this.addMic) {
                session.mute({ audio: true });
            } else {
                session.unmute({ audio: true });
            }
        },

        /****************qong'iroq bo'lganda ko'tarish iconni***********************/
        phoneOutline(session) {
            this.noneBlock = true;
            this.answerCall(session, 0);
        },

        miniWidow(e) {
            this.activeKontakModal = false;
            this.activeTaskModal = false;
            this.miniPage = !this.miniPage;
        },

        /****************end qong'iroq bo'lganda ko'tarish iconni***********************/
    },
};
</script>
<style>
/* ikkinchi modal */
.btn-call.noneIcon {
    display: none;
}
.calling-style {
    width: 60%;
    height: 515px;
    opacity: 0;
    background: #2c3e50;
    margin: auto;
    padding: 20px;
    position: fixed;
    z-index: 0;
    top: 20vh;
    right: 0;
    left: 0;
    display: flex;
    border-radius: 10px;
    transform: translate(0%, 0%) scale(0);
    transition: all 0.5s cubic-bezier(0.6, -0.28, 0.735, 0.045);
}
.calling-style.active3 {
    transform: translate(0%, 0%) scale(1);
    transition: all 0.8s cubic-bezier(0.175, 0.885, 0.32, 1.075);
}
.calling-style.active4 {
    transform: translate(0%, 0%) scale(1);
    transition: all 0.8s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
.calling-style.active3 {
    opacity: 1;
    z-index: 9994;
}
.calling-text {
    margin-bottom: 30px !important;
    /* -webkit-box-reflect: below -5px linear-gradient(transparent, rgba(0, 0, 0, 0.2)); */
    animation: waviy 1s infinite;
    animation-delay: calc(0.1s * var(--i));
}
@keyframes waviy {
    0%,
    40%,
    100% {
        transform: translateY(3px);
    }
    20% {
        transform: translateY(-3px);
    }
}
.calling-nam-user,
.calling-text {
    color: #fff;
    font-size: 20px;
    margin-bottom: 10px;
    font-weight: 600;
}
.calling-pharom-user {
    font-size: 18px;
    color: #fff;
    margin-bottom: 15px;
}
.clling-img img {
    width: 100px;
    height: 100px;
    overflow: hidden;
    border: 2px solid #fff;
    border-radius: 50%;
    box-shadow: 0 8px 10px 0 rgba(0, 0, 0, 0.24),
        0 10px 50px 0 rgba(0, 0, 0, 0.19);
    margin-bottom: 20px;
}

.calling-icons i {
    font-size: 21px;
    padding: 10px;
    border-radius: 50%;
    color: #fff;
    margin: 5px;
    cursor: pointer;
}
.calling-icons .el-icon-phone {
    background: red;
    transform: rotate(135deg);
}
.calling-icons .el-icon-phone-outline {
    background: #00d669;
}

.calling-icons .el-icon-microphone {
    background-color: #e6a23c;
}

.calling-icons .el-icon-copy-document {
    background-color: #8b8f999c;
}
.calling-icons .el-icon-user {
    background: #fff;
    color: #e6a23c !important;
}
.calling-icons .el-icon-suitcase {
    background-color: #8b8f999c;
}
.el-icon-microphone.offMicrophone {
    background: #fff;
    color: #e6a23c !important;
}
.el-icon-microphone.offMicrophone:before {
    content: "\e728" !important;
}
.el-icon-phone-outline.noneBlock {
    display: none;
}
.calling-style.active4 {
    width: 200px;
    height: 200px;
    right: 20px !important;
    left: initial;
    bottom: 20px;
    top: inherit;
    justify-content: center;
}
.calling-style.active4 .clling-img {
    display: none;
}
.calling-style.active4 .calling-text {
    margin-bottom: 10px !important;
}
.calling-style.active4 .calling-nam-user,
.calling-style.active4 .calling-text {
    font-size: 12px;
    font-weight: 300;
}

.calling-style.active4 .calling-icons i {
    font-size: 18px;
    padding: 7px;
    border-radius: 50%;
    color: #fff;
    margin: 4px;
    cursor: pointer;
}
/* ikkinchi modal */

.closeIconPhone-close {
    position: absolute;
    top: -17px;
    right: -17px;
    font-size: 38px;
    background: #b55e7d;
    color: #fff;
    border-radius: 50%;
    cursor: pointer;
}

.no-modal-task,
.no-modal-kontak,
.no-modal2 {
    width: 850px;
    position: fixed;
    top: 20vh;
    /* bottom: 0; */
    right: 0;
    left: 0;
    background: whitesmoke;
    box-shadow: rgba(17, 17, 26, 0.05) 0px 4px 16px,
        rgba(17, 17, 26, 0.05) 0px 8px 32px;
    margin: auto;
    padding: 20px;
    opacity: 0;
    transform: translate(0%, 0%) scale(0);
    transition: all 0.8s cubic-bezier(0.6, -0.28, 0.735, 0.045);
}
.no-modal-task {
    width: 600px;
}

.no-modal-kontak {
    width: 400px;
}

.no-modal-task.activetask,
.no-modal-kontak.activeKontak,
.no-modal2.active2 {
    opacity: 1;
    z-index: 9996;
    transform: translate(0%, 0%) scale(1);
    transition: all 1s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.call-number-kontak {
    display: flex;
}
.call-kontak {
    width: 100%;
}
.dial-pad {
    width: 210px;
}
.call-number {
    border-right: 1px solid #dddddd8c;
    padding-right: 20px;
    margin-right: 20px;
}
.dial-screen {
    height: 50px;
    padding: 0px;
    font-size: 26px;
    font-weight: 400;
    line-height: 50px;
    text-align: right;
    margin-bottom: 20px;
}
.dial-table {
    width: 100%;
    display: table;
}
.dial-table .dial-table-row {
    display: table-row;
}
.dial-table .dial-table-col {
    cursor: default;
    width: 33.333333%;
    text-align: center;
    display: table-cell;
    vertical-align: top;
}
.dial-table .dial-key-wrap {
    margin-bottom: 10px;
    transition: background 0.3s ease-in-out 0s;
}
.dial-table .dial-table-col .dial-key {
    font-size: 28px;
    font-weight: 400;
    min-height: 48px;
    line-height: 48px;
    cursor: pointer;
}
.dial-table .no-sub-key .dial-key {
    min-height: 48px;
    line-height: 48px;
}
.dial-table .dial-sub-key {
    color: #aaa;
    font-size: 14px;
    text-transform: uppercase;
}
.no-sub-key .dial-sub-key {
    display: none;
}
.dial-table .dial-key-wrap.active,
.dial-table .dial-key-wrap:hover {
    color: #eee;
    background: #16a085;
}
.dial-table .dial-key-wrap.active .dial-sub-key,
.dial-table .dial-key-wrap:hover .dial-sub-key {
    color: #eee;
}
.btn-call {
    background: #38a3fd;
    border: 2px solid #38a3fd;
    border-radius: 50%;
    box-shadow: 0 8px 10px rgba(56, 163, 253, 0.3);
    cursor: pointer;
    height: 55px;
    width: 55px;
    text-align: center;
    position: fixed;
    right: 40px;
    bottom: 30px;
    z-index: 999;
    transition: 0.3s;
    -webkit-animation: hoverWave linear 1s infinite;
    animation: hoverWave linear 1s infinite;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
}

.btn-call__ico {
    display: flex;
    justify-content: center;
    align-items: center;
    animation: 1200ms ease 0s normal none 1 running shake;
    animation-iteration-count: infinite;
    -webkit-animation: 1200ms ease 0s normal none 1 running shake;
    -webkit-animation-iteration-count: infinite;
    /* color: white; */
    font-size: 30px;
    padding-top: 5px;
    transition: 0.3s all;
}

.btn-call__ico_color_white {
    color: white;
}

.btn-call__ico_color_red {
    color: red;
}

.btn-call:hover {
    background-color: #fff;
}

.btn-call:hover .btn-call__ico {
    color: #38a3fd;
}

@-webkit-keyframes hoverWave {
    0% {
        box-shadow: 0 8px 10px rgba(56, 163, 253, 0.3),
            0 0 0 0 rgba(56, 163, 253, 0.2), 0 0 0 0 rgba(56, 163, 253, 0.2);
    }

    40% {
        box-shadow: 0 8px 10px rgba(56, 163, 253, 0.3),
            0 0 0 15px rgba(56, 163, 253, 0.2), 0 0 0 0 rgba(56, 163, 253, 0.2);
    }

    80% {
        box-shadow: 0 8px 10px rgba(56, 163, 253, 0.3),
            0 0 0 30px rgba(56, 163, 253, 0),
            0 0 0 26.7px rgba(56, 163, 253, 0.067);
    }

    100% {
        box-shadow: 0 8px 10px rgba(56, 163, 253, 0.3),
            0 0 0 30px rgba(56, 163, 253, 0), 0 0 0 40px rgba(56, 163, 253, 0);
    }
}

@keyframes hoverWave {
    0% {
        box-shadow: 0 8px 10px rgba(56, 163, 253, 0.3),
            0 0 0 0 rgba(56, 163, 253, 0.2), 0 0 0 0 rgba(56, 163, 253, 0.2);
    }

    40% {
        box-shadow: 0 8px 10px rgba(56, 163, 253, 0.3),
            0 0 0 15px rgba(56, 163, 253, 0.2), 0 0 0 0 rgba(56, 163, 253, 0.2);
    }

    80% {
        box-shadow: 0 8px 10px rgba(56, 163, 253, 0.3),
            0 0 0 30px rgba(56, 163, 253, 0),
            0 0 0 26.7px rgba(56, 163, 253, 0.067);
    }

    100% {
        box-shadow: 0 8px 10px rgba(56, 163, 253, 0.3),
            0 0 0 30px rgba(56, 163, 253, 0), 0 0 0 40px rgba(56, 163, 253, 0);
    }
}

/* animations icon */

@keyframes shake {
    0% {
        transform: rotateZ(0deg);
        -ms-transform: rotateZ(0deg);
        -webkit-transform: rotateZ(0deg);
    }

    10% {
        transform: rotateZ(-30deg);
        -ms-transform: rotateZ(-30deg);
        -webkit-transform: rotateZ(-30deg);
    }

    20% {
        transform: rotateZ(15deg);
        -ms-transform: rotateZ(15deg);
        -webkit-transform: rotateZ(15deg);
    }

    30% {
        transform: rotateZ(-10deg);
        -ms-transform: rotateZ(-10deg);
        -webkit-transform: rotateZ(-10deg);
    }

    40% {
        transform: rotateZ(7.5deg);
        -ms-transform: rotateZ(7.5deg);
        -webkit-transform: rotateZ(7.5deg);
    }

    50% {
        transform: rotateZ(-6deg);
        -ms-transform: rotateZ(-6deg);
        -webkit-transform: rotateZ(-6deg);
    }

    60% {
        transform: rotateZ(5deg);
        -ms-transform: rotateZ(5deg);
        -webkit-transform: rotateZ(5deg);
    }

    70% {
        transform: rotateZ(-4.28571deg);
        -ms-transform: rotateZ(-4.28571deg);
        -webkit-transform: rotateZ(-4.28571deg);
    }

    80% {
        transform: rotateZ(3.75deg);
        -ms-transform: rotateZ(3.75deg);
        -webkit-transform: rotateZ(3.75deg);
    }

    90% {
        transform: rotateZ(-3.33333deg);
        -ms-transform: rotateZ(-3.33333deg);
        -webkit-transform: rotateZ(-3.33333deg);
    }

    100% {
        transform: rotateZ(0deg);
        -ms-transform: rotateZ(0deg);
        -webkit-transform: rotateZ(0deg);
    }
}

@-webkit-keyframes shake {
    0% {
        transform: rotateZ(0deg);
        -ms-transform: rotateZ(0deg);
        -webkit-transform: rotateZ(0deg);
    }

    10% {
        transform: rotateZ(-30deg);
        -ms-transform: rotateZ(-30deg);
        -webkit-transform: rotateZ(-30deg);
    }

    20% {
        transform: rotateZ(15deg);
        -ms-transform: rotateZ(15deg);
        -webkit-transform: rotateZ(15deg);
    }

    30% {
        transform: rotateZ(-10deg);
        -ms-transform: rotateZ(-10deg);
        -webkit-transform: rotateZ(-10deg);
    }

    40% {
        transform: rotateZ(7.5deg);
        -ms-transform: rotateZ(7.5deg);
        -webkit-transform: rotateZ(7.5deg);
    }

    50% {
        transform: rotateZ(-6deg);
        -ms-transform: rotateZ(-6deg);
        -webkit-transform: rotateZ(-6deg);
    }

    60% {
        transform: rotateZ(5deg);
        -ms-transform: rotateZ(5deg);
        -webkit-transform: rotateZ(5deg);
    }

    70% {
        transform: rotateZ(-4.28571deg);
        -ms-transform: rotateZ(-4.28571deg);
        -webkit-transform: rotateZ(-4.28571deg);
    }

    80% {
        transform: rotateZ(3.75deg);
        -ms-transform: rotateZ(3.75deg);
        -webkit-transform: rotateZ(3.75deg);
    }

    90% {
        transform: rotateZ(-3.33333deg);
        -ms-transform: rotateZ(-3.33333deg);
        -webkit-transform: rotateZ(-3.33333deg);
    }

    100% {
        transform: rotateZ(0deg);
        -ms-transform: rotateZ(0deg);
        -webkit-transform: rotateZ(0deg);
    }
}

/* .user-call--info */
.call-users-info {
    display: flex;
}
.call-users-info img {
    width: 40px;
    margin-right: 5px;
}
.call-users-info-text {
    display: flex;
    flex-direction: column;
}
.call-users-info-text .tel {
    font-size: 14px;
    color: #409eff;
}
.call-users-info-text .name {
    font-size: 12px;
    margin-bottom: 5px;
    color: #000;
}
.call-users {
    padding: 5px 4px;
    border-radius: 5px;
    border-top: 1px;
    margin-bottom: 5px;
    cursor: pointer;
}
.input-call .el-input__inner {
    background: #fff0;
    margin-bottom: 5px;
    border-radius: 50px;
    height: 30px;
    line-height: 29px;
}
.input-call .el-input__icon {
    line-height: 25px;
}
.calling-style.active4 .font18 {
    font-size: 12px;
}
.font18 {
    font-size: 18px;
}

.calling-style.active4 .calling-pharom-user {
    margin-bottom: 9px;
}

/*  */

.fixed__column {
    position: fixed;
    z-index: 999;
    height: 55px;
    width: auto;
    right: 43px;
    bottom: 95px;
}
.carte {
    position: relative;
}
.carte__icon {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    background: #459ef7;
    border-radius: 50%;
    border: 1px solid #459ef7;
    margin-left: auto;
}
.carte__icon i {
    font-size: 30px;
    color: #fff;
}
.carte__li {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: 5px 8px;
    cursor: pointer;
}

.carte__li span {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 35px;
    height: 35px;
    margin-left: 100px;
    background: #459ef7;
    border-radius: 50%;
    border: 1px solid #459ef7;
    color: #fff;
    z-index: 100;
}
.carte__li a {
    background: #459ef7;
    border: 1px solid #459ef7;
    width: 130px;
    height: 30px;
    text-align: center;
    color: #fff;
    border-radius: 0.475rem;
    line-height: 26px;
    margin-right: -95px;
    transition: all 0.2s ease-in-out;
    z-index: 90;
}
.carte__li:active span {
    border: 2px solid #459ef7;
    background: #5eacf9;
}
.carte__li:active a {
    border: 2px solid #459ef7;
    background: #5eacf9;
}
.carte__ul {
    margin-top: -180px;
    padding-bottom: 30px;
    margin-right: 0px;
}

.no-modal-task {
    background: rgb(255, 255, 255);
    top: 10vh;
    max-height: 89vh;
    height: 89vh;
    border: 1px solid #d7d5d5;
}
.no-modal-task .modal-tasks-itle {
    overflow: hidden;
    overflow-y: scroll;
    max-height: 78vh;
}

.call__numbers {
    display: flex;
    flex-direction: column;
}
.call__line {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 5px 0;
}
.call__empty {
    display: flex;
    width: 60px;
    height: 60px;
    background: #e5e5e5;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.call__empty span {
    font-size: 22px;
    font-weight: 600;
}
.call__item {
    justify-content: center;
    align-items: center;
    display: flex;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    background: #e5e5e5;
    cursor: pointer;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.call__item:active {
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
        rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}
.call__item span {
    font-size: 22px;
    font-weight: 600;
}
.call__clear {
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.status__header:active {
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
        rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}
.call__empty:active {
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
        rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}
.call__call:active {
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
        rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}
.call__clear span img {
    width: 30px;
    height: auto;
    cursor: pointer;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.call__call {
    justify-content: center;
    align-items: center;
    display: flex;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    cursor: pointer;
    background: #65c466;
}
.call__call span {
    font-size: 28px;
    color: #fff;
}
.call-big {
    border-radius: 10px;
}
.status__box {
    position: absolute;
    margin-top: 30px;
    background: #fff;
    box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
    border-radius: 10px;
}
.statuss {
    display: flex;
    justify-content: center;
}
.status__item {
    padding: 5px 10px;
    border-top: 1px solid #e5e5e5;
    display: flex;
    align-items: center;
    cursor: pointer;
}
.status__title {
    text-align: center;
    padding: 5px 0;
}
.status__title span {
    font-size: 12px;
}
.available__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: #65c466;
    font-size: 9px;
    text-align: center;
    display: flex;
    color: #fff;
    margin-right: 5px;
}
.disturb__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: #fd3b32;
    font-size: 9px;
    text-align: center;
    display: flex;
    color: #fff;
    margin-right: 5px;
}
.hidden__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: #8b8f99;
    font-size: 9px;
    text-align: center;
    display: flex;
    color: #fff;
    margin-right: 5px;
}
.status__text {
    font-size: 13px;
    padding-right: 15px;
}
.status__absolute {
    position: absolute;
    right: 0;
    margin-right: 5px;
}
.status__header {
    background: #dcdcdc;
    padding: 5px 10px;
    border-radius: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.status__header span {
    color: #fff;
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
}
.active__status {
    width: 6px;
    height: 6px;
    background: #65c466;
    border-radius: 50%;
    margin-right: 5px;
}
.notactive__status {
    width: 6px;
    height: 6px;
    background: #fd3b32;
    border-radius: 50%;
    margin-right: 5px;
}
.status-fade-enter-active {
    transition: all 0.1s;
}

.status-fade-leave-active {
    transition: all 0.1s cubic-bezier(1, 0.5, 0.8, 1);
}

.status-fade-enter-from,
.status-fade-leave-to {
    transform: translateY(-20px);
    opacity: 0;
}

.just-enter-active {
    transition: all 0.2s ease;
}
.just-leave-active {
    transition: all 0.2s ease;
}
.just-enter, .just-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
    transform: translateY(30px);
    opacity: 0;
}
.unique__button {
    cursor: not-allowed !important;
    opacity: 0.5;
    background: #8b8f999c;
}
.opacityshow {
    opacity: 1;
    cursor: pointer !important;
}
.phone__center {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    justify-content: center;
}
.meeting__li {
    display: flex;
    flex-direction: column;
    margin: 10px 5px;
    padding: 10px 0px;
    align-items: center;
    text-align: center;
    width: 100px;
    height: 191px;
}
.meeting__image {
    width: 50px;
    height: 50px;
    object-fit: cover;
    border: 2px solid #8b8f999c;
    padding: 1px;
    border-radius: 50%;
}
.meeting__buttons button {
    width: 30px;
    height: 30px;
    cursor: pointer;
    margin: 0 5px;
    margin-top: 10px;
    border-radius: 50%;
}
.decline__icon {
    background: #fd3b32;
    border-color: #fd3b32;
    color: #fff;
}
.mute__mic {
    background: #8b8f999c;
    border-color: #8b8f999c;
    color: #fff;
}
.meeting__li h5 {
    color: #fff;
    margin: 0;
    margin-top: 15px;
}
.meeting__li span {
    color: #fff;
    margin-bottom: 10px;
    font-size: 12px;
}
.meeting__ul {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.align__center {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.main__call {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
</style>
