export const getters = {
    list: state => state.list,
    inventory: state => state.inventory,
    types: state => state.types,
    deals: state => state.deals,
    genders: state => state.genders,
    companyClients: state => state.companyClients,
    personClients: state => state.personClients,
    model: state => state.model,
    rules: state => state.rules,
    columns: state => state.columns,
    filter: state => state.filter,
    pagination: state => state.pagination,
    sort: state => state.sort, 
    client_account: state => state.client_account, 
    contact_people: state => state.contact_people,
    clientDashboard: state => state.clientDashboard,
  };
  