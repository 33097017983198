export const mutations = {
    SET_TOKEN: (state, token) => state.token = token,
    SET_GOMAX_TOKEN: (state, token) => state.gomax_token = token,
    SET_HR_TOKEN: (state, token) => state.hr_token = token,
    SET_ROLE: (state, role) => state.role = role,
    SET_NAME: (state, name) => state.name = name,
    SET_PHONE: (state, phone_number) => state.phone_number = phone_number,
    SET_SLUG: (state, slug) => state.slug = slug,
    SET_CLIENT_STATUS:(state,status)=>state.status=status,
    SET_USER:(state,user)=>state.user=user
};
