<template>
  <!-- <el-tab-pane label="Товары" name="second"> -->
  <div class="product-add">
    <el-row :gutter="20">
      <el-form ref="form" :label-position="'left'" :model="form" :rules="rules">
        <el-col :offset="22" :span="3">
          <el-form-item
              :label="$t('message.columns')"
              prop="columns"
            >
            
            <el-dropdown style="display:block" class="setting-cheek">
                <el-button
                    class="padding_none"
                    size="small"
                    icon="el-icon-open"
                ></el-button>
                <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item
                        v-for="(column, index) in columns"
                        :key="'drop' + index"
                    >
                        <el-checkbox
                            :checked="column.show"
                            @change="check(column.column, $event)"
                            >{{ column.title }}
                        </el-checkbox>
                    </el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
          </el-form-item>

        </el-col>
      </el-form>

    </el-row>
        
    <div class="product-add-table">
      <table class="table-my-code table-bordered borde-l-r" v-loading="loadingData">
        <el-table :data="dealProductsList" style="width: 100%">
          <el-table-column label="Name" width="180" v-if="columns.name.show">
            <template slot-scope="scope">
              {{ scope.row.product.name }}
            </template>
          </el-table-column>
          <el-table-column :label="columns.quantity.title" v-if="columns.quantity.show">
            <template slot-scope="scope">
              <el-input
                :min="0"
                type="number"
                size="mini"
                v-model="scope.row.quantity"
              ></el-input>
            </template>
          </el-table-column>
          <el-table-column :label="columns.measurement_id.title" v-if="columns.measurement_id.show">
            <template slot-scope="scope">
              {{ scope.row.product.measurement.name }}
            </template>
          </el-table-column>
          <el-table-column :label="columns.price.title" v-if="columns.price.show">
            <template slot-scope="scope">
              <el-input
                :min="0"
                type="number"
                size="mini"
                v-model="scope.row.price"
              ></el-input>
            </template>
          </el-table-column>
          <el-table-column :label="columns.currency_id.title" v-if="columns.currency_id.show">
            <template slot-scope="scope">
              <select-currency
                :size="'mini'"
                :placeholder="columns.currency_id.title"
                :id="scope.row.currency_id"
                v-model="scope.row.currency_id"
              ></select-currency>
            </template>
          </el-table-column>

          <el-table-column :label="columns.profit.title + ' %'" v-if="columns.profit.show">
            <template slot-scope="scope">
              <el-input
                :min="0"
                :max="99"
                type="number"
                size="mini"
                @change="noMoreThan(scope.$index)"
                v-model="scope.row.profit"
              ></el-input>
            </template>
          </el-table-column>

          <el-table-column :label="columns.discount.title + ' %'" v-if="columns.discount.show">
            <template slot-scope="scope">
              <el-input
                :min="0"
                :max="99"
                type="number"
                size="mini"
                @change="noMoreThan(scope.$index)"
                v-model="scope.row.discount"
              ></el-input>
            </template>
          </el-table-column>
          <el-table-column :label="columns.nds.title + ' %'" v-if="columns.nds.show">
            <template slot-scope="scope">
              <el-input
                :min="0"
                :max="99"
                type="number"
                size="mini"
                @change="noMoreThan(scope.$index)"
                v-model="scope.row.nds"
              ></el-input>
            </template>
          </el-table-column>
          <el-table-column :label="columns.color.title" v-if="columns.color.show">
            <template slot-scope="scope">
                <select-color
                  :size="'mini'"
                  :placeholder="columns.color.title"
                  :id="scope.row.color_id"
                  :allowed_colors="scope.row.colors"
                  :model_id="scope.row.product_model_id"
                  v-model="scope.row.color_id"
                >
                </select-color>
            </template>
          </el-table-column>

          <el-table-column :label="columns.package.title" v-if="columns.package.show">
            <template slot-scope="scope">
              <select-package
                :size="'mini'"
                :placeholder="columns.package.title"
                :id="scope.row.package_id"
                :allowed_packages="scope.row.packages"
                v-model="scope.row.package_id"
              ></select-package>
            </template>
          </el-table-column>

          <el-table-column :label="columns.total.title" v-if="columns.total.show">
            <template slot-scope="scope">
              {{ productRowTotal(scope.row) | formatMoney}}
            </template>
          </el-table-column>
          <el-table-column :label="columns.delete.title" v-if="columns.delete.show">
            <template slot-scope="scope">
              <i
                @click="removeFromDealProductsList(scope.row.id, scope.$index)"
                class="el-icon-delete icon__delete"
              ></i>
            </template>
          </el-table-column>
        </el-table>
        <el-col :span="12" class="mt-2">
          <select-filial-product
            :filial_id="filial_id"
            :size="'medium'"
            :placeholder="$t('message.product_id')"
            :id="product_id"
            v-model="product_id"
            @get_selected_product="get_selected_product"
          >
          </select-filial-product>
        </el-col>
      </table>
    </div>
    <el-col :span="24" class="mt-2">
      <el-row>
        <el-col :span="12" :offset="12">
          <div class="table-responsive">
            <table class="table borderless">
              <tbody>
                <tr>
                  <th style="width: 50%">
                    {{ $t("message.Amount without discount and NDS") }}:
                  </th>
                  <td>
                    <h4 class="font-weight-bold">
                      {{ totalAmountWithoutNDSAndDiscount | formatMoney }}
                    </h4>
                  </td>
                </tr>
                <tr>
                  <th>{{ $t("message.Discount amount") }}:</th>
                  <td>
                    <h4 class="font-weight-bold">
                      {{ discountAmount | formatMoney }}
                    </h4>
                  </td>
                </tr>
                <tr>
                  <th>{{ $t("message.NDS amount") }}:</th>
                  <td>
                    <h4 class="font-weight-bold">
                      {{ NDSAmount | formatMoney }}
                    </h4>
                  </td>
                </tr>
                <tr>
                  <th>{{ $t("message.Total amount") }}:</th>
                  <td>
                    <h4 class="font-weight-bold">
                      {{ totalAmount | formatMoney }}
                    </h4>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </el-col>
      </el-row>
    </el-col>
    <el-row>
      <el-button type="primary" @click="submit()" size="small" round v-loading="loadingButton"
        v-if="permissions.some(per => per.slug == 'dealProducts.create')"
        >{{$t('message.save')}}
      </el-button>
      <el-button
        type="success"
        @click="dealOffersCreate"
        size="small"
        round
        >{{ $t('message.kp') }}
      </el-button>
    </el-row>
  </div>
  <!-- </el-tab-pane> -->
</template>

<script>
import selectFilialProduct from "@/components/inventory/select-filial-product";
import selectCurrency from "@/components/inventory/select-currency";
import selectPackage from "@/components/inventory/select-package";
import selectColor from "@/components/inventory/select-color";
import selectKpTemplate from "@/components/inventory/select-kp-template";

import { mapGetters, mapActions } from "vuex";
import drawer from "@/utils/mixins/drawer";
import form from "@/utils/mixins/form";
import show from "@/utils/mixins/show";

export default {
  components: {
    selectFilialProduct,
    selectCurrency,
    selectPackage,
    selectColor,
    selectKpTemplate,
  },
  mixins: [show, form, drawer],
  props: {
    filial_id: {
      default: null,
    },
    deal_id: {
      default: null,
    },
  },
  data() {
    return {
      dealProductsList: [],
      product_id: null,
      updateProducts: false,
    };
  },
  watch: {
    deal_id: {
      handler: function (deal_id) {
        if (deal_id) {
          if (this.deal_id) {
            this.updateList();
          }
        }
      },
      immediate: true,
      deep: true,
    },
  },
  computed: {
    ...mapGetters({
      permissions: "auth/permissions",
      getProduct: "products/model",
      model: "dealProducts/model",
      dealProducts: "dealProducts/dealProducts",
      rules: "dealProducts/rules",
      columns: "dealProducts/columns",
      currencies: "currency/inventory",
    }),

    totalAmountWithoutNDSAndDiscount: function () {
      let total = 0;
      this.dealProductsList.forEach((product) => {
        let rate = (this.currencies.length > 0 ? this.currencies.find(item => item.id === product.currency_id).rate : 1);
        total += product.quantity * product.price * rate;
      });
      return Number(total.toFixed(2));
    },
    discountAmount: function () {
      let total = 0;
      this.dealProductsList.forEach((product) => {
        let rate = (this.currencies.length > 0 ? this.currencies.find(item => item.id === product.currency_id).rate : 1);
        total += (rate * product.quantity * product.price * product.discount) / 100;
      });
      return Number(total.toFixed(2));
    },
    NDSAmount: function () {
      let total = 0;
      this.dealProductsList.forEach((product) => {
        let rate = (this.currencies.length > 0 ? this.currencies.find(item => item.id === product.currency_id).rate : 1);
        total += (rate * product.quantity * product.price * product.nds) / 100;
      });
      return Number(total.toFixed(2));
    },
    totalAmount: function () {
      let total = 0;
      this.dealProductsList.forEach((product) => {
        let rate = (this.currencies.length > 0 ? this.currencies.find(item => item.id === product.currency_id).rate : 1);
        total += rate * product.quantity * product.price * (1 - product.discount / 100 + product.nds / 100);
      });
      return Number(total.toFixed(2));
    },
    
  },
  methods: {
    ...mapActions({
      save: "dealProducts/store",
      update: "dealProducts/update",
      updateColumn: "dealProducts/updateColumn",
      show: "dealProducts/show",
      showProduct: "products/show",
      empty: "dealProducts/empty",
      destroy: "dealProducts/destroy",
    }),
    noMoreThan(index) {
        if(this.dealProductsList[index].profit >= 100){
          this.dealProductsList[index].profit = 99;
        }
        if(this.dealProductsList[index].profit < 0){
          this.dealProductsList[index].profit = 0;
        }
        if(this.dealProductsList[index].nds >= 100){
          this.dealProductsList[index].nds = 99;
        }
        if(this.dealProductsList[index].nds < 0){
          this.dealProductsList[index].nds = 0;
        }
        if(this.dealProductsList[index].discount >= 100){
          this.dealProductsList[index].discount = 99;
        }
         if(this.dealProductsList[index].discount < 0){
          this.dealProductsList[index].discount = 0;
        }
    },

    check: function (column, event) {
      this.updateColumn({key: column, value: event,})
    },

    handleChange() {},
    get_selected_product(selected_product_id){
      if(selected_product_id){
        this.addToDealProductsList(selected_product_id);
      }
    },
    dealOffersCreate() {
        if(this.dealProductsList.length > 0){
            this.$emit('dealOffersCreate', {drawer: true, productList: this.dealProductsList})
        }else{
            this.$message({
                type: "warning",
                message: this.$t('message.product_list_is_empty'),
            });
        }
    },
    productRowTotal(product) {
      let rate = (this.currencies.length > 0 ? this.currencies.find(item => item.id === product.currency_id).rate : 1);
      
      let rowTotal =
        rate *
        product.price *
        product.quantity *
        (1 - product.discount / 100 + product.nds / 100);
      return Number(rowTotal.toFixed(2));
    },

    addToDealProductsList(product_id) {
      this.showProduct(product_id).then(() => {
        let item = {};
        item.id = null;
        item.deal_id = this.deal_id;
        item.product_id = product_id;
        item.price = this.getProduct.selling_price;
        item.currency_id = this.getProduct.selling_price_currency.id;
        item.product = JSON.parse(JSON.stringify(this.getProduct));
        item.quantity = 1;
        item.discount = 0;
        item.profit = 0;
        item.nds = 0;
        item.color_id = null;
        item.colors = this.getProduct.allowed_colors;
        item.package_id = null;
        item.packages = this.getProduct.allowed_packages;
        this.dealProductsList.push(item);
      });
    },

    removeFromDealProductsList(id, index) {
      if (id) {
        this.$confirm(
            this.$t('message.Are you sure you want to uninstall the product?'),
            this.$t('message.warning'),
            {
            confirmButtonText: "Да",
            cancelButtonText: "Нет",
            type: "warning",
            }
            
        )
        .then(() => {
            this.destroy(id)
              .then((res) =>{
                this.dealProductsList.splice(index, 1);
                this.$alert(res);
              })
              .catch((err) => {
                  this.$alert(err);
              })
        })
        .catch(() => {
            this.$message({
                type: "warning",
                message: this.$t('message.deleting_product_canceled'),
            });
        });
      }else{
          this.dealProductsList.splice(index, 1);
      }
    },

    submit(close = true) {
      this.$emit('listChanged');
      if (this.updateProducts) {
        this.loadingButton = true;
        this.update(this.dealProductsList)
        .then((res) => {
          this.loadingButton = false;
          this.updateList();
          this.$alert(res);
        })
        .catch((err) => {
          this.loadingButton = false;
          this.$alert(err);
        });
      } else {
        this.loadingButton = true;
        this.save(this.dealProductsList)
        .then((res) => {
          this.loadingButton = false;
          this.updateList();
          this.$alert(res);
        })
        .catch((err) => {
          this.loadingButton = false;
          this.$alert(err);
        });
      }
    },
    updateList(){
       this.loadingData = true;
        this.show(this.deal_id)
            .then((res) => {
              this.dealProductsList = JSON.parse(JSON.stringify(this.dealProducts));
              this.updateProducts = true;
              this.loadingData = false;
            })
            .catch((err) => {
            this.loadingData = false;
            });
    }

  },
};
</script>


<style>
</style>
