<template>
   <timeline ref="timeline"
              :items="items"
              class="team__timeline vis-today"
              :groups="groups"
              :options="options">
    </timeline>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
    data () {
    return {
      groups: [
        {
          id: 11,
          content: 'Research'
        },
        {
          id: 2,
          content: 'Research'
        }
      ],
      items: [
        {
          id: 11,
          group: 11,
          start: new Date(),
          content: ''
        },
        {
          id: 2,
          group: 2,
          start: 'Wed Feb 02 2022 04:42:12 GMT+0500 (Uzbekistan Standard Time)',
          end: 'Wed Feb 03 2022 04:22:12 GMT+0500 (Uzbekistan Standard Time)',
          content: '<div class="vis__content" style="transform: translateX(0px);"><div class="vis__into vis__blue1"><div class="vis__primary vis__blue" style="width:20%"></div><div class="vis__index"><div class="vis__nowrap"><div class="vis__image"><img src="https://preview.keenthemes.com/good/assets/media/avatars/300-2.jpg" alt=""></div><div class="vis__second"><img src="https://preview.keenthemes.com/good/assets/media/avatars/300-2.jpg" alt=""></div></div><a href="#" class="vis__text">Meeting</a></div><div class="vis__other">20%</div></div></div>'
        }
      ],
      options: {
        editable: false,
        width: '100%',
        height: '400px',
        margin: {
          item: 50
        },
       
        horizontalScroll: false,
        verticalScroll: true,
        zoomable: true,
        showMajorLabels:true,
      },
      activeItem: 'week'
    }
  },
  computed: {
    ...mapGetters({
      mode: "MODE",
      timeLineTasks: 'home/timeLineTasks',
      timeLineTaskGroups: 'home/timeLineTaskGroups'
    })
  },
  created(){
    this.taskTimeLine().then(res => {
      this.groups = JSON.parse(JSON.stringify(this.timeLineTaskGroups));
      this.items = JSON.parse(JSON.stringify(this.timeLineTasks)); 
      this.items = this.items.map(item => {
        return {id: item.id, group: item.group ,start: new Date(item.start), end: new Date(item.end), content: item.content};
      });
    })
  },
  methods: {
    ...mapActions({
      taskTimeLine: 'home/taskTimeLine'
    }),


  }
}
</script>

<style>
.vis__primary {
  position: absolute;
  border-radius: 30px;
  display: block;
  left: 0;
  top: 0;
  height: 100%;
  z-index: 1;
}
.vis__blue {
  background: #00b2ff;
}
.vis__blue1 {
  background: #2d3749 !important;
}
.vis__red {
  background: #e11d64 !important;
}
.vis__red1 {
  background: #452e36;
}
.vis__green {
  background: #50cd89 !important;
}
.vis__green1 {
  background: #2d3e35 !important;
}
.vis__purple {
  background: #9A68FF !important;
}
.vis__purple1 {
  background: #382f59 !important;
}

.vis__blueday {
  background: #4fc9da;
}
.vis__blue1day {
  background: #ddf8fc !important;
}
.vis__redday {
  background: #f06445 !important;
}
.vis__red1day {
  background: #fff0ed;
}
.vis__greenday {
  background: #b8d935 !important;
}
.vis__green1day {
  background: #f4fbdb !important;
}
.vis__purpleday {
  background: #4f55da !important;
}
.vis__purple1day {
  background: #b4b8f7 !important;
}

.vis__into {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 35px;
  width: 100%;
  padding: 2px;
  overflow: hidden;
  border-radius: 50rem!important;
}
.vis__into1 {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 22px;
  width: 100%;
  padding: 2px;
  overflow: hidden;
  border-radius: 13.5px !important;
}
.vis__index {
  display: flex;
  align-items: center;
  position: relative;
  z-index: 2;
}
.vis__nowrap {
  display: flex;
  align-items: center;
  margin-left: 10px;
}
.vis__image {
  display: flex;
  align-items: center;
  z-index: 2;
}
.vis__second {
  display: flex;
  align-items: center;
  z-index: 3;
}
.vis__image img {
  width: 25px;
  height: 25px;
  object-fit: cover;
  border-radius: 50%;
}
.vis__second img {
  width: 25px;
  height: 25px;
  object-fit: cover;
  border-radius: 50%;
  margin-left: -5px;
}
.vis__text {
  font-size: 13px;
  color: #fff;
  text-decoration: none;
  margin-left: 10px;
}
.vis__text1 {
  font-size: 12px;
  color: #fff;
  text-decoration: none;
  margin-left: 5px;
}
.vis__other {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  color: #fff;
  margin-right: 10px;
  font-size: 12px;
  font-weight: 600;
  height: 27px;
  padding-right: 0.75rem!important;
  padding-left: 0.75rem!important;
  border-radius: 50rem!important;
  background: #1e1e2d;
}
.vis__other1 {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  color: #92929f;
  margin-right: 5px;
  font-size: 10px;
  line-height: 8px;
  font-weight: 600;
  height: 16px;
  padding-right: 0.25rem!important;
  padding-left: 0.25rem!important;
  border-radius: 50rem!important;
  background: #1e1e2d;
}
.vis__other1day {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  color: #716d66;
  margin-right: 5px;
  font-size: 10px;
  line-height: 8px;
  font-weight: 600;
  height: 16px;
  padding-right: 0.25rem!important;
  padding-left: 0.25rem!important;
  border-radius: 50rem!important;
  background: #fff;
  transition: all 0.2s ease-out;
}
.vis__otherday {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  color: #716d66;
  margin-right: 10px;
  font-size: 12px;
  font-weight: 600;
  height: 27px;
  padding-right: 0.75rem!important;
  padding-left: 0.75rem!important;
  border-radius: 50rem!important;
  background: #fff;
  transition: all 0.2s ease-out;
}
.vis-labelset .vis-label .vis-inner {
  font-weight: 600;
}
.vis-left {
  top: 18px !important;
} 
.vis-item {
  background: inherit !important;
  border: none !important;
  color: #fff;
}
.vis-timeline {
  border: none !important;
}
.vis-panel.vis-center, .vis-panel.vis-left, .vis-panel.vis-right {
  border: none !important;
  border-left-style: none;
}
.vis-panel.vis-bottom, .vis-panel.vis-center, .vis-panel.vis-top {
  border: none !important
}
.vis-labelset .vis-label {
  border: none !important;
  color: #fff !important;
}
.vis-time-axis .vis-text {
    color: #fff !important;
}
.vis-foreground .vis-group {
  border: none !important;
}
</style>
