<template>
  <div class="deal-progress-wrap d-flex">
    <el-tooltip
      class="item"
      :class="stage.color"
      effect="dark"
      :content="stage.name"
      placement="top"
      v-for="stage in stages"
      :key="stage.id"
      
    >
      <div
        class="
          deal-progress-items
          p-relative
          mr-2
          pointer
          transition
          text-white
          font-medium
        "
        @click="changeDealStage(stage.id)"
      >
        <span class="deal-progress-text d-block text-truncate">
          {{ stage.name }}
        </span>
      </div>
    </el-tooltip>

    <el-dialog
      class="KP_modal"
      :title="$t('message.end_date')"
      :visible.sync="centerDialogVisible"
      :append-to-body="true"
      width="30%"
      center
    >
      <div>
        <el-form ref="form" :model="form">
          <el-form-item :label="$t('message.date')">
             <el-date-picker
                    class="w-100"
                    size="medium"
                    v-model="finish_date"
                    type="date"
                    :placeholder="$t('message.date')"
                    format="yyyy-MM-dd"
                    value-format="yyyy-MM-dd"
                  ></el-date-picker>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="centerDialogVisible = false">{{$t('message.cancel')}}</el-button>
          <el-button @click="submit(true)" type="primary"
          >{{$t('message.confirm')}}</el-button>
        </span>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions, createLogger } from "vuex";

export default {
  data() {
    return {
      centerDialogVisible: false,
      selectedStageId: null,
      stages: [],
      form: {},
      finish_date: new Date(),
      stage_id: null,
    };
  },
  props: {
    deal_id: {
      default: null,
    },
  },
  created: function () {
    this.allStages().then(() => {
      this.stages = JSON.parse(JSON.stringify(this.getStages));
    });
  },
  watch: {
    deal_id(val) {
      if (val.deal_stage) {
        this.addActiveToStage(val.deal_stage.id);
        this.selectedStageId = val.deal_stage.id;
      }
      if (val.deal_stage_id) {
        this.addActiveToStage(val.deal_stage_id);
        this.selectedStageId = val.deal_stage_id;
      }
    },
  },
  computed: {
    ...mapGetters({
      getStages: "dealStages/list",
    }),
  },

  methods: {
    ...mapActions({
      allStages: "dealStages/index",
      updateDealStage: "deals/changeDealStage"
    }),
    addActiveToStage(val) {
      this.stages.forEach((element) => {
        if (val && element.id <= val) {
          element.color = "action5";
        } else {
          element.color = "action1";
        }
      });
    },
    changeDealStage(stage_id){
      if(this.deal_id.id && stage_id){
        if(this.stages[this.stages.length-1]['id'] == stage_id){
          this.centerDialogVisible = true;
          this.stage_id = stage_id;
        }else{
          this.$confirm(
                this.$t('message.do_you_really_want_to_do_this'),
                this.$t('message.warning'), {
                  confirmButtonText: this.$t('message.yes'),
                  cancelButtonText: this.$t('message.no'),
                  type: "warning"
                }
              )
              .then(() => {
                this.updateDealStage({deal_id: this.deal_id.id, stage_id: stage_id})
                .then(() => {
                    this.$emit('stageChanged', this.deal_id.id);
                });
              })
              .catch(() => {
                this.$message({
                  type: "warning",
                  message: this.$t('message.operation_canceled')
                });
              });
        }
        
      } 
    },

    submit() {
        this.updateDealStage({deal_id: this.deal_id.id, stage_id: this.stage_id, finish_date: this.finish_date})
            .then(() => {
                this.centerDialogVisible = false;
                this.$emit('stageChanged', this.deal_id.id);
            });
    }

  },
};
</script>

