<template>
  <div>
    <el-form-item
      :label="$t('message.company')"
      class="label_mini"
    >
      <el-col :span="16">
        <el-select
          :value="selected"
          @input="dispatch"
          :placeholder="placeholder || $t('message.company')"
          filterable
          clearable
          :size="size"
          class="w-100"
        >
          <el-option
            v-for="(company, index) in companies"
            :key="'companies-' + index"
            :label="company.name"
            :value="company.id"
          ></el-option>
        </el-select>
      </el-col>
      <el-col :span="4">
        <i
          class="el-icon-plus cursor-pointer ml-2"
          size="small"
          @click="showModal('create_company')"
        >
        </i>
      </el-col>

      <create @crm-close="afterCreated"></create>
    </el-form-item>
  </div>
</template>
<script>
import create from "@/components/createModalsForSelects/company-create-modal";
import { mapGetters, mapActions } from "vuex";
export default {
  components: { create },
  props: {
    size: {
      default: "small",
    },
    placeholder: {
      default: null,
    },
    id: {
      default: null,
    },
  },
  watch: {
    id: {
      handler: function () {
        this.selected = this.id;
      },
      immediate: true,
    },
  },
  data() {
    return {
      selected: null,
    };
  },
  mounted() {
    if (this.companies && this.companies.length === 0) this.updateInventory();
  },
  computed: {
    ...mapGetters({
      companies: "company/inventory",
    }),
  },
  methods: {
    ...mapActions({
      updateInventory: "company/inventory",
    }),
    showModal(modal) {
      this.$modal.show(modal);
    },
    afterCreated(data) {
      if (data.created && data.created === true) {
        this.dispatch(data.company.id);
      }
    },
    dispatch(e) {
      this.$emit("input", e);
      this.selected = e;
    },
  },
};
</script>
