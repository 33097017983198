export const filter = {
    id: null,
    name: '',
    mfo: '',
    okonx:'',
    oked:'',
    rkp_nds: '',
    work_phone:'',
    work_place: '',
    legal_address: '',
    landmark:'',
    residence_place:'',
    comment:'',
    country_id: null,
    city_id: null,
    created_at: '',
    updated_at: '', 
};
