import {i18n} from '@/utils/i18n';

export const rules = {
    full_name: [
        { required: true, message: i18n.t('message.please_enter_input', {input: i18n.t('message.full_name')}), trigger: 'change' },
    ],
    type_id: [
        { required: true, message: i18n.t('message.please_enter_input', {input: i18n.t('message.type')}), trigger: 'change' },
    ],
    phone: [
        { required: true, message: i18n.t('message.please_enter_input', {input: i18n.t('message.phone')}), trigger: 'change' },
    ],
};
