<template>
    <modal :name="name" width="80%" height="auto" @before-open="beforeOpen" :draggable="true">
        <div class="el-dialog__header cursor-move">
            <span class="el-dialog__title">{{ $t('message.new') }} {{ $t('message.company') | lowerFirst }}</span>
            <button type="button" class="el-dialog__headerbtn" @click="closeModal(name)">
                <i class="el-dialog__close el-icon el-icon-close"></i>
            </button>
        </div>
        <div class="el-dialog__body" style="background-color: white">
             <el-form ref="form" status-icon :model="form" :rules="rules" label-position="right" label-width="180px" size="small">
                 <el-row>
                    <el-col :span="12">
                        <el-form-item :label="columns.name.title" prop="name">
                            <el-input v-model="form.name" autocomplete="off"></el-input>
                        </el-form-item>
                        <el-form-item :label="columns.mfo.title">
                            <el-input v-model="form.mfo" autocomplete="off"></el-input>
                        </el-form-item>
                        <el-form-item :label="columns.okonx.title">
                            <el-input v-model="form.okonx" autocomplete="off"></el-input>
                        </el-form-item>
                        <el-form-item :label="columns.oked.title">
                            <el-input v-model="form.oked" autocomplete="off"></el-input>
                        </el-form-item>
                        <el-form-item :label="columns.rkp_nds.title">
                            <el-input v-model="form.rkp_nds" autocomplete="off"></el-input>
                        </el-form-item>
                        <el-form-item :label="columns.work_phone.title" prop="work_phone">
                            <el-input v-model="form.work_phone" autocomplete="off"></el-input>
                        </el-form-item>
                        <el-form-item :label="columns.country_id.title">
                            <select-country
                                :size="'medium'"
                                :placeholder="columns.country_id.title"
                                :id="form.country_id"
                                v-model="form.country_id"
                            >
                            </select-country>
                        </el-form-item>

                        <el-form-item :label="columns.city_id.title">
                            <select-city
                                :size="'medium'"
                                :placeholder="columns.city_id.title"
                                :id="form.city_id"
                                :country_id="form.country_id"
                                v-model="form.city_id"
                            >
                            </select-city>
                        </el-form-item>
                        
                    </el-col>
                    <el-col :span="12">
                        <el-form-item :label="columns.work_place.title">
                            <el-input type="textarea" v-model="form.work_place" autocomplete="off"></el-input>
                        </el-form-item>
                        <el-form-item :label="columns.legal_address.title">
                            <el-input type="textarea" v-model="form.legal_address" autocomplete="off"></el-input>
                        </el-form-item>
                        <el-form-item :label="columns.landmark.title">
                            <el-input type="textarea" v-model="form.landmark" autocomplete="off"></el-input>
                        </el-form-item>
                        <el-form-item :label="columns.residence_place.title">
                            <el-input type="textarea" v-model="form.residence_place" autocomplete="off"></el-input>
                        </el-form-item>
                        <el-form-item :label="columns.comment.title">
                            <el-input type="textarea" v-model="form.comment" autocomplete="off"></el-input>
                        </el-form-item>
                        
                    </el-col>
                 </el-row>
            </el-form>
        </div>
        <div class="el-dialog__footer">
            <span class="dialog-footer">
                <el-button @click="closeModal(name)" round size="mini" :loading="waiting">{{ $t('message.cancel') }}</el-button>
                <el-button type="primary" @click="submit" round size="mini" :loading="waiting">{{ $t('message.save') }}</el-button>
            </span>
        </div>
    </modal>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import drawer from "@/utils/mixins/drawer";
import form from "@/utils/mixins/form";
import selectCountry from "@/components/inventory/select-country";
import selectCity from "@/components/inventory/select-city";

export default {
  mixins: [form, drawer],
  components: {
    selectCountry,
    selectCity,
  },
   data(){
        return {
            name: 'create_company',
            form: {},
            waiting: false,
        }
    },
    computed: {
        ...mapGetters({
            rules: 'company/rules',
            model: 'company/model',
            columns: 'company/columns',
            types: 'company/types'
        })
    },
    methods:{
        ...mapActions({
            save: 'company/store',
            updateListInventory: 'company/inventory',
        }),
        beforeOpen(){
            this.form = JSON.parse( JSON.stringify( this.model ));
        },
        closeModal(modalName) {
            this.$modal.hide(modalName);
        },
        changeWaiting(val = false) {
            if (val === false) {
                setTimeout(() => {
                    this.waiting = false;
                }, 500);
            } else {
                this.waiting = true;
            }
        },
        submit(){
            if (!this.waiting) {
                this.$refs['form'].validate((valid) => {
                    if (valid) {
                        this.changeWaiting(true);
                        this.save(this.form)
                            .then(async (res) => {
                                await this.updateListInventory();
                                this.$emit('crm-close', {created: true, company: res.data.result.data});
                                this.$alert(res);
                                this.changeWaiting();
                                this.closeModal(this.name);
                            })
                            .catch(err => {
                                this.changeWaiting();
                                this.$alert(err);
                            })
                    }
                });
            }
        }
    }
};
</script>