<template>
  <div>
    <el-form-item
      :label="$t('message.client')"
      class="label_mini"
      prop="client_id"
    >
    <el-col :span="18">
      <el-select
        :value="selected"
        @input="dispatch"
        :placeholder="$t('message.client_with_search')"
        filterable
        clearable
        :size="size"
        class="w-100"
        :filter-method="filterByType"
      >
        <el-option
          v-for="(client, index) in getPersonClients"
          :key="'clients-' + index"
          :label="client.full_name"
          :value="client.id"
        ></el-option>
      </el-select>
    </el-col>

    <el-col :span="4">
      <i
        class="el-icon-plus cursor-pointer ml-2"
        size="small"
        @click="showModal('create_client')"
      >
      </i>
    </el-col>

    <create @crm-close="afterCreated"></create>

    </el-form-item>
  </div>
</template>
<script>
import create from "@/components/createModalsForSelects/company-client-create-modal";

import { mapGetters, mapActions } from "vuex";
export default {
  components: { create },
  props: {
    size: {
      default: "small",
    },
    placeholder: {
      default: null,
    },
    id: {
      default: null,
    },
    company_id: {
      default: null,
    },
    model: {
      default: null,
    },
  },
  watch: {
    company_id: {
      handler: function () {
        if (this.company_id) {
          this.companyClients(this.company_id).then((res) => {
            this.clients = this.getCompanyClients;
            let self = this;
            if (this.selected && this.model && this.company_id != this.model.company_id) {
              this.$emit("input", null);
            }
          });
        } else {
          this.personClients().then((res) => {
            this.clients = this.getPersonClients;
            if (this.selected && this.selected != this.model.client_id) {
              this.$emit("input", null);
            }
          });
        }
      },
      immediate: true,
    },
    id: {
      handler: function () {
        this.selected = this.id;
      },
      immediate: true,
    },
  },
  data() {
    return {
      selected: null,
      clients: [],
    };
  },
  computed: {
    ...mapGetters({
      getCompanyClients: "clients/companyClients",
      getPersonClients: "clients/personClients",
    }),
  },
  methods: {
    ...mapActions({
      companyClients: "clients/companyClients",
      personClients: "clients/personClients",
      filteredClients: "clients/filterClientsByType",
    }),
    showModal(modal) {
      this.$modal.show(modal);
    },
    afterCreated(data) {
      if(this.company_id){
        this.companyClients(this.company_id)
      }else{
        this.personClients()
      }
    },
    dispatch(e) {
      this.$emit("input", e);
      this.selected = e;
    },
    filterByType(filterValue){
        let company = null;
        if(this.company_id){
          company = this.company_id
        }

        let filter = null;
        if(filterValue){
          filter = filterValue
        }
        let data = {typeName: filter, company_id: company};
        this.debouncedFilter(data, this);

    },

    debouncedFilter: _.debounce((data, self) => {
      if (_.isFunction(self.filteredClients)) {
        self.filteredClients(data)
          .then((res) => {
             self.clients = self.getPersonClients;
          })
          .catch((err) => {});
      }
    }, 1000),
  },
};
</script>
