<template>
  <div class="product-add">
    <div class="product-add-table mt-5">
      <table class="table-my-code table-bordered borde-l-r">
        <thead>
          <tr>
            <th class="w15">{{ $t("message.n") }}</th>
            <th class="w15">{{ $t("message.task") }}</th>
            <th class="w15">{{ $t("message.begin_date") }}</th>
            <th class="w15">{{ $t("message.end_date") }}</th>
            <th class="w15">{{ $t("message.user") }}</th>
            <th class="w15">{{ $t("message.update") }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="task in dealTasks" :key="task.id">
            <th class="w15">{{ task.id }}</th>
            <th class="w15"> {{ task.name }} </th>
            <th class="w15">{{ task.begin_date }}</th>
            <th class="w15">{{ task.end_date }}</th>
            <th class="w15">{{ task.username ? task.username : '' }}</th>
            <th class="w15"><i class="el-icon-edit-outline mr-2" style="font-size: 20px" @click="updateTask(task)"></i></th>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="app-modal app-modal__full">
      <el-drawer 
        class="bg-se"
        :visible.sync="drawerTaskCreate"
        :with-header="false"
        ref="drawerTaskCreate"
        :append-to-body="true"
        @opened="drawerOpened('drawerTaskCreateChild')"
        @closed="drawerClosed('drawerTaskCreateChild')" 
        size="80%">
        <UpdateTask
          :selectedItem="selectedItem"
          ref="drawerTaskCreateChild"
          drawer="drawerTaskCreate"
        />
      </el-drawer>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import _ from 'lodash';
import UpdateTask from "@/views/tasks/components/add-modal-update";

export default {
  name: "dealOffer",
  props: {
    deal_id: {
      default: null,
    },
  },
  components: {
    UpdateTask
  },
  data() {
    return {
      drawerTaskCreate: false,
      selectedItem: {},
      reloadList: false,
    };
  },
  watch: {
    deal_id:{
        handler: function(val) {
          if(val){
            this.updateDealTasks(val)
          }
           
        },
        immediate: true
      }
  },
  computed: {
    ...mapGetters({
        dealTasks: "deals/dealTasks",
    }),
  },
  methods: {
    ...mapActions({
        updateDealTasks: "deals/dealTasks",
    }),
    updateTask(task){
      this.selectedItem = task;
      this.drawerTaskCreate = true
    },
    closeDrawer(drawer) {       
        if (this.$refs[drawer] && _.isFunction(this.$refs[drawer].closeDrawer)) {
            this.$refs[drawer].closeDrawer();
        }
    },
    listChanged() {
        this.reloadList = true;
    },
    drawerClosed(ref) {
        if (this.$refs[ref]) {
            this.$refs[ref].closed()
        }
        if (this.reloadList === true) {
          this.updateDealTasks(this.deal_id)
            .then(res => {
              this.reloadList = false
            })
        }
        if (_.isFunction(this.empty)) {
            this.empty()
        }
    },
    drawerOpened(ref) {        
        if (this.$refs[ref]) {
            if (_.isFunction(this.$refs[ref].opened)) {
                this.$refs[ref].opened()
            }
        }
    },
  },
};
</script>



<style>
</style>
