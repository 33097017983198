import { columns } from './properties/columns'
import { filter } from './properties/filter'
import { pagination } from "@/store/modules/properties/pagination";
import { sort } from "@/store/modules/properties/sort";
import { rules } from "./properties/rules";
import { model } from "./properties/model";

export const state = {
    list: [],
    archivedDeals: [],
    deletedDeals: [],
    inventory: [],
    model: JSON.parse(JSON.stringify(model)),
    columns: columns,
    filter: filter,
    pagination: JSON.parse(JSON.stringify(pagination)),
    sort: JSON.parse(JSON.stringify(sort)),   
    rules: rules,   
    form: [],
    dealStages: [],
    deletedDealsForStage: [],
    dealTasks: [],
    dealsForCalendar: [],
    phone_number_deals: [],
};
