<template>
    <div class="create-deal-modal" v-loading="loadingData">
        <div class="app-modal__box">
            <div class="app-modal__header p-5">
                <p class="large--title m-0">{{ selectedDealForUpdate.name }}</p>
            </div>

            <div class="app-modal__body p-5">
                <deal-stages
                    @stageChanged="dealStageChanged"
                    :stage_id="stage_id"
                    :deal_id="selectedDealForUpdate"
                    ref="dealStages"
                ></deal-stages>

                <div class="deal-tab-wrap">
                    <el-tabs v-model="activeName">
                        <el-tab-pane :label="$t('message.overall')" name="first">
                            <div class="wrap-all d-flex f-between f-wrap">
                                <div class="wrap-all__left">
                                    <div
                                        class="
                                            gc-card
                                            rounded-sm
                                            mb-4
                                            p-relative
                                        "
                                    >
                                        <div
                                            class="
                                                gc-card__header
                                                d-flex
                                                align-center
                                                px-3
                                                py-4
                                            "
                                        >
                                            <i
                                                class="el-icon-document mr-2"
                                            ></i>
                                            <div class="d-flex w-100">
                                                <span class="font-bold">
                                                    {{ $t('message.about_deal') }}
                                                </span>

                                                <div
                                                    class="
                                                        change-form
                                                        ml-auto
                                                        pointer
                                                    "
                                                    @click="
                                                        drawerUpdateDeal = true
                                                    "
                                                >
                                                    {{ $t("message.update") }}
                                                </div>
                                            </div>
                                        </div>

                                        <div class="gc-card__body px-3 py-2">
                                            <div class="text-descr">
                                                <span
                                                    class="
                                                        text-descr__label
                                                        d-block
                                                    "
                                                    >{{
                                                        $t("message.filial")
                                                    }}</span
                                                >
                                                <div
                                                    class="
                                                        d-flex
                                                        align-center
                                                        font-medium
                                                    "
                                                >
                                                    {{
                                                        selectedDealForUpdate.filial
                                                            ? selectedDealForUpdate
                                                                  .filial.name
                                                            : ""
                                                    }}
                                                </div>
                                            </div>
                                        </div>

                                        <div class="gc-card__body px-3 py-2">
                                            <div class="text-descr">
                                                <span
                                                    class="
                                                        text-descr__label
                                                        d-block
                                                    "
                                                    >{{
                                                        $t(
                                                            "message.money_amount"
                                                        )
                                                    }}</span
                                                >
                                                <div
                                                    class="
                                                        d-flex
                                                        align-center
                                                        font-medium
                                                    "
                                                >
                                                    {{
                                                        selectedDealForUpdate.money_amount
                                                            | formatNumber(1)
                                                    }}
                                                    {{
                                                        selectedDealForUpdate.currency
                                                            ? selectedDealForUpdate
                                                                  .currency.code
                                                            : ""
                                                    }}
                                                </div>
                                            </div>
                                        </div>

                                        <div class="gc-card__body px-3 py-2">
                                            <div class="text-descr">
                                                <span
                                                    class="
                                                        text-descr__label
                                                        d-block
                                                    "
                                                    >{{
                                                        $t("message.profit")
                                                    }}</span
                                                >
                                                <div
                                                    class="
                                                        d-flex
                                                        align-center
                                                        font-medium
                                                    "
                                                >
                                                    {{
                                                        ((selectedDealForUpdate.profit *
                                                            selectedDealForUpdate.money_amount) /
                                                            100)
                                                            | formatNumber(1)
                                                    }}
                                                    {{
                                                        selectedDealForUpdate.currency
                                                            ? selectedDealForUpdate
                                                                  .currency.code
                                                            : ""
                                                    }}
                                                </div>
                                            </div>
                                        </div>

                                        <div class="gc-card__body px-3 py-2">
                                            <div class="text-descr">
                                                <span
                                                    class="
                                                        text-descr__label
                                                        pr-5
                                                        mr-5
                                                    "
                                                    >{{
                                                        $t("message.begin_date")
                                                    }}</span
                                                >
                                                <span
                                                    class="
                                                        text-descr__label
                                                        pl-5
                                                        ml-3
                                                    "
                                                    >{{
                                                        $t("message.end_date")
                                                    }}</span
                                                >
                                                <div
                                                    class="
                                                        d-flex
                                                        align-center
                                                        font-medium
                                                    "
                                                >
                                                    <span class="pr-5 mr-5">
                                                        {{
                                                            formatDate(
                                                                selectedDealForUpdate.begin_date
                                                            )
                                                        }}
                                                    </span>
                                                    <span class="pl-5 ml-5">
                                                        {{
                                                            formatDate(
                                                                selectedDealForUpdate.end_date
                                                            )
                                                        }}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="gc-card__body px-3 py-2">
                                            <div class="text-descr">
                                                <span
                                                    class="
                                                        text-descr__label
                                                        d-block
                                                    "
                                                    >{{
                                                        $t("message.company")
                                                    }}</span
                                                >
                                                <div
                                                    class="
                                                        d-flex
                                                        align-center
                                                        font-medium
                                                    "
                                                >
                                                    {{
                                                        selectedDealForUpdate.company
                                                            ? selectedDealForUpdate
                                                                  .company.name
                                                            : ""
                                                    }}
                                                </div>
                                            </div>
                                        </div>

                                        <!-- <div class="gc-card__body px-3 py-2">
                      <div class="text-descr">
                        <span class="text-descr__label d-block">{{
                          $t("message.client")
                        }}</span>
                        <div class="d-flex align-center font-medium">
                          {{
                            selectedDealForUpdate.client
                              ? selectedDealForUpdate.client.full_name
                              : ""
                          }}
                        </div>
                      </div>
                    </div> -->
                                        <div class="gc-card__body px-3 py-2">
                                            <div class="text-descr">
                                                <span
                                                    class="
                                                        text-descr__label
                                                        d-block
                                                    "
                                                    >{{
                                                        $t("message.client")
                                                    }}</span
                                                >
                                                <div
                                                    class="
                                                        d-flex
                                                        align-center
                                                        font-medium
                                                    "
                                                >
                                                    <a
                                                        class="
                                                            text-descr__phone
                                                            f-fill
                                                            text-reset
                                                        "
                                                    >
                                                        {{
                                                            selectedDealForUpdate.client
                                                                ? selectedDealForUpdate
                                                                      .client
                                                                      .full_name
                                                                : ""
                                                        }}
                                                    </a>
                                                    <i
                                                        class="
                                                            el-icon-edit-outline
                                                        "
                                                        @click="
                                                            drawerUpdateClient = true
                                                        "
                                                        style="font-size: 1.5em"
                                                    ></i>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="gc-card__body px-3 py-2">
                                            <div class="text-descr">
                                                <span
                                                    class="
                                                        text-descr__label
                                                        d-block
                                                    "
                                                    >{{
                                                        $t("message.country")
                                                    }}</span
                                                >
                                                <div
                                                    class="
                                                        d-flex
                                                        align-center
                                                        font-medium
                                                    "
                                                >
                                                    {{
                                                        selectedDealForUpdate.client
                                                            ? selectedDealForUpdate
                                                                  .client
                                                                  .country
                                                                ? selectedDealForUpdate
                                                                      .client
                                                                      .country
                                                                      .name
                                                                : ""
                                                            : ""
                                                    }}
                                                </div>
                                            </div>
                                        </div>

                                        <div class="gc-card__body px-3 py-2">
                                            <div class="text-descr">
                                                <span
                                                    class="
                                                        text-descr__label
                                                        d-block
                                                    "
                                                    >{{
                                                        $t("message.phone")
                                                    }}</span
                                                >
                                                <div
                                                    class="
                                                        d-flex
                                                        align-center
                                                        font-medium
                                                    "
                                                >
                                                    <a
                                                        :href="'tell:'"
                                                        class="
                                                            text-descr__phone
                                                            f-fill
                                                            text-reset
                                                        "
                                                    >
                                                        {{
                                                            selectedDealForUpdate.phone
                                                        }}
                                                    </a>
                                                    <span
                                                        class="
                                                            phone-detect
                                                            pl-2
                                                        "
                                                    >
                                                        {{ $t("message.working") }}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="gc-card__body px-3 py-2">
                                            <div class="text-descr mb-3">
                                                <span
                                                    class="
                                                        text-descr__label
                                                        d-block
                                                    "
                                                    >{{
                                                        $t("message.email")
                                                    }}</span
                                                >
                                                <div
                                                    class="
                                                        d-flex
                                                        align-center
                                                        font-medium
                                                    "
                                                >
                                                    {{
                                                        selectedDealForUpdate.email
                                                    }}
                                                </div>
                                            </div>
                                        </div>

                                        <div class="gc-card__body px-3 py-2">
                                            <div class="text-descr mb-3">
                                                <span
                                                    class="
                                                        text-descr__label
                                                        d-block
                                                    "
                                                    >{{
                                                        $t(
                                                            "message.shipmentTerm"
                                                        )
                                                    }}</span
                                                >
                                                <div
                                                    class="
                                                        d-flex
                                                        align-center
                                                        font-medium
                                                    "
                                                >
                                                    {{
                                                        selectedDealForUpdate.shipmentTerm
                                                            ? selectedDealForUpdate
                                                                  .shipmentTerm
                                                                  .name
                                                            : ""
                                                    }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div
                                        class="gc-card rounded-sm mb-4"
                                        v-if="
                                            selectedDealForUpdate.deal_payments &&
                                            selectedDealForUpdate.deal_payments
                                                .length > 0
                                        "
                                    >
                                        <div
                                            class="
                                                gc-card__header
                                                d-flex
                                                align-center
                                                px-3
                                                py-4
                                            "
                                        >
                                            <i class="el-icon-files mr-2"></i>
                                            <div class="d-flex w-100">
                                                <span class="font-bold">
                                                    {{ $t("message.dealConditions") }}
                                                </span>
                                                <div
                                                    class="
                                                        change-form
                                                        ml-auto
                                                        pointer
                                                    "
                                                >
                                                    <!-- {{ $t("message.update") }} -->
                                                </div>
                                            </div>
                                        </div>
                                        <div class="gc-card__body px-3 py-4">
                                            <div
                                                class="gc-card__body px-3 py-2"
                                            >
                                                <div class="text-descr mb-3">
                                                    <el-progress
                                                        style="len"
                                                        class="mt-3"
                                                        :stroke-width="13"
                                                        :percentage="
                                                            totalPaymentPercentage
                                                        "
                                                        :color="customColors"
                                                        v-if="
                                                            selectedDealForUpdate.deal_payments &&
                                                            selectedDealForUpdate
                                                                .deal_payments
                                                                .length > 0
                                                        "
                                                    ></el-progress>
                                                </div>
                                            </div>

                                            <hr
                                                v-if="
                                                    selectedDealForUpdate.deal_payments &&
                                                    selectedDealForUpdate
                                                        .deal_payments.length >
                                                        0
                                                "
                                            />

                                            <div
                                                v-for="payment in selectedDealForUpdate.deal_payments"
                                                :key="payment.id"
                                            >
                                                <div class="gc-card__body mb-4">
                                                    <span
                                                        class="
                                                            text-descr__label
                                                            d-block
                                                        "
                                                        >{{
                                                            $t(
                                                                "message.dealCondition"
                                                            )
                                                        }}</span
                                                    >
                                                    <div
                                                        class="
                                                            d-flex
                                                            align-center
                                                            font-medium
                                                        "
                                                    >
                                                        {{
                                                            payment.payment_condition
                                                                ? payment
                                                                      .payment_condition
                                                                      .name
                                                                : ""
                                                        }}
                                                    </div>
                                                </div>

                                                <div class="text-descr d-flex">
                                                    <div class="mmq">
                                                        <span
                                                            class="
                                                                text-descr__label
                                                                pr-5
                                                                mr-5
                                                            "
                                                        >
                                                            {{
                                                                $t(
                                                                    "message.payment_in_money"
                                                                )
                                                            }}
                                                        </span>
                                                        <span>
                                                            {{
                                                                ((payment.percentage *
                                                                    selectedDealForUpdate.money_amount) /
                                                                    100)
                                                                    | formatNumber(
                                                                        1
                                                                    )
                                                            }}
                                                            {{
                                                                selectedDealForUpdate.currency
                                                                    ? selectedDealForUpdate
                                                                          .currency
                                                                          .code
                                                                    : ""
                                                            }}
                                                        </span>
                                                    </div>
                                                    <div class="mmq">
                                                        <span
                                                            class="
                                                                text-descr__label
                                                            "
                                                        >
                                                            {{
                                                                $t(
                                                                    "message.payment_in_percents"
                                                                )
                                                            }}
                                                        </span>
                                                        <span>
                                                            {{
                                                                payment.percentage
                                                            }}
                                                            %
                                                        </span>
                                                    </div>
                                                </div>

                                                <div class="text-descr d-flex">
                                                    <div class="mmq">
                                                        <span
                                                            class="
                                                                text-descr__label
                                                                pr-5
                                                                mr-5
                                                            "
                                                        >
                                                            {{ $t("message.payment_start_date") }}
                                                        </span>
                                                        <span>
                                                            {{
                                                                formatDate(
                                                                    payment.begin_date
                                                                )
                                                            }}
                                                        </span>
                                                    </div>
                                                    <div class="mmq">
                                                        <span
                                                            class="
                                                                text-descr__label
                                                            "
                                                        >
                                                            {{ $t("message.payment_end_date") }}
                                                        </span>
                                                        <span>
                                                            {{
                                                                formatDate(
                                                                    payment.end_date
                                                                )
                                                            }}
                                                        </span>
                                                    </div>
                                                </div>

                                                <div class="gc-card__body mb-4">
                                                    <div
                                                        class="text-descr mb-3"
                                                    >
                                                        <span
                                                            class="
                                                                text-descr__label
                                                                d-block
                                                            "
                                                        >
                                                            {{
                                                                $t(
                                                                    "message.comment"
                                                                )
                                                            }}
                                                        </span>
                                                        <div
                                                            class="
                                                                d-flex
                                                                align-center
                                                                font-medium
                                                            "
                                                        >
                                                            {{
                                                                payment.comment
                                                            }}
                                                        </div>
                                                    </div>
                                                </div>

                                                <hr />
                                            </div>
                                        </div>
                                    </div>

                                    <div class="gc-card rounded-sm mb-4">
                                        <div
                                            class="
                                                gc-card__header
                                                d-flex
                                                align-center
                                                px-3
                                                py-4
                                            "
                                        >
                                            <i class="el-icon-files mr-2"></i>
                                            <div class="d-flex w-100">
                                                <span class="font-bold">
                                                    {{
                                                        $t("message.additional")
                                                    }}
                                                </span>
                                                <div
                                                    class="
                                                        change-form
                                                        ml-auto
                                                        pointer
                                                    "
                                                >
                                                </div>
                                            </div>
                                        </div>
                                        <div class="gc-card__body px-3 py-4">
                                            <div class="text-descr">
                                                <span
                                                    class="
                                                        text-descr__label
                                                        d-block
                                                    "
                                                >
                                                    {{
                                                        $t("message.deal_type")
                                                    }}
                                                </span>
                                                <div
                                                    class="
                                                        d-flex
                                                        align-center
                                                        font-medium
                                                    "
                                                >
                                                    <span
                                                        class="
                                                            text-descr__text
                                                            f-fill
                                                            text-reset
                                                        "
                                                    >
                                                        {{
                                                            selectedDealForUpdate.deal_type
                                                                ? selectedDealForUpdate
                                                                      .deal_type
                                                                      .name
                                                                : ""
                                                        }}
                                                    </span>
                                                </div>
                                            </div>
                                            <div class="text-descr">
                                                <span
                                                    class="
                                                        text-descr__label
                                                        d-block
                                                    "
                                                >
                                                    {{ $t("message.source") }}
                                                </span>
                                                <div
                                                    class="
                                                        d-flex
                                                        align-center
                                                        font-medium
                                                    "
                                                >
                                                    <span
                                                        class="
                                                            text-descr__text
                                                            f-fill
                                                            text-reset
                                                        "
                                                        >{{
                                                            selectedDealForUpdate.source
                                                                ? selectedDealForUpdate
                                                                      .source
                                                                      .name
                                                                : ""
                                                        }}</span
                                                    >
                                                </div>
                                            </div>

                                            <div class="text-descr">
                                                <span
                                                    class="
                                                        text-descr__label
                                                        d-block
                                                    "
                                                >
                                                    {{ $t("message.reception_address") }}
                                                </span>
                                                <div
                                                    class="
                                                        d-flex
                                                        align-center
                                                        font-medium
                                                    "
                                                >
                                                    <span
                                                        class="
                                                            text-descr__text
                                                            f-fill
                                                            text-reset
                                                        "
                                                        >{{
                                                            selectedDealForUpdate.reception_address
                                                        }}</span
                                                    >
                                                </div>
                                            </div>

                                            <div class="text-descr">
                                                <span
                                                    class="
                                                        text-descr__label
                                                        d-block
                                                    "
                                                >
                                                    {{ $t("message.comment") }}
                                                </span>
                                                <div
                                                    class="
                                                        d-flex
                                                        align-center
                                                        font-medium
                                                    "
                                                >
                                                    <span
                                                        class="
                                                            text-descr__text
                                                            f-fill
                                                            text-reset
                                                        "
                                                        >{{
                                                            selectedDealForUpdate.comment
                                                        }}</span
                                                    >
                                                </div>
                                            </div>

                                            <div class="text-descr">
                                                <span
                                                    class="
                                                        text-descr__label
                                                        d-block
                                                    "
                                                >
                                                    {{ $t("message.files") }}
                                                </span>
                                                <div
                                                    @click="
                                                        downloadFile(
                                                            selectedDealForUpdate.id,
                                                            file.name
                                                        )
                                                    "
                                                    class="
                                                        d-flex
                                                        align-center
                                                        mir
                                                        font-medium
                                                        pb-2
                                                    "
                                                    v-for="file in selectedDealForUpdate.files"
                                                    :key="file.name"
                                                >
                                                    <span
                                                        class="
                                                            text-descr__text
                                                            text-reset
                                                        "
                                                    >
                                                        <i
                                                            class="
                                                                el-icon-document-checked
                                                            "
                                                        ></i>
                                                        {{
                                                            file
                                                                ? file.name
                                                                : ""
                                                        }}
                                                    </span>
                                                    <span
                                                        class="
                                                            text-descr__text
                                                            text-reset
                                                        "
                                                    >
                                                        <i
                                                            class="
                                                                el-icon-download
                                                            "
                                                        ></i>
                                                    </span>
                                                </div>
                                            </div>

                                            <div class="text-descr">
                                                <span
                                                    class="
                                                        text-descr__label
                                                        d-block
                                                    "
                                                >
                                                    {{
                                                        $t(
                                                            "message.responsible"
                                                        )
                                                    }}
                                                </span>
                                                <div
                                                    class="
                                                        responsible-block
                                                        d-flex
                                                        align-center
                                                        font-medium
                                                        p-2
                                                        rounded-sm
                                                    "
                                                >
                                                    <div
                                                        class="
                                                            gc-small-block
                                                            rounded-circle
                                                            hidden
                                                            mr-3
                                                        "
                                                        style="
                                                            border: 1px solid
                                                                gray;
                                                        "
                                                    >
                                                        <img
                                                            :src="
                                                                selectedDealForUpdate.user
                                                                    ? selectedDealForUpdate
                                                                          .user
                                                                          .avatar
                                                                        ? selectedDealForUpdate
                                                                              .user
                                                                              .avatar
                                                                        : '/img/default-user-icon.png'
                                                                    : '/img/default-user-icon.png'
                                                            "
                                                            alt="Default Avatar"
                                                            class="d-block"
                                                        />
                                                    </div>
                                                    <div class="f-fill">
                                                        <span
                                                            class="
                                                                text-descr__text
                                                                f-fill
                                                            "
                                                        >
                                                            {{
                                                                selectedDealForUpdate.user
                                                                    ? selectedDealForUpdate
                                                                          .user
                                                                          .name
                                                                    : ""
                                                            }}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="text-descr">
                                                <span
                                                    class="
                                                        text-descr__label
                                                        d-block
                                                    "
                                                >
                                                    {{
                                                        $t("message.spectators")
                                                    }}
                                                </span>
                                                <div
                                                    v-for="spectator in selectedDealForUpdate.spectators"
                                                    :key="spectator.id"
                                                    class="
                                                        responsible-block
                                                        d-flex
                                                        align-center
                                                        font-medium
                                                        p-2
                                                        rounded-sm
                                                    "
                                                >
                                                    <div
                                                        class="
                                                            gc-small-block
                                                            rounded-circle
                                                            hidden
                                                            mr-3
                                                        "
                                                        style="
                                                            border: 1px solid
                                                                gray;
                                                        "
                                                    >
                                                        <img
                                                            :src="
                                                                spectator
                                                                    ? spectator.avatar
                                                                        ? spectator.avatar
                                                                        : '/img/default-user-icon.png'
                                                                    : '/img/default-user-icon.png'
                                                            "
                                                            alt="Default Avatar"
                                                            class="d-block"
                                                        />
                                                    </div>
                                                    <div class="f-fill mirdel">
                                                        <span
                                                            class="
                                                                text-descr__text
                                                                f-fill
                                                            "
                                                        >
                                                            {{
                                                                spectator
                                                                    ? spectator.name
                                                                    : ""
                                                            }}
                                                        </span>
                                                        <span
                                                            class="
                                                                text-descr__text
                                                                text-reset
                                                            "
                                                            @click="
                                                                deletingSpectator(
                                                                    spectator
                                                                        ? spectator.id
                                                                        : ''
                                                                )
                                                            "
                                                        >
                                                            <i
                                                                class="
                                                                    el-icon-delete
                                                                "
                                                                style="
                                                                    color: red;
                                                                "
                                                            ></i>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="wrap-all__right">
                                    <deal-correspondences
                                        v-can="'dealCorrespondences.index'"
                                        ref="dealCorrespondence"
                                        @listChanged="listChanged"
                                        :deal_id="selectedDealForUpdate.id"
                                        :deal_email="selectedDealForUpdate.email"
                                    ></deal-correspondences>
                                </div>
                            </div>

                            <div class="mt-5">
                                <el-row class="mt-5">
                                    <el-button
                                        v-if="
                                            permissions.some(
                                                (per) =>
                                                    per.slug ==
                                                    'deals.archivate'
                                            )
                                        "
                                        type="warning"
                                        @click="
                                            archivatingDeal(
                                                selectedDealForUpdate.id
                                            )
                                        "
                                        >{{ $t("message.archivate_deal") }}</el-button
                                    >
                                    <el-button
                                        type="danger"
                                        @click="drawerDeleteDeal = true"
                                        v-if="
                                            permissions.some(
                                                (per) =>
                                                    per.slug == 'deals.delete'
                                            )
                                        "
                                        >{{ $t("message.delete_deal") }}</el-button
                                    >
                                </el-row>
                            </div>
                        </el-tab-pane>

                        <el-tab-pane :label="$t('message.products')" name="second">
                            <deal-products
                                v-can="'dealProducts.index'"
                                ref="dealProducts"
                                @listChanged="listChanged"
                                @dealOffersCreate="dealOffersCreate"
                                :filial_id="
                                    selectedDealForUpdate.filial
                                        ? selectedDealForUpdate.filial.id
                                        : selectedDealForUpdate.filial_id
                                "
                                :deal_id="selectedDealForUpdate.id"
                            ></deal-products>
                        </el-tab-pane>

                        <el-tab-pane :label="$t('message.offer')" name="third">
                            <deal-offers
                                v-can="'dealOffers.index'"
                                @listChanged="listChanged"
                                :deal_id="selectedDealForUpdate.id"
                            ></deal-offers>
                        </el-tab-pane>

                        <el-tab-pane :label="$t('message.check_a')" name="fourth">
                            {{ $t('message.check_a') }}
                        </el-tab-pane>

                        <el-tab-pane :label="$t('message.history')" name="fifth">
                            {{ $t('message.history') }}
                        </el-tab-pane>

                        <el-tab-pane :label="$t('message.applications')" name="sixth">
                            {{ $t('message.applications') }}
                        </el-tab-pane>

                        <el-tab-pane :label="$t('message.tasks')" name="task">
                            <deal-tasks
                                :deal_id="selectedDealForUpdate.id"
                            ></deal-tasks>
                            <div class="mt-5">
                                <el-row class="mt-5">
                                    <el-button
                                        @click="
                                            addTaskToDeal(
                                                selectedDealForUpdate.id
                                            )
                                        "
                                        icon="el-icon-circle-plus-outline"
                                        type="warning"
                                        >{{ $t('message.add_task') }}</el-button
                                    >
                                </el-row>
                            </div>
                        </el-tab-pane>
                    </el-tabs>
                </div>
                <div class="change-data-overlay p-absolute w-100 h-100"></div>
            </div>
        </div>

        <el-dialog
            class="KP_modal"
            :title="$t('message.delete')"
            :visible.sync="drawerDeleteDeal"
            :append-to-body="true"
            width="30%"
            center
            ref="drawerDeleteDeal"
            @opened="drawerOpened('deleteDealComponent')"
            @closed="drawerClosed('deleteDealComponent')"
        >
            <delete-deal
                ref="dealOfferComponent"
                :deal_id="selectedDealForUpdate ? selectedDealForUpdate.id : ''"
                @deleteDealClose="deleteDealClose"
                drawer="drawerDealOfferCreate"
            >
            </delete-deal>
        </el-dialog>

        <el-dialog
            class="KP_modal"
            :title="$t('message.kp')"
            :visible.sync="centerDialogVisible"
            :append-to-body="true"
            width="30%"
            center
            ref="drawerDealOfferCreate"
            @opened="drawerOpened('dealOfferComponent')"
            @closed="drawerClosed('dealOfferComponent')"
        >
            <kp-deal-offer
                ref="dealOfferComponent"
                :filial_id="selectedDealForUpdate.filial ? selectedDealForUpdate.filial.id : selectedDealForUpdate.filial_id"
                @dealOffersClose="dealOffersClose"
                @listChanged="listChanged"
                drawer="drawerDealOfferCreate"
            >
            </kp-deal-offer>
        </el-dialog>

        <div class="app-modal app-modal__full modal-widt-70">
            <el-drawer
                :append-to-body="true"
                size="50%"
                :with-header="false"
                :visible.sync="drawerCreateDeal"
                class="body_scroll"
                ref="drawerDealCreate"
                @opened="drawerOpened('drawerDealCreateChild')"
                @closed="drawerClosed('drawerDealCreateChild')"
            >
                <crm-create-deal
                    :board_id="board_id"
                    ref="drawerDealCreateChild"
                    drawer="drawerDealCreate"
                ></crm-create-deal>
            </el-drawer>

            <el-drawer
                :append-to-body="true"
                size="50%"
                :with-header="false"
                :visible.sync="drawerUpdateDeal"
                class="body_scroll"
                ref="drawerDealUpdate"
                @opened="drawerOpened('drawerDealUpdateChild')"
                @closed="drawerClosed('drawerDealUpdateChild')"
            >
                <crm-update-deal
                    :board_id="board_id"
                    :selectedItem="selectedDealForUpdate"
                    ref="drawerDealUpdateChild"
                    drawer="drawerDealUpdate"
                ></crm-update-deal>
            </el-drawer>

            <el-drawer
                :append-to-body="true"
                :with-header="false"
                :visible.sync="drawerUpdateClient"
                size="70%"
                ref="drawerUpdateClient"
                @opened="drawerOpened('drawerUpdateClientChild')"
                @closed="drawerClosed('drawerUpdateClientChild')"
            >
                <crm-update-client
                    :selectedItem="
                        selectedDealForUpdate.client
                            ? selectedDealForUpdate.client
                            : null
                    "
                    ref="drawerUpdateClientChild"
                    drawer="drawerUpdateClient"
                ></crm-update-client>
            </el-drawer>

            <el-dialog
                :title="$t('message.update_payment')"
                :visible.sync="paymentDrawer"
                width="30%"
                :append-to-body="true"
                class="add-participants"
                ref="drawerPayments"
                @opened="drawerOpened('drawerPaymentsChild')"
                @closed="drawerClosed('drawerPaymentsChild')"
            >
                <CrmUpdatePayment
                    ref="drawerPaymentsChild"
                    drawer="drawerPayments"
                >
                </CrmUpdatePayment>
            </el-dialog>
            <!-- end AddingTask -->
        </div>

        <div class="app-modal app-modal__full">
            <el-drawer
                class="bg-se"
                :visible.sync="drawerTaskCreate"
                :with-header="false"
                ref="drawerTaskCreate"
                @opened="drawerOpened('drawerTaskCreateChild')"
                @closed="drawerClosed('drawerTaskCreateChild')"
                :append-to-body="true"
                size="80%"
            >
                <CreateTask
                    :selected_deal="selectedDealForUpdate"
                    ref="drawerTaskCreateChild"
                    drawer="drawerTaskCreate"
                />
            </el-drawer>
        </div>
    </div>
</template>

<script>
import DealOffers from "./dealOffer/deal-offers";
import DealTasks from "./dealTask/deal-tasks";
import DealProducts from "./dealProduct/deal-products";
import KpDealOffer from "./dealProduct/kp-deal-offer";
import DealCorrespondences from "./dealCorrespondence/deal-correspondences.vue";
import CrmCreateDeal from "./deal/crm-create-deal";
import CrmUpdateDeal from "./deal/crm-update-deal";
import DealStages from "./dealStage/deal-stages";
import DeleteDeal from "./deleteDeal/delete-deal";
import CrmUpdateClient from "@/views/clients/components/crm-update";
import CrmUpdatePayment from "./payments/crm-update-payment";

import { mapGetters, mapActions } from "vuex";
import drawer from "@/utils/mixins/drawer";
import form from "@/utils/mixins/form";

export default {
    name: 'Modal1',
    mixins: [drawer, form],

    components: {
        DealTasks,
        DealOffers,
        DealStages,
        DealProducts,
        CrmCreateDeal,
        CrmUpdateDeal,
        DealCorrespondences,
        KpDealOffer,
        DeleteDeal,
        CrmUpdateClient,
        CreateTask: () => import('@/views/tasks/components/add-modal'),
        CrmUpdatePayment,
    },
    props: {
        board_id: {
            default: null,
        },
        selectedDeal: {
            default: null,
            type: Object,
        },
        dealCreate: {
            default: null,
        },
    },
    data() {
        return {
            form: {},
            stage_id: null,
            loadingData: false,
            drawerUpdateClient: false,
            drawerCreateDeal: false,
            drawerUpdateDeal: false,
            drawerDeleteDeal: false,
            activeName: "first",
            centerDialogVisible: false,
            reloadList: false,
            selectedDealForUpdate: {},
            selectedUsersList: [],
            selectedUsersContainer: false,
            drawerTaskCreate: false,
            drawerTaskCreateChild: false,
            paymentDrawer: false,
            customColors: [
                { color: "#f56c6c", percentage: 20 },
                { color: "#e6a23c", percentage: 40 },
                { color: "#6f7ad3", percentage: 60 },
                { color: "#1989fa", percentage: 80 },
                { color: "#5cb87a", percentage: 100 },
            ],
        };
    },
    computed: {
        ...mapGetters({
            permissions: "auth/permissions",
            getNewDeal: "deals/newForm",
            dealModel: "deals/model",
            stages: "dealStages/list",
        }),
        totalPaymentPercentage() {
            let percentage = 0;
            if (
                this.selectedDealForUpdate.deal_payments &&
                this.selectedDealForUpdate.deal_payments.length > 0
            ) {
                percentage = this.selectedDealForUpdate.deal_payments
                    .map((item) => item.percentage)
                    .reduce((prev, next) => prev + next);
            }
            return Math.round(percentage);
        },
    },
    methods: {
        ...mapActions({
            updateList: "deals/index",
            setPagination: "deals/setPagination",
            updateSort: "deals/updateSort",
            updateFilter: "deals/updateFilter",
            updateColumn: "deals/updateColumn",
            updatePagination: "deals/updatePagination",
            show: "deals/show",
            refreshData: "deals/refreshData",
            showLastDeal: "deals/showLast",
            dealOffers: "dealOffers/index",
            emptyDealCorrespondences: "dealCorrespondences/emptyList",
            empty: "deals/empty",
            downloadDealFile: "deals/downloadDealFile",
            archivateDeal: "deals/archivateDeal",
            delete: "deals/destroy",
            deleteSpectator: "deals/deleteSpectator",
        }),

        addTaskToDeal(deal_id) {
            this.drawerTaskCreate = true;
        },
        async dealOffersCreate({ drawer, productList, kpTemplateId }) {
            await this.dealOffersCreateOpen(drawer);

            if (this.$refs.dealOfferComponent) {
                this.$refs.dealOfferComponent.productList = productList;
                this.$refs.dealOfferComponent.kpTemplateId = kpTemplateId;
            }
        },

        async dealOffersCreateOpen(drawer) {
            this.centerDialogVisible = drawer;
        },

        dealOffersClose() {
            this.centerDialogVisible = false;
        },
        deleteDealClose(val) {
            this.drawerDeleteDeal = false;
            if(val){
                this.parent().closeAddModel();
            }
        },

        afterOpen() {
            if (this.selectedDeal && this.selectedDeal.id) {
                this.fetchData(this.selectedDeal.id);
            } else {
                this.selectedDealForUpdate = JSON.parse(
                    JSON.stringify(this.dealModel)
                );
            }
            this.drawerCreateDeal = JSON.parse(JSON.stringify(this.dealCreate));
        },

        fetchData(id) {
            this.loadingData = true;
            this.show(id)
                .then((res) => {
                    this.selectedDealForUpdate = JSON.parse(
                        JSON.stringify(this.dealModel)
                    );
                    if (this.selectedDealForUpdate.deal_stage) {
                        this.stage_id =
                            this.selectedDealForUpdate.deal_stage.id;
                    }
                    this.loadingData = false;
                })
                .catch((err) => {
                    this.loadingData = false;
                });
        },
        dealStageChanged(id) {
            this.fetchData(id);
            this.listChanged();
        },
        deletingSpectator(user_id) {
            if (this.selectedDealForUpdate.id && user_id) {
                let deal_id = this.selectedDealForUpdate.id;
                this.$confirm(
                    this.$t('message.do_you_want_to_delete_participant'),
                    this.$t('message.warning'),
                    {
                        confirmButtonText: this.$t('message.yes'),
                        cancelButtonText: this.$t('message.no'),
                        type: "warning",
                    }
                )
                    .then(() => {
                        this.deleteSpectator({
                            deal_id: deal_id,
                            user_id: user_id,
                        }).then(() => {
                            this.fetchData(deal_id);
                        });
                    })
                    .catch(() => {
                        this.$message({
                            type: "warning",
                            message: this.$t('message.operation_canceled')
                        });
                    });
            }
        },

        drawerOpened(ref) {
            if (this.$refs[ref]) {
                if (_.isFunction(this.$refs[ref].opened)) {
                    this.$refs[ref].opened();
                }
            }
        },
        closeDrawer(drawer) {
            if (
                this.$refs[drawer] &&
                _.isFunction(this.$refs[drawer].closeDrawer)
            ) {
                this.$refs[drawer].closeDrawer();
            }
        },
        closeWhenDealNotCreated() {
            this.parent().closeAddModel();
        },
        dealFirstCreated() {
            this.showLastDeal().then((res) => {
                this.listChanged();
                this.selectedDealForUpdate = JSON.parse(
                    JSON.stringify(this.getNewDeal)
                );
            });
        },
        drawerClosed(ref) {
            if (
                (this.$refs.drawerDealCreateChild,
                ref === "drawerDealCreateChild")
            ) {
                if (!this.$refs.drawerDealCreateChild.dataSaved) {
                    this.parent().closeAddModel();
                }
            }

            if (this.$refs[ref]) {
                this.$refs[ref].closed();
            }
            if (this.reloadList === true) {
                this.reloadList = false;
            }
            if (_.isFunction(this.empty)) {
                this.empty();
            }
        },
        afterLeave() {
            this.activeName = "first";
            this.$refs.dealProducts.updateProducts = false;
            this.$refs.dealCorrespondence.afterLeave();
            this.$refs.dealStages.addActiveToStage(0);
            this.selectedDealForUpdate = {};
            this.emptyDealCorrespondences();
        },
        listChanged() {
            this.parent().listChanged();
        },
        downloadFile(deal_id, fileName) {
            let data = { deal_id: deal_id, file: fileName };
            this.downloadDealFile(data)
                .then((res) => {
                    const url = window.URL.createObjectURL(
                        new Blob([res.data])
                    );

                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", fileName);
                    document.body.appendChild(link);
                    link.click();
                })
                .catch((err) => {
                    this.$alert(err);
                });
        },
        archivatingDeal(deal_id) {
            this.$confirm(
                this.$t('message.do_you_want_to_archivate_task'),
                this.$t('message.warning'),
                {
                    confirmButtonText: "Да",
                    cancelButtonText: "Нет",
                    type: "warning",
                }
            )
                .then(() => {
                    this.listChanged();
                    this.archivateDeal(deal_id).then(() => {
                        this.parent().closeAddModel();
                    });
                    this.$message({
                        type: "success",
                        message: this.$t('message.archivating_completed'),
                    });
                })
                .catch(() => {
                    this.$message({
                        type: "warning",
                        message: this.$t('message.archivating_canceled'),
                    });
                });
        },
    },
};
</script>

<style>
.KP_modal .el-dialog__body {
    width: 100%;
    background: inherit;
}
.KP_modal .el-dialog {
    background: #f8f8f8;
    border-radius: 10px;
}
.tab-right .el-upload {
    margin-top: -20px;
    margin-bottom: 5px;
}
.mir {
    display: flex;
    justify-content: space-between;
    color: #558cf5;
    border-bottom: 1px solid #d1d1d154;
    padding-top: 7px;
    cursor: pointer;
}
.mir:hover {
    background-color: #e3e3e34a;
    border-color: #d1d1d154;
}

.mirdel {
    display: flex;
    justify-content: space-between;
    /* color: #8a1e35; */
    border-bottom: 1px solid #d1d1d154;
    padding-top: 7px;
    cursor: pointer;
}
</style>
