<template>
    <div class="deal-tab-wrap">
          <el-tabs v-model="activeName">
            <el-tab-pane label="Общие" name="first">
              <div class="wrap-all d-flex f-between f-wrap">
                <div class="wrap-all__left">
                  <div class="gc-card rounded-sm mb-4 p-relative">
                    <div class="gc-card__header d-flex align-center px-3 py-4">
                      <i class="el-icon-document mr-2"></i>
                      <div class="d-flex w-100">
                        <span class="font-bold"> {{$t("message.about_deal")}} </span>

                        <div
                          class="change-form ml-auto pointer"
                          @click="drawerUpdateDeal = true"
                        >
                          {{ $t("message.update") }}
                        </div>
                      </div>
                    </div>

                    <div class="gc-card__body px-3 py-2">
                      <div class="text-descr">
                        <span class="text-descr__label d-block">{{
                          $t("message.filial")
                        }}</span>
                        <div class="d-flex align-center font-medium">
                          {{
                            selectedDealForUpdate.filial
                              ? selectedDealForUpdate.filial.name
                              : ""
                          }}
                        </div>
                      </div>
                    </div>

                    <div class="gc-card__body px-3 py-2">
                      <div class="text-descr">
                        <span class="text-descr__label d-block">{{
                          $t("message.money_amount")
                        }}</span>
                        <div class="d-flex align-center font-medium">
                          {{ selectedDealForUpdate.money_amount }}
                          {{
                            selectedDealForUpdate.currency
                              ? selectedDealForUpdate.currency.code
                              : ""
                          }}
                        </div>
                      </div>
                    </div>

                    <div class="gc-card__body px-3 py-2">
                      <div class="text-descr">
                        <span class="text-descr__label pr-5 mr-5">{{
                          $t("message.begin_date")
                        }}</span>
                        <span class="text-descr__label pl-5 ml-3">{{
                          $t("message.end_date")
                        }}</span>
                        <div class="d-flex align-center font-medium">
                          <span class="pr-5 mr-5">
                            {{ selectedDealForUpdate.begin_date }}
                          </span>
                          <span class="pl-5 ml-5">
                            {{ selectedDealForUpdate.end_date }}
                          </span>
                        </div>
                      </div>
                    </div>

                    <div class="gc-card__body px-3 py-2">
                      <div class="text-descr">
                        <span class="text-descr__label d-block">{{
                          $t("message.company")
                        }}</span>
                        <div class="d-flex align-center font-medium">
                          {{
                            selectedDealForUpdate.company
                              ? selectedDealForUpdate.company.name
                              : ""
                          }}
                        </div>
                      </div>
                    </div>

                    <div class="gc-card__body px-3 py-2">
                      <div class="text-descr">
                        <span class="text-descr__label d-block">{{
                          $t("message.client")
                        }}</span>
                        <div class="d-flex align-center font-medium">
                          {{
                            selectedDealForUpdate.client
                              ? selectedDealForUpdate.client.full_name
                              : ""
                          }}
                        </div>
                      </div>
                    </div>

                    <div class="gc-card__body px-3 py-2">
                      <div class="text-descr">
                        <span class="text-descr__label d-block">{{
                          $t("message.phone")
                        }}</span>
                        <div class="d-flex align-center font-medium">
                          <a
                            :href="'tell:'"
                            class="text-descr__phone f-fill text-reset"
                          >
                            {{ selectedDealForUpdate.phone }}
                          </a>
                          <span class="phone-detect pl-2"> {{ $t("message.working") }} </span>
                        </div>
                      </div>
                    </div>

                    <div class="gc-card__body px-3 py-2">
                      <div class="text-descr mb-3">
                        <span class="text-descr__label d-block">{{
                          $t("message.email")
                        }}</span>
                        <div class="d-flex align-center font-medium">
                          {{ selectedDealForUpdate.email }}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="gc-card rounded-sm mb-4">
                    <div class="gc-card__header d-flex align-center px-3 py-4">
                      <i class="el-icon-files mr-2"></i>
                      <div class="d-flex w-100">
                        <span class="font-bold">
                          {{ $t("message.additional") }}
                        </span>
                        <div class="change-form ml-auto pointer">
                          <!-- {{ $t("message.update") }} -->
                        </div>
                      </div>
                    </div>
                    <div class="gc-card__body px-3 py-4">
                      <div class="text-descr">
                        <span class="text-descr__label d-block">
                          {{ $t("message.deal_type") }}
                        </span>
                        <div class="d-flex align-center font-medium">
                          <span class="text-descr__text f-fill text-reset">
                            {{
                              selectedDealForUpdate.deal_type
                                ? selectedDealForUpdate.deal_type.name
                                : ""
                            }}
                          </span>
                        </div>
                      </div>
                      <div class="text-descr">
                        <span class="text-descr__label d-block">
                          {{ $t("message.source") }}
                        </span>
                        <div class="d-flex align-center font-medium">
                          <span class="text-descr__text f-fill text-reset">{{
                            selectedDealForUpdate.source
                              ? selectedDealForUpdate.source.name
                              : ""
                          }}</span>
                        </div>
                      </div>

                      <div class="text-descr">
                        <span class="text-descr__label d-block">
                          {{ $t("message.comment") }}
                        </span>
                        <div class="d-flex align-center font-medium">
                          <span class="text-descr__text f-fill text-reset">{{
                            selectedDealForUpdate.comment
                          }}</span>
                        </div>
                      </div>

                      <div class="text-descr">
                        <span class="text-descr__label d-block">
                          {{ $t("message.files") }}
                        </span>
                        <div
                          class="d-flex align-center font-medium pb-2"
                          v-for="file in selectedDealForUpdate.files"
                          :key="file.name"
                        >
                          <span class="text-descr__text f-fill text-reset">{{
                            file ? file.name : ""
                          }}</span>
                          <span class="text-descr__text f-fill text-reset">
                            <el-button
                              @click="
                                downloadFile(
                                  selectedDealForUpdate.id,
                                  file.name
                                )
                              "
                              type="info"
                              size="mini"
                              ><i class="el-icon-download"></i
                              > {{ $t('message.download') }}</el-button
                            >
                          </span>
                        </div>
                      </div>

                      <div class="text-descr">
                        <span class="text-descr__label d-block">
                          {{ $t("message.responsible") }}
                        </span>
                        <div
                          class="
                            responsible-block
                            d-flex
                            align-center
                            font-medium
                            p-2
                            rounded-sm
                          "
                        >
                          <div
                            class="gc-small-block rounded-circle hidden mr-3"
                            style="border: 1px solid gray"
                          >
                            <img
                              :src="
                                selectedDealForUpdate.user
                                  ? selectedDealForUpdate.user.avatar
                                    ? selectedDealForUpdate.user.avatar
                                    : '/img/default-user-icon.png'
                                  : '/img/default-user-icon.png'
                              "
                              alt="Default Avatar"
                              class="d-block"
                            />
                          </div>
                          <div class="f-fill">
                            <span class="text-descr__text f-fill">
                              {{
                                selectedDealForUpdate.user
                                  ? selectedDealForUpdate.user.name
                                  : ""
                              }}
                            </span>
                          </div>
                        </div>
                      </div>

                      <div class="text-descr">
                        <span class="text-descr__label d-block">
                          {{ $t("message.spectators") }}
                        </span>
                        <div
                          v-for="spectator in selectedDealForUpdate.spectators"
                          :key="spectator.id"
                          class="
                            responsible-block
                            d-flex
                            align-center
                            font-medium
                            p-2
                            rounded-sm
                          "
                        >
                          <div
                            class="gc-small-block rounded-circle hidden mr-3"
                            style="border: 1px solid gray"
                          >
                            <img
                              :src="
                                spectator
                                  ? spectator.avatar
                                    ? spectator.avatar
                                    : '/img/default-user-icon.png'
                                  : '/img/default-user-icon.png'
                              "
                              alt="Default Avatar"
                              class="d-block"
                            />
                          </div>
                          <div class="f-fill">
                            <span class="text-descr__text f-fill">
                              {{ spectator ? spectator.name : "" }}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="wrap-all__right">
                  <deal-correspondences
                    ref="dealCorrespondence"
                    :deal_id="selectedDealForUpdate.id"
                  ></deal-correspondences>
                </div>
              </div>
            </el-tab-pane>

            <el-tab-pane label="Товары" name="second">
              <deal-products
                ref="dealProducts"
                :filial_id="
                  selectedDealForUpdate.filial
                    ? selectedDealForUpdate.filial.id
                    : selectedDealForUpdate.filial_id
                "
                :deal_id="selectedDealForUpdate.id"
              ></deal-products>
            </el-tab-pane>

            <el-tab-pane label="Предложения" name="third">
              <deal-offers :deal_id="selectedDealForUpdate.id"></deal-offers>
            </el-tab-pane>

            <el-tab-pane :label="$t('message.accounts')" name="fourth"> {{$t('message.accounts')}}</el-tab-pane>

            <el-tab-pane :label="$t('message.history')" name="fifth">{{$t('message.history')}}</el-tab-pane>

            <el-tab-pane :label="$t('message.applications')" name="sixth">
             {{$t('message.applications')}}
            </el-tab-pane>

            <el-tab-pane label="Задачи" name="task">
              <div class="product-add">
                <div class="product-add-table mt-5">
                  <table class="table-my-code table-bordered borde-l-r">
                    <thead>
                      <tr>
                        <th class="w15">{{$t('message.task')}}</th>
                        <th class="w15">{{$t('message.description')}}</th>
                        <th class="w15">{{$t('message.begin_date')}}</th>
                        <th class="w15">{{$t('message.end_date')}}</th>
                        <th class="w15">{{$t('message.who_gave_the_task')}}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th class="w15">{{ $t("message.task_in_project") }}:</th>
                        <th class="w15">
                          Для скачивания материала войдите или зарегистрируйтесь
                          на сайте Для скачивания материала войдите или
                          зарегистрируйтесь на са
                        </th>
                        <th class="w15">20.09.2021</th>
                        <th class="w15">30.09.2021</th>
                        <th class="w15">Azimov A</th>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>

</template>

<script>

    import { mapGetters, mapActions } from "vuex";
    import DealOffers from "./dealOffer/deal-offers";
    import DealProducts from "./dealProduct/deal-products";
    import DealCorrespondences from "./dealCorrespondence/deal-correspondences.vue";

    export default {
        props:['deal_id', 'open'],
        components: {
            DealOffers,
            DealProducts,
            DealCorrespondences,
        },
        data() {
            return {
                total: 0,
                selectedDealForUpdate: [],
                activeName: "first",
            };
        },
        watch: {
            open: {
                handler: function(newValue) {
                    if (newValue === true) {
                        this.loadModel();
                    }
                },
                immediate: true,
                deep: true
            },
        },
        computed:{
            ...mapGetters({
                model: 'deals/model',
            }),
        },
        methods:{
            ...mapActions({
                show: "deals/show",
                downloadDealFile: "deals/downloadDealFile",
            }),
            loadModel(){
                if (this.deal_id) {
                    this.show(this.deal_id)
                        .then((res) => {
                            this.selectedDealForUpdate = this.model
                        })
                }
            },
            downloadFile(deal_id, fileName) {
              let data = { deal_id: deal_id, file: fileName };
              this.downloadDealFile(data)
                .then((res) => {
                  const url = window.URL.createObjectURL(new Blob([res.data]));

                  const link = document.createElement("a");
                  link.href = url;
                  link.setAttribute("download", fileName);
                  document.body.appendChild(link);
                  link.click();
                })
                .catch((err) => {
                  this.$alert(err);
                });
            },  
        },
    }
</script>
<style>
.KP_modal .el-dialog__body {
  width: 100%;
  background: inherit;
}
.KP_modal .el-dialog {
  background: #f8f8f8;
  border-radius: 10px;
}
.tab-right .el-upload {
  margin-top: -20px;
  margin-bottom: 5px;
}
.mir{
  display: flex;
    justify-content: space-between;
    color: #558cf5;
    border-bottom: 1px solid  #d1d1d154;
    padding-top: 7px;
    cursor: pointer;
}
.mir:hover{
  background-color: #e3e3e34a;
  border-color:  #d1d1d154;
}

.mirdel{
  display: flex;
    justify-content: space-between;
    /* color: #8a1e35; */
    border-bottom: 1px solid  #d1d1d154;
    padding-top: 7px;
    cursor: pointer;
}
</style>