<template>
  <div>
    <div class="app-modal_In">
      <div class="app-modal__header d-flex f-between p-5 m-title-modal">
        <p class="large--title m-0">{{ $t('message.new_deal') }}</p>
        <div>
          <crm-store-update-close
            :permission="$options.name"
            :button_type="'store'"
            :loading="loadingButton"
            @c-submit="submit(true)"
            @c-close="close()"
          ></crm-store-update-close>
        </div>
      </div>
    </div>
    <!-- app-modal__header end -->
    <div
      class="change-data-wrap p-relative w-100 crm-create-deal"
      ref="showChangeContent"
    >
      <el-form ref="form" :model="form" :rules="rules" label-position="top">
        <div class="change-data-card rounded-sm mb-4">
          <div class="gc-card__body px-3 py-4">
            <div class="app-form__group mb-0">
              <el-form-item
                :label="$t('message.task_board_id')"
                prop="board_id"
                class="label_mini mb-0"
                v-if="!this.board_id"
              >
                <select-board
                  :size="'medium'"
                  :placeholder="$t('message.task_board_id')"
                  :id="form.board_id"
                  v-model="form.board_id"
                >
                </select-board>
              </el-form-item>
            </div>
            <div class="app-form__group mb-0">
              <el-form-item
                :label="$t('message.filial')"
                prop="filial_id"
                class="label_mini mb-0"
              >
                <select-filial
                  :size="'medium'"
                  :placeholder="columns.filial.title"
                  :id="form.filial_id"
                  :board_id="form.board_id"
                  v-model="form.filial_id"
                >
                </select-filial>
              </el-form-item>
            </div>

            <div class="app-form__group mb-0">
              <el-form-item
                :label="$t('message.name')"
                prop="name"
                class="label_mini mb-0"
              >
                <el-input
                  placeholder="Сделка "
                  v-model="form.name"
                  size="medium"
                ></el-input>
              </el-form-item>
            </div>

            <div class="app-form__group mb-0">
              <div class="form-group-wrap d-flex">
                <div class="app-form__group f-fill">
                  <el-form-item
                    :label="$t('message.money_amount')"
                    class="label_mini mb-0"
                  >
                    <crm-money-input
                      v-model="form.money_amount"
                      :old="form.money_amount"
                      :size="'medium'"
                    >
                    </crm-money-input>
                  </el-form-item>
                </div>

                <div class="app-form__group pl-3">
                  <el-form-item
                    :label="$t('message.currency')"
                    class="label_mini mb-0"
                  >
                    <select-currency
                      :size="'medium'"
                      ref="currencySelect"
                      :placeholder="columns.currency.title"
                      :id="form.currency_id"
                      :board_id="form.board_id"
                      v-model="form.currency_id"
                    >
                    </select-currency>
                  </el-form-item>
                </div>
              </div>
            </div>

            <div class="app-form__group mb-0">
              <div class="form-group-wrap d-flex">
                <div class="app-form__group f-fill">
                  <div>
                  <el-form-item
                    :label="$t('message.profit_in_percents')"
                    class="label_mini"
                  >
                    <crm-money-input
                      v-model="form.profit"
                      :old="form.profit"
                      :size="'medium'"
                    >
                    </crm-money-input>
                  </el-form-item>
                  </div>

                </div>

                <div class="app-form__group pl-3 f-fill">
                  <el-form-item
                    :label="$t('message.profit_in_money')"
                    class="label_mini"
                  >
                    <el-input
                      placeholder="Please input"
                      v-model="calculateProfit"
                      style="font-weight: bold"
                      :disabled="true">
                    </el-input>
                  </el-form-item>
                </div>
              </div>
            </div>

            <div class="app-form__group mb-0">
              <span class="input--label d-block mb-2"
                >{{ $t('message.dealCondition') }}</span
              >
              <div class="input-group-wrap p-3 rounded">
                  

                  <el-progress class="mt-3" v-if="totalPaymentPercentage > 0" :stroke-width="13" :percentage="totalPaymentPercentage" :color="customColors"></el-progress>
                  
                  <div class="app-form__group mb-0 mt-3">
                    <div class="input-group-wrap p-3 rounded mb-2" style="background-color: #f3f3f2;" v-for="(payment, index) in form.deal_payments" :key="index">
                        <el-form-item :label="$t('message.dealCondition')" class="label_mini">
                            <select-deal-condition
                              :size="'medium'"
                              :placeholder="columns.condition.title"
                              :id="payment.payment_condition_id"
                              v-model="payment.payment_condition_id"
                            >
                            </select-deal-condition>
                        </el-form-item>

                      <div v-if="payment.payment_condition_id">

                        <div class="form-group-wrap d-flex">
                          <div class="app-form__group f-fill">
                            <div>
                            <el-form-item
                              :label="$t('message.payment_in_percents')"
                              class="label_mini mb-2"
                            >
                              <crm-money-input
                                v-model="payment.percentage"
                                :old="payment.percentage"
                                :size="'medium'"
                              >
                              </crm-money-input>
                            </el-form-item>
                            </div>

                          </div>

                          <div class="app-form__group pl-3 f-fill">
                            <el-form-item
                              :label="$t('message.payment_in_money')"
                              class="label_mini mb-2"
                            >
                              <el-input
                                :placeholder="$t('message.payment_in_money')"
                                :value="calculatePaymentPercentage(payment)"
                                style="font-weight: bold"
                                :disabled="true">
                              </el-input>
                            </el-form-item>
                          </div>
                        </div>

                        <div class="app-form__group mb-0 d-flex">
                          <div class="app-form__group f-fill mr-2">
                            <el-form-item
                              :label="$t('message.payment_start_date')"
                              class="label_mini mb-2"
                            >
                              <el-date-picker
                                class="w-100"
                                size="medium"
                                v-model="payment.begin_date"
                                type="date"
                                :placeholder="$t('message.payment_start_date')"
                                format="yyyy-MM-dd"
                                value-format="yyyy-MM-dd"
                              ></el-date-picker>
                            </el-form-item>
                          </div>

                          <div class="app-form__group f-fill ml-2">
                            <el-form-item
                              :label="$t('message.payment_end_date')"
                              class="label_mini mb-2"
                            >
                              <el-date-picker
                                class="w-100"
                                size="medium"
                                v-model="payment.end_date"
                                type="date"
                                :placeholder="$t('message.payment_end_date')"
                                format="yyyy-MM-dd"
                                value-format="yyyy-MM-dd"
                              ></el-date-picker>
                            </el-form-item>
                          </div>
                        </div>
                      
                        <el-form-item :label="$t('message.comment')" class="label_mini mb-0">
                          <el-input
                            type="textarea"
                            autosize
                            :placeholder="$t('message.comment')"
                            v-model="payment.comment">
                          </el-input>
                        </el-form-item>
                        <el-button v-if="form.deal_payments.length > 1" class="mt-5" size="mini" type="danger" @click="removePayment(index)"><i class="el-icon-delete"></i> {{ $t('message.delete_payment') }} </el-button>
                      </div>
                    </div>

                  </div>
                  <el-button v-if="paymentPercentage < 100" class="mt-5" size="mini" type="primary" @click="addPayment()"><i class="el-icon-plus"></i> {{ $t('message.add_payment') }}</el-button>
              </div>
            </div>
            
            <div class="app-form__group mb-0">
              <el-form-item :label="$t('message.shipmentTerm')" class="label_mini mb-0">
                  <select-shipment-term
                    :size="'medium'"
                    :placeholder="columns.shipmentTerm.title"
                    :id="form.shipment_term_id"
                    v-model="form.shipment_term_id"
                  >
                  </select-shipment-term>
              </el-form-item>
            </div>


            <div class="app-form__group mb-0">
              <el-form-item :label="$t('message.dealStage')" class="label_mini mb-0">
                <select-deal-stage
                  :size="'medium'"
                  :placeholder="columns.dealStage.title"
                  :id="form.deal_stage_id"
                  v-model="form.deal_stage_id"
                >
                </select-deal-stage>
              </el-form-item>
            </div>

            <div class="app-form__group mb-4 d-flex">
              <div class="app-form__group f-fill mr-2">
                <el-form-item
                  :label="$t('message.begin_date')"
                  class="label_mini"
                >
                  <el-date-picker
                    class="w-100"
                    size="medium"
                    v-model="form.begin_date"
                    type="date"
                    :placeholder="$t('message.begin_date')"
                    format="yyyy-MM-dd"
                    value-format="yyyy-MM-dd"
                  ></el-date-picker>
                </el-form-item>
              </div>

              <div class="app-form__group f-fill ml-2">
                <el-form-item
                  :label="$t('message.end_date')"
                  class="label_mini"
                >
                  <el-date-picker
                    class="w-100"
                    size="medium"
                    v-model="form.end_date"
                    type="date"
                    :placeholder="$t('message.end_date')"
                    format="yyyy-MM-dd"
                    value-format="yyyy-MM-dd"
                  ></el-date-picker>
                </el-form-item>
              </div>
            </div>

            <div class="app-form__group mb-0">
              <span class="input--label d-block mb-2"
                > {{ $t('message.client_with_contact') }}</span
              >
              <div class="input-group-wrap p-3 rounded">
                <div class="input-group__list">
                  <div class="app-form__group mb-0">
                    <select-company
                      :size="'medium'"
                      :placeholder="columns.company.title"
                      :id="form.company_id"
                      v-model="form.company_id"
                    >
                    </select-company>
                  </div>

                  

                  <div class="app-form__group mb-0">
                    <select-client
                      :size="'medium'"
                      :placeholder="columns.client.title"
                      :id="form.client_id"
                      :company_id="form.company_id"
                      v-model="form.client_id"
                    >
                    </select-client>

                    <div class="sub-form-group pl-5">
                      <div class="app-form__group mb-4">
                        <el-form-item
                          :label="$t('message.country')"
                           class="label_mini"
                        >
                          <el-input
                            :placeholder="$t('message.country')"
                            :value="clientForm.country ? clientForm.country.name : ''"
                            size="medium"
                            disabled
                          ></el-input>
                        </el-form-item>
                      </div>
                      <div class="app-form__group mb-0">
                        <!-- <el-form-item
                          :label="$t('message.phone')"
                          class="label_mini"
                        >
                          <CountryPhoneInput
                            ref="phoneInput"
                            v-model="form.phone"
                            @input="getPhone"
                          />
                        </el-form-item> -->
                        <crm-phone-input
                            :inputValue="form.phone" 
                            v-model="form.phone"
                            @getPhoneStatus="getPhoneStatus"
                            :form_prop="'phone'"
                            :country_code="form.phone_country_code"
                        ></crm-phone-input>
                      </div>

                      <div class="app-form__group mb-0">
                        <el-form-item
                          :label="$t('message.email')"
                          class="label_mini"
                        >
                          <el-input
                            :placeholder="$t('message.email')"
                            v-model="form.email"
                            size="medium"
                          ></el-input>
                        </el-form-item>
                      </div>
                    </div>
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="change-data-card rounded-sm mb-0">
          <div class="gc-card__header d-flex align-center px-3 py-4">
            <i class="el-icon-share mr-2"></i>
            <div class="d-flex w-100">
              <span class="font-bold">{{ $t("message.additional") }}</span>
            </div>
          </div>
          <div class="gc-card__body px-3 py-4">
            <div class="app-form__group mb-0">
              <el-form-item :label="$t('message.dealType')" class="label_mini mb-0">
                <select-deal-type
                  :size="'medium'"
                  :placeholder="columns.dealType.title"
                  :id="form.deal_type_id"
                  v-model="form.deal_type_id"
                >
                </select-deal-type>
              </el-form-item>
            </div>
            <div class="app-form__group mb-0">
              <el-form-item :label="$t('message.source')" class="label_mini mb-0">
                <select-source
                  :size="'medium'"
                  :placeholder="columns.source.title"
                  :id="form.source_id"
                  v-model="form.source_id"
                >
                </select-source>
              </el-form-item>
            </div>
            <div class="app-form__group mb-0">
              <el-form-item
                :label="$t('message.reception_address')"
                class="label_mini"
              >
                <el-input
                  :placeholder="$t('message.reception_address')"
                  v-model="form.reception_address"
                  size="medium"
                ></el-input>
              </el-form-item>
            </div>
            <div class="app-form__group mb-0">
              <el-form-item :label="$t('message.comment')" class="label_mini mb-0">
                <textarea
                  :placeholder="$t('message.comment')"
                  v-model="form.comment"
                  class="timeline-textarea p-3 w-100 rounded"
                ></textarea>
              </el-form-item>
            </div>
            <div class="app-form__group mb-0">
              <el-form-item :label="$t('message.file')" class="label_mini">
                <el-upload
                  v-model="form.files"
                  class="upload-demo"
                  action="/"
                  :limit="file_limit"
                  :accept="fileAccepts"
                  :on-exceed="handleExceed"
                  :on-change="createImageList"
                  :on-remove="handleRemove"
                  :file-list="fileList"
                  :auto-upload="false"
                >
                  <el-button size="small" type="primary"> {{ $t('message.loading') }} </el-button>
                </el-upload>
              </el-form-item>
            </div>
            <div class="text-descr mb-0">
              <span class="text-descr__label d-block">{{
                $t("message.responsible")
              }}</span>
              <div
                class="
                  responsible-block
                  d-flex
                  align-center
                  font-medium
                  p-2
                  rounded-sm
                "
              >
                <div
                  class="gc-small-block rounded-circle hidden mr-3"
                  style="border: 1px solid gray"
                >
                  <img
                    :src="
                      authUser
                        ? (authUser.avatar
                          ? authUser.avatar
                          : '/img/default-user-icon.png')
                        : '/img/default-user-icon.png'
                    "
                    alt="Default Avatar"
                    class="d-block"
                  />
                </div>
                <div class="f-fill">
                  <span class="text-descr__text f-fill">{{
                    authUser.name
                  }}</span>
                </div>
              </div>
            </div>
            <div class="text-descr mb-0">
              <span class="text-descr__label d-block">{{ $t('message.participants') }}</span>
              <div
                class="
                  responsible-block
                  d-flex
                  align-center
                  font-medium
                  p-2
                  rounded-sm
                  pointer
                  p-relative
                "
              >
                <div
                  class="gc-small-block rounded-circle hidden mr-3"
                  @click="getSelectedUsers"
                >
                  <img
                    src="/img/default-user-icon.png"
                    alt="Default Avatar"
                    class="d-block"
                  />
                </div>
                <div
                  class="f-fill d-flex align-center"
                  @click="getSelectedUsers"
                >
                  <span class="text-descr__text f-fill"
                    >{{ $t('message.add_participant') }}</span
                  >
                  <i class="el-icon-plus ml-auto"></i>
                </div>

                <div
                  class="
                    responsible-block-dropdown
                    p-absolute
                    w-100
                    rounded
                    p-3
                  "
                >
                  <div class="responsible-block-search">
                    <div class="app-form__group">
                      <div class="responsible-block-input">
                        <input
                          type="text"
                          class="gc-country-input"
                          :placeholder="$t('message.search')"
                          @input="findResponsibleUsers"
                          ref="responsibleUsersInput"
                        />
                      </div>
                    </div>
                  </div>

                  <div
                    class="responsible-users-dropdown rounded p-3 mt-2"
                    ref="usersDropdown"
                  >
                    <perfect-scrollbar style="height: 200px">
                      <div
                        v-for="user in usersList"
                        :key="user.id"
                        @click="selectUsers(user)"
                        class="
                          users-dropdown-items
                          d-flex
                          p-2
                          rounded
                          transition
                        "
                      >
                        <div class="user-items-left mr-2">
                          <div
                            class="
                              user-item-avatar
                              w-100
                              h-100
                              rounded-pill
                              hidden
                            "
                          >
                            <img
                              :src="
                                user
                                  ? (user.avatar
                                    ? user.avatar
                                    : '/img/default-user-icon.png')
                                  : '/img/default-user-icon.png'
                              "
                              alt
                            />
                          </div>
                        </div>
                        <div class="user-items-right">
                          <h4 class="my-1">
                            {{ user.name }}
                          </h4>
                          <p class="m-0">
                            {{ user.phone }}
                          </p>
                        </div>
                      </div>
                    </perfect-scrollbar>

                    <div class="custom__button mt-3">
                      <el-button
                        @click="setUsers"
                        type="primary"
                        icon="el-icon-user"
                        size="medium"
                        > {{$t('message.add')}} </el-button
                      >
                    </div>
                  </div>

                  <div
                    v-for="user in selectedUsersList"
                    :key="user.id"
                    class="users-dropdown-items d-flex p-2 rounded transition"
                  >
                    <div class="user-items-left mr-2">
                      <div
                        style="border: 1px solid gray"
                        class="user-item-avatar w-100 h-100 rounded-pill hidden"
                      >
                        <img
                          :src="
                            user
                              ? (user.avatar
                                ? user.avatar
                                : '/img/default-user-icon.png')
                              : '/img/default-user-icon.png'
                          "
                          alt
                        />
                      </div>
                    </div>
                    <div class="user-items-right">
                      <h4 class="my-1">
                        {{ user.name }}
                      </h4>
                      <p class="m-0">
                        {{ user.phone }}
                      </p>
                    </div>
                    <div
                      class="f-fill d-flex align-center"
                      @click="removeFromSelectedUsers(user)"
                    >
                      <i class="el-icon-delete icon__delete ml-auto"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </el-form>
    </div>
  </div>
</template>


<script>
import CountryPhoneInput from "@/components/CountryPhoneInput";
import selectFilial from "@/components/inventory/select-filial";
import selectSource from "@/components/inventory/select-source";
import selectDealStage from "@/components/inventory/select-dealStage";
import selectCurrency from "@/components/inventory/select-currency";
import selectCompany from "@/components/selectsWithCreate/select-company-and-create";
import selectClient from "@/components/selectsWithCreate/select-company-client-and-create";
import selectDealType from "@/components/inventory/select-dealType";
import selectDealCondition from "@/components/inventory/select-deal-condition";
import selectShipmentTerm from "@/components/inventory/select-shipment-term";
import selectBoard from "@/components/inventory/select-deal-board";

import { mapGetters, mapActions } from "vuex";
import drawer from "@/utils/mixins/drawer";
import form from "@/utils/mixins/form";
import _ from "lodash";

export default {
  components: {
    CountryPhoneInput,
    selectFilial,
    selectSource,
    selectDealStage,
    selectCompany,
    selectCurrency,
    selectClient,
    selectDealType,
    selectDealCondition,
    selectShipmentTerm,
    selectBoard
  },
  mixins: [form, drawer],
  props: {
    board_id: {
      default: null,
    },
  },
  data() {
    return {
      dataSaved: false,
      usersList: [],
      updateFile: [],
      selectedUsersList: [],
      selectedUsersContainer: false,
      clientForm: [],
      fileList: [],
      input: 0,
      paymentPercentage: 0,
      file_limit: 5,
      customColors: [
        {color: '#f56c6c', percentage: 20},
        {color: '#e6a23c', percentage: 40},
        {color: '#6f7ad3', percentage: 60},
        {color: '#1989fa', percentage: 80},
        {color: '#5cb87a', percentage: 100},
      ],
      fileAccepts:
        "image/png, image/jpeg, application/pdf, .doc,.docx,application/msword,.xls,.xlsx,application/vnd.openxmlformats-officedocument.wordprocessingml.document, text/plain,",
    };
  },
  computed: {
    ...mapGetters({
      getUserSearch: "users/search",
      getInventoryUsers: "users/inventory",
      authUser: "auth/user",
      model: "deals/model",
      rules: "deals/rules",
      columns: "deals/columns",
      getClient: "clients/model",
      getForm: "deals/model",
    }),

    calculateProfit() {
      let currency_symbol = ''
      if(this.$refs['currencySelect']){
        currency_symbol = this.$refs['currencySelect'].currency_symbol;
      }
      return  (this.form.money_amount * this.form.profit / 100).toFixed(2) + ' ' + currency_symbol;
    },
    totalPaymentPercentage(){
      if(this.form.deal_payments.length > 0){
        this.paymentPercentage = this.form.deal_payments.map(item => item.percentage).reduce((prev, next) => prev + next);
      }
      if(this.paymentPercentage > 100){
        this.$message({
          type: "warning",
          message: this.$t('message.Payment cannot be more than 100%')
        });        
        this.paymentPercentage = 100
      }
      return Math.round(this.paymentPercentage);
    }
    
  },
  watch: {
    "form.client_id": function (val) {
      if (val) {
        this.showClient(val).then(() => {
          this.clientForm = JSON.parse(JSON.stringify(this.getClient));
          // this.$refs["phoneInput"].setPhoneNumber(this.clientForm.phone);
          this.form.email = this.clientForm.email;
          this.form.phone_country_code = this.clientForm.phone_country_code;
          this.form.phone = this.clientForm.phone;
        });
      } else {
        // this.$refs["phoneInput"].setPhoneNumber("");
        this.form.phone_country_code = 'UZ';
        this.form.email = "";
        this.form.phone = "";
      }
    },
  },
  methods: {
    ...mapActions({
      save: "deals/store",
      showInventoryUsers: "users/inventory",
      showClient: "clients/show",
      search: "users/search",
      empty: "deals/empty",
      updateDealSources: "sources/dealSources",
      updateInventory: "deals/inventory",
    }),
    // getPhone(num) {
    //   this.form.phone = num;
    // },
    afterOpen() {
      this.form.board_id = this.board_id;
      this.form.user_id = this.authUser.id;
      this.addPayment();
    },
    getPhoneStatus({is_valid, countryCode}){
        this.is_phone_valid = is_valid;
        this.form.phone_country_code = countryCode;
    },
    addPayment(){
      if(this.form.deal_payments.filter(item => item.percentage === 0).length > 0){
        this.$message({
          type: "warning",
          message: this.$t('message.One of the payments is 0, please enter the percentage for the existing payment first')
        }); 
      }else{
        let payment = {
          id: null,
          percentage: 0,
          begin_date: '',
          end_date: '',
          comment: '',
          payment_condition_id: null
        }
        this.form.deal_payments.push(payment);
      }
      
    },
    removePayment(index){
       this.$confirm(
          this.$t('message.do_you_really_want_to_do_this'),
          this.$t('message.warning'), {
            confirmButtonText: this.$t('message.yes'),
            cancelButtonText: this.$t('message.no'),
            type: "warning"
          }
        )
        .then(() => {
            this.form.deal_payments.splice(index, 1);
        })
        .catch(() => {
          this.$message({
            type: "warning",
            message: this.$t('message.operation_canceled')
          });
        });
    },
    calculatePaymentPercentage(payment) {
      let currency_symbol = ''
      if(this.$refs['currencySelect']){
        currency_symbol = this.$refs['currencySelect'].currency_symbol;
      }
      return  (this.form.money_amount * payment.percentage / 100).toFixed(2) + ' ' + currency_symbol;
    },
    submit(close = true) {
      if(this.form.deal_payments.length > 0 && this.form.deal_payments.map(item => item.percentage).reduce((prev, next) => prev + next) > 100){
        this.$message({
          type: "warning",
          message: this.$t('message.Payment cannot be more than 100%, please correct before saving')
        });   
      }else{

        let formData = new FormData();

        for (const key in this.form) {
          if (this.form[key]) {
            if(key !== 'deal_payments'){
              formData.append(key, this.form[key]);
            }else{
              formData.append(key, JSON.stringify(this.form[key]));
            }
          } else {
            formData.append(key, "");
          }
        }
        if (_.size(this.updateFile) > 0) {
          for (const key in this.updateFile) {
            if (this.updateFile.hasOwnProperty(key)) {
              const element = this.updateFile[key];
              formData.append(`files[${key}]`, element);
            }
          }
        }
        

        this.$refs["form"].validate((valid) => {
          if (valid) {
            this.loadingButton = true;
            this.dataSaved = true;
            this.save(formData)
              .then((res) => {
                this.loadingButton = false;
                this.firstCreated();
                this.$alert(res);
                this.parent().listChanged();
                this.updateInventory();
                if (close) this.close();
              })
              .catch((err) => {
                this.loadingButton = false;
                this.$alert(err);
              });
          }
        });
      }
    },
    firstCreated() {
      this.parent().dealFirstCreated();
    },
    close() {
      if (!this.dataSaved) {
        this.parent().closeWhenDealNotCreated();
      }
      this.parent().closeDrawer(this.drawer);
    },
    findResponsibleUsers() {
      const { usersDropdown } = this.$refs;
      let value = event.target.value;
      if (value) {
        this.searchUsers(value, this);
      }
      if (value) {
        usersDropdown.classList.add("active");
      } else {
        usersDropdown.classList.remove("active");
      }
    },
    searchUsers: _.debounce((search, self) => {
      if (_.isFunction(self.search)) {
        self
          .search(search)
          .then((res) => {
            self.usersList = JSON.parse(JSON.stringify(self.getUserSearch.filter(item => item.id !== self.form.user_id)));
          })
          .catch((err) => {});
      }
    }, 1000),
    getSelectedUsers() {
      const { usersDropdown } = this.$refs;
      usersDropdown.classList.toggle("active");
      if (usersDropdown.classList.contains("active")) {
        this.showInventoryUsers().then(() => {
          this.usersList = JSON.parse(JSON.stringify(this.getInventoryUsers.filter(item => item.id !== (this.authUser ? this.authUser.id : null))));
        });
      }
    },
    selectUsers(user) {
      let $el = event.target.closest(".users-dropdown-items");
      if (!this.selectedUsersList.some((person) => person.id === user.id)) {
        this.selectedUsersList.push(user);
        $el.classList.add("active");
      } else {
        this.selectedUsersList = this.selectedUsersList.filter(
          (item) => item.id !== user.id
        );
        $el.classList.remove("active");
      }
    },
    setUsers() {
      this.form.spectators = this.selectedUsersList.map(item => item.id);
      const { usersDropdown, responsibleUsersInput } = this.$refs;

      this.selectedUsersContainer = true;
      usersDropdown.classList.remove("active");
      responsibleUsersInput.value = "";
    },
    afterLeave() {
      this.empty();
      this.usersList = [];
      this.selectedUsersList = [];
    },

    removeFromSelectedUsers(user) {
      this.selectUsers(user);
      this.form.spectators = this.selectedUsersList.map(item => item.id);
    },
    handleExceed(files, fileList) {
      this.$message.warning(this.$t('message.file_limit_warning', {number: this.file_limit}));
    },
    handleRemove(file, fileList){
      this.updateFile.forEach((element, index) => {
        if(element.name === file.name ){
          this.updateFile.splice(index, 1);
        }
      });
    },
    createImageList(file, fileList) {
      if (file.size > 5000000) {
        fileList.pop();
        return this.$message({
          type: "warning",
          message: this.$t('message.file_limit_warning', {number: 5}),
        });
      }
      if(this.updateFile.some(item => item.name === file.name && item.size === file.size)) {
        this.updateFile.forEach((element, index) => {
          if(element.name === file.name && element.size === file.size){
            fileList.pop();
            return this.$message({
              type: "warning",
              message: this.$t('message.file_already_exists'),
            });
          } 
        });
      }else{
        this.updateFile.push(file.raw);
      }       
    },
  },
};
</script>


<style>
.el-form--label-top .el-form-item__label {
    margin: 0px !important;
    padding: 0px !important;
}
</style>
