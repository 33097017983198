import { i18n } from '@/utils/i18n';

export const columns = {
    id : {
        show: true,
        title: i18n.t('message.n'),
        sortable: true,
        column: 'id'
    },
    company_id : {
        show: true,
        title: i18n.t('message.company_id'),
        sortable: true,
        column: 'company_id'
    },
    full_name : {
        show: true,
        title: i18n.t('message.full_name'),
        sortable: true,
        column: 'full_name'
    },
    date_of_birth : {
        show: true,
        title: i18n.t('message.date_of_birth'),
        sortable: true,
        column: 'date_of_birth'
    },
    passport_serial_number : {
        show: true,
        title: i18n.t('message.passport_serial_number'),
        sortable: true,
        column: 'passport_serial_number'
    },
    type_id : {
        show: true,
        title: i18n.t('message.type_id'),
        sortable: true,
        column: 'type_id'
    },
    phone : {
        show: true,
        title: i18n.t('message.phone'),
        sortable: true,
        column: 'phone'
    },
    email : {
        show: true,
        title: i18n.t('message.email'),
        sortable: true,
        column: 'email'
    },
    passport_validity : {
        show: false,
        title: i18n.t('message.passport_validity'),
        sortable: true,
        column: 'passport_validity'
    },
    actual_address : {
        show: false,
        title: i18n.t('message.actual_address'),
        sortable: true,
        column: 'actual_address'
    },
    inn : {
        show: false,
        title: i18n.t('message.inn'),
        sortable: true,
        column: 'inn'
    },
    gender_id : {
        show: false,
        title: i18n.t('message.gender_id'),
        sortable: true,
        column: 'gender_id'
    },
    country_id : {
        show: false,
        title: i18n.t('message.country_id'),
        sortable: true,
        column: 'country_id'
    },
    city_id : {
        show: false,
        title: i18n.t('message.city_id'),
        sortable: true,
        column: 'city_id'
    },
    clientTypes : {
        show: true,
        title: i18n.t('message.clientTypes'),
        sortable: true,
        column: 'clientTypes'
    },
    created_at : {
        show: true,
        title: i18n.t('message.created_at'),
        sortable: true,
        column: 'created_at'
    },
    updated_at : {
        show: true,
        title: i18n.t('message.updated_at'),
        sortable: true,
        column: 'updated_at'
    },   
    settings : {
        show: true,
        title: i18n.t('message.settings'),
        sortable: false,
        column: 'settings'
    },

};
